import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_BOTTOM, BORDER_RADIUS_S, FontWeights } from 'shared/config';

export const getMuiTextFieldOverrides = (theme: Theme): ComponentsOverrides['MuiTextField'] => {
  return {
    root: {
      width: '100%',

      '.MuiInput-root': {
        height: '20px',
        '.MuiInputBase-input': {
          ...theme.typography.body2,
          padding: 0,
          color: theme.themeColors.colorTypographyPrimary,

          [theme.breakpoints.down('md')]: {
            fontSize: 18,
            lineHeight: '20px',
          },
          ' &.bold': {
            fontWeight: `${FontWeights.SemiBold} !important`,
          },
          '&:active': {
            color: theme.themeColors.textFieldActiveColor,
          },
        },
        '&.Mui-focused': {
          color: theme.themeColors.textFieldActiveColor,
          '.MuiInputBase-input': {
            color: theme.themeColors.textFieldActiveColor,
          },
        },
        '&.Mui-disabled': {
          '.MuiInputBase-input': {
            color: theme.themeColors.colorTypographyPrimary,
            WebkitTextFillColor: theme.themeColors.colorTypographyPrimary,
          },
        },
      },
      '& .MuiFilledInput-root': {
        padding: theme.spacing(0.25, 2, 0),
        height: 80,
        background: `${theme.themeColors.textFieldBackground} !important`,
        borderRadius: BORDER_RADIUS_S,
        borderColor: 'transparent',
        color: 'transparent',
        borderBottom: BORDER_BOTTOM,
        '&::after, &::before': {
          display: 'none',
        },
        '&:hover, &:active': {
          borderColor: theme.themeColors.textFieldBorderHover,
        },
        [theme.breakpoints.down('md')]: {
          height: 56,
        },
        '&.Mui-focused': {
          color: theme.themeColors.textFieldActiveColor,
          '.MuiInputBase-input': {
            color: theme.themeColors.textFieldActiveColor,
          },
        },
        '&.Mui-disabled': {
          borderColor: 'transparent',
        },
        '& .MuiInputAdornment-root': {
          '& .MuiInputAdornment-hiddenLabel': {
            margin: 0,
          },
        },

        '.MuiInputBase-input': {
          ...theme.typography.body2,
          padding: 0,
          color: theme.themeColors.colorTypographyPrimary,

          [theme.breakpoints.down('md')]: {
            fontSize: 18,
            lineHeight: '20px',
          },
          '&:active': {
            color: theme.themeColors.textFieldActiveColor,
          },
          // '&.Mui-disabled': {
          //   opacity: 1,
          //   color: theme.themeColors.textFieldColorDisabled,
          //   WebkitTextFillColor: theme.themeColors.textFieldColorDisabled,
          // },
        },
        '.MuiFilledInput-notchedOutline': {
          padding: 0,
        },
        '&.Mui-error': {
          borderColor: `${theme.themeColors.textFieldColorError} !important`,
        },
        '&.Mui-error fieldset': {
          borderColor: `${theme.themeColors.textFieldColorError} !important`,
        },
      },
      '& .MuiFormHelperText-root': {
        textAlign: 'right',
        color: theme.themeColors.colorTypographySecondary,
        margin: 0,
        marginTop: theme.spacing(1),
      },
      '.MuiInputBase-multiline': {
        background: theme.themeColors.textFieldBackground,
        minHeight: 48,
        padding: theme.spacing(2),
        height: 'min-content',
      },

      '.MuiFormLabel-root': {
        ...theme.typography.body2,
        lineHeight: '20px',
        color: theme.themeColors.textFieldFormLabel,
        textTransform: 'capitalize',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(0px, -20px) scale(0.75)',
        },
        '&.Mui-focused': {
          color: theme.themeColors.textFieldFormLabel,
        },
      },
      'input:-webkit-autofill': {
        boxShadow: '0 0 0 1000px transparent inset !important',
        transitionDelay: '999999s',
        transitionProperty: 'background-color, color',
      },
    },
  };
};

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTextField'] => ({
  variant: 'filled',
  SelectProps: {
    displayEmpty: true,
  },
  InputProps: {
    autoComplete: 'off',
    disableUnderline: true,
  },
});
