import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from 'app/types';
import { RequestWithWeb3Provider } from 'shared';
import { Chains, ChainType, WalletProviders } from 'shared/config';

import {
  AddBlockReq,
  ChangeAvatarReq,
  ChangeBannerReq,
  ChangeBioReq,
  ChangeNameReq,
  CreateProfileReq,
  DeleteBlocksReq,
  EditBlocksReq,
  EditUserDescriptionReq,
  UpdateBlocksReq,
  UploadAvatarReq,
  UserBlockData,
  IssueCredentialReq
} from './types';

export type UserDomainState = {
  domainName: string;
  domainTokenId: null | string;
};

export type UserState = {
  address: string;
  icpAddress: string;
  provider: WalletProviders;
  chainType: ChainType;
  isAdmin: boolean;
  network: Chains;
  balance: string;
  authQR: string;
  session: string;
  credential: string;
  isWhitelisted: boolean;
  scoreMintedTokenId: number;

  profileContractAddress: string;
  name: string;
  bio: string; //  UserBlockData
  bioBlockData: Nullable<UserBlockData>;
  avatar: string;
  banner: string; //  UserBlockData
  bannerBlockData: Nullable<UserBlockData>;

  domain: UserDomainState;
  links: UserBlockData[];
};

const initialUserState: UserState = {
  address: '',
  provider: WalletProviders.metamask,
  icpAddress: '',
  isAdmin: false,
  chainType: 'mainnet', // TODO: change to mainnet on production
  network: Chains.Polygon,
  balance: '0',
  isWhitelisted: false,
  scoreMintedTokenId: -1,

  authQR: '',
  session: '',
  credential: '',
  profileContractAddress: '',
  name: '',
  bio: '',
  bioBlockData: null,
  avatar: '',
  banner: '',
  bannerBlockData: null,

  domain: {
    domainName: '',
    domainTokenId: null,
  },
  links: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    updateUserState: (state: UserState, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearUserState: () => ({
      ...initialUserState,
    }),

    disconnectWalletState: () => {
      localStorage.removeItem('walletconnect');
      return {
        ...initialUserState,
      };
    },

    updateUserDomain: (state: UserState, action: PayloadAction<Partial<UserDomainState>>) => ({
      ...state,
      domain: { ...state.domain, ...action.payload },
    }),

    /** For saga */
    updateUserBioBlockDataState: (state: UserState, action: PayloadAction<Partial<UserBlockData>>) => ({
      ...state,
      bioBlockData: state.bioBlockData ? { ...state.bioBlockData, ...action.payload } : null,
    }),
    updateUserBannerBlockDataState: (state: UserState, action: PayloadAction<Partial<UserBlockData>>) => ({
      ...state,
      bannerBlockData: state.bannerBlockData ? { ...state.bannerBlockData, ...action.payload } : null,
    }),

    /** For saga */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    /* eslint-disable @typescript-eslint/no-empty-function */
    getUserInfo(state, action: PayloadAction) {},
    getUserBalance(state, action: PayloadAction<RequestWithWeb3Provider>) {},
    getUserDomain(state, action: PayloadAction) {},
    getIsUserWhitelisted(state, action: PayloadAction) {},
    createProfile(state, action: PayloadAction<CreateProfileReq>) {},
    getProfileContractAddress(state, action: PayloadAction) {},
    changeName(state, action: PayloadAction<ChangeNameReq>) {},
    changeBio(state, action: PayloadAction<ChangeBioReq>) {},
    editUserDescription(state, action: PayloadAction<EditUserDescriptionReq>) {},
    addBlock(state, action: PayloadAction<AddBlockReq>) {},
    updateBlocks(state, action: PayloadAction<UpdateBlocksReq>) {},
    deleteBlocks(state, action: PayloadAction<DeleteBlocksReq>) {},
    editBlocks(state, action: PayloadAction<EditBlocksReq>) {},
    uploadAvatar(state, action: PayloadAction<UploadAvatarReq>) {},
    changeAvatar(state, action: PayloadAction<ChangeAvatarReq>) {},
    changeBanner(state, action: PayloadAction<ChangeBannerReq>) {},
    mintScore(state, action: PayloadAction<RequestWithWeb3Provider>) {},
    getScoreMintedTokenId(state, action: PayloadAction) {},
    getAuthQR(state, action: PayloadAction) {},
    issueCredential(state, action: PayloadAction<IssueCredentialReq>) {},
    connectPlug(state, action: PayloadAction) {},
  },
});

export const { reducer } = userSlice;
export const { actions: userActions } = userSlice;
