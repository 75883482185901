import { createSelector } from '@reduxjs/toolkit';
import { State } from 'app/store';

const getProfile = (state: State) => state.profile;

const selectProfileCredentials = createSelector(getProfile, ({ credentials }) => ({
  credentials,
}));

const selectProfilePoaps = createSelector(getProfile, ({ credentials }) => credentials.poaps);
const selectProfileZKBadges = createSelector(getProfile, ({ credentials }) => credentials.zkBadges);

const selectProfileLens = createSelector(getProfile, ({ lens }) => ({
  lens,
}));

export const selectors = {
  getProfile,
  selectProfileCredentials,
  selectProfileLens,
  selectProfilePoaps,
  selectProfileZKBadges,
};
