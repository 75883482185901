import { SocialField, Socials } from 'shared';

export const permanentSocialFieldsData: SocialField[] = [
  {
    name: Socials.Website,
    icon: 'website',
    label: 'Website',
    link: '',
    placeholder: 'https://marko.eth',
  },
  {
    name: Socials.Twitter,
    icon: 'twitter',
    label: 'Twitter',
    link: 'https://twitter.com/',
    placeholder: 'https://twitter.com/',
  },
];

export const optionalSocialFieldsData: SocialField[] = [
  {
    name: Socials.Lenster,
    icon: 'lenster',
    label: 'Lenster',
    link: 'https://lenster.xyz/u/',
    placeholder: 'https://lenster.xyz/u/',
  },
  {
    name: Socials.Tiktok,
    icon: 'tiktok',
    label: 'Tiktok',
    link: 'https://www.tiktok.com/@',
    placeholder: 'https://www.tiktok.com/@',
  },
  {
    name: Socials.Instagram,
    icon: 'instagram',
    label: 'Instagram',
    link: 'https://instagram.com/',
    placeholder: 'https://instagram.com/',
  },
  {
    name: Socials.Telegram,
    icon: 'telegram',
    label: 'Telegram',
    link: 'https://t.me/',
    placeholder: 'https://t.me/',
  },
  {
    name: Socials.Facebook,
    icon: 'facebook',
    label: 'Facebook',
    link: 'https://facebook.com/',
    placeholder: 'https://facebook.com/',
  },
  {
    name: Socials.Discord,
    icon: 'discord',
    label: 'Discord',
    link: 'https://discord.com/users/',
    placeholder: 'https://discord.com/users/',
  },
  {
    name: Socials.WhatsApp,
    icon: 'whatsapp',
    label: 'WhatsApp',
    link: 'https://wa.me/',
    placeholder: 'https://wa.me/+12345671234',
  },
  {
    name: Socials.LinkedIn,
    icon: 'linkedin',
    label: 'LinkedIn',
    link: 'https://www.linkedin.com/in/',
    placeholder: 'https://www.linkedin.com/in/',
  },
  {
    name: Socials.Calendly,
    icon: 'calendly',
    label: 'Calendly',
    link: 'https://calendly.com/',
    placeholder: 'https://calendly.com/',
  },
  {
    name: Socials.Github,
    icon: 'github',
    label: 'Github',
    link: 'https://github.com/',
    placeholder: 'https://github.com/',
  },
  {
    name: Socials.Custom,
    icon: 'custom-social',
    label: '',
    link: '',
    placeholder: 'https://custom.com',
  },
  {
    name: Socials.Website,
    icon: 'website',
    label: 'Website',
    link: '',
    placeholder: 'https://marko.eth',
  },
];

export const allSocialFieldsData: SocialField[] = [...permanentSocialFieldsData, ...optionalSocialFieldsData];
