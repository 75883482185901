import { Chains, ChainType, contractsConfig, ContractsNames } from 'shared';
import { AbiItem } from 'web3-utils';

export const getContractDataByItsName = (
  name: ContractsNames,
  chainType: ChainType,
  network = Chains.Polygon,
): [AbiItem[], string] => {
  const { abi: contractAbi, address: contractAddress } = contractsConfig.contracts[name][chainType];

  return [contractAbi as AbiItem[], contractAddress[network]];
};
