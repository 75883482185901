import { Box, SvgIcon } from '@mui/material';
import { profileModel } from 'entities/profile';
import { useShallowSelector } from 'shared';

import QRCode from './assets/share.svg';
import { useShareWebAPI } from './lib/hooks/useShareWebAPI';

export const ProfileShare = () => {
  const { name } = useShallowSelector(profileModel.selectors.getProfile);
  const { share } = useShareWebAPI(`${name.toString()} {r}elinkd profile.`, name, '');

  return (
    <Box
      sx={{
        display: 'unset',
        cursor: 'pointer',
        position: 'absolute',
        right: '16px',
        top: '20px',
        height: '24px',
        width: '24px',
        filter: 'none!important',
      }}
      component="img"
      src={QRCode}
      onClick={share}
    />
  );
};
