import { domainModel } from 'entities/domain';
import { domainActions } from 'entities/domain/model';
import { getDomainInfoSaga } from 'entities/domain/model/sagas/getDomainInfo';
import { profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import { UserState } from 'entities/user/model';
import { ContractsNames, createContract, getContractDataByItsName, ProfileAbi } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { BlockData, BlockDataFromBlockchain, ProfileState } from '../../types';
import { profileActions } from '../reducer';

import { getEnsNamesSaga } from './getEnsNames';
import { getProfileContractAddressSaga } from './getProfileContractAddress';

export function* getProfileInfoSaga({
  type,
  payload: { name: domainName },
}: ReturnType<typeof profileActions.getProfileInfo>) {
  yield* put(request(type));
  const { chainType, address: userAddress }: Pick<UserState, 'chainType' | 'address'> = yield select(
    userModel.selectors.selectUserWeb3Info,
  );
  const [profileAbi] = getContractDataByItsName(ContractsNames.Profile, chainType);

  try {
    yield* call(getDomainInfoSaga, {
      type: domainActions.getDomainInfo.type,
      payload: { name: domainName },
    });

    const { busy, owner, tokenId } = yield select(domainModel.selectors.selectDomainInfo);

    // if domain is not busy, then domain in profile pathname is address
    if (busy) {
      yield* put(
        profileActions.updateProfileState({
          domainName,
          address: owner,
        }),
      );
    } else {
      yield* put(
        profileActions.updateProfileState({
          address: domainName,
          domainName: '',
        }),
      );
    }
    const { address: profileAddress }: Pick<ProfileState, 'address'> = yield select(profileModel.selectors.getProfile);

    yield* call(getProfileContractAddressSaga, {
      type: profileActions.getProfileContractAddress.type,
      payload: { profileAddress },
    });

    const { profileContractAddress }: Pick<ProfileState, 'profileContractAddress'> = yield select(
      profileModel.selectors.getProfile,
    );

    if (profileContractAddress) {
      const profileContract: ProfileAbi = yield createContract(profileAbi, profileContractAddress, chainType);
      const {
        0: style,
        1: blocksData,
        2: blocksOrder,
        3: [name, avatarLink, attributes],
      } = yield* call(profileContract.methods.getProfile().call);

      const formatedBlocks = (blocksData as unknown as BlockDataFromBlockchain[]).map(({ blockPayload, ...data }) => ({
        blockPayload: JSON.parse(blockPayload),
        ...data,
      })) as unknown as BlockData[];

      const links = formatedBlocks.filter(({ blockType }) => blockType !== 'Banner' && blockType !== 'Bio'  && blockType !== 'Plug');
      const bannerData = formatedBlocks.find(({ blockType }) => blockType === 'Banner');
      const bioData = formatedBlocks.find(({ blockType }) => blockType === 'Bio');

      yield* put(
        profileActions.updateProfileState({
          name,
          avatar: avatarLink,
          banner: bannerData?.blockPayload?.contents || '',
          bio: bioData?.blockPayload?.contents || '',
          links,
          isCurrentUser: profileAddress.toLowerCase() === userAddress.toLowerCase(),
        }),
      );

      yield* call(getEnsNamesSaga, {
        type: profileActions.getEnsNames.type,
        payload: undefined,
      });
    }

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileInfo.type, getProfileInfoSaga);
}
