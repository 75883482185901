import axios, { AxiosResponse } from 'axios';
import { profileModel } from 'entities/profile';
import { fetchProfilePoaps } from 'entities/profile/api';
import { getToastMessage, logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { CredentialData, ProfileState } from '../../types';
import { profileActions } from '../reducer';

import { updateProfileToken } from './updateProfileToken';

export function* updateProfileScoreSaga({ type }: ReturnType<typeof profileActions.getProfileScore>) {
  yield* put(request(type));

  const { address }: Pick<ProfileState, 'address'> = yield select(profileModel.selectors.getProfile);

  const RELINKD_API_URL = `https://api.relinkd.xyz/update-metadata`;

  try {
    const { data }: AxiosResponse = yield axios.get(`${RELINKD_API_URL}?address=${address}`);
    console.log(data, 'updatescore');
    // const poaps: CredentialData[] = yield fetchProfilePoaps(address);

    yield* put(
      profileActions.updateProfileState({
        score: data,
      }),
    );

    yield* call(updateProfileToken, {
      type: profileActions.updateProfileToken.type,
      payload: undefined,
    });

    getToastMessage('success', 'Score and token successfully updated');

    yield* put(success(type));
  } catch (err: any) {
    getToastMessage('error', 'wait 3 minutes for update');
    yield* put(error(type, err));
    logger('getProfileScore', 'User metadata can only be updated once every three minutes.');
  }
}

export default function* listener() {
  yield takeLatest(profileActions.updateProfileScore.type, updateProfileScoreSaga);
}
