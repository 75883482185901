import { IconType } from '..';

export enum Socials {
  Website = 'Website',
  Twitter = 'Twitter',
  Lenster = 'Lenster',
  Tiktok = 'Tiktok',
  Instagram = 'Instagram',
  Telegram = 'Telegram',
  Facebook = 'Facebook',
  Discord = 'Discord',
  WhatsApp = 'WhatsApp',
  LinkedIn = 'LinkedIn',
  Calendly = 'Calendly',
  Custom = 'Custom',
  Github = 'Github',
}

export type SocialField = {
  name: Socials;
  icon: IconType;
  label: string;
  link: string;
  placeholder: string;
};
