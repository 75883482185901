import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from 'app/types';

import { CreateDomain, GetDomainInfo } from './types';

export type DomainState = {
  name: string;
  busy: Nullable<boolean>;
  linkedProfile: string;
  owner: string;
  tokenId: string;
  isFirstDomainFree: boolean;
  domainPrice: number;
  maxFeePerGas: number;
};

const initialDomainState: DomainState = {
  name: '',
  busy: null,
  linkedProfile: '',
  owner: '',
  tokenId: '',
  isFirstDomainFree: false,
  domainPrice: 0,
  maxFeePerGas: 0,
};

const domainSlice = createSlice({
  name: 'domain',
  initialState: initialDomainState,
  reducers: {
    updateDomainState: (state: DomainState, action: PayloadAction<Partial<DomainState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearDomainState: () => ({
      ...initialDomainState,
    }),

    /** For saga */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    getDomainInfo(state, action: PayloadAction<GetDomainInfo>) {},
    createDomain(state, action: PayloadAction<CreateDomain>) {},
    getFactoryState(state, action: PayloadAction) {},
  },
});

export const { reducer } = domainSlice;
export const { actions: domainActions } = domainSlice;
