import type { AbiItem } from 'web3-utils';

import domain from './domain.abi.json';
import factory from './factory.abi.json';
import profile from './profile.abi.json';
import score from './score.abi.json';

export const factoryAbi = factory as AbiItem[];
export const domainAbi = domain as AbiItem[];
export const profileAbi = profile as AbiItem[];
export const scoreAbi = score as AbiItem[];
