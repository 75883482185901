import { credentialsMock } from 'features/credential-accordion';

export const credentialAccordionsMock = [
  {
    label: 'Verifiable credentials',
    credentials: credentialsMock,
  },
  {
    label: 'POAPs',
    credentials: credentialsMock.slice(0, 3),
  },
  {
    label: 'Guilds',
    credentials: [],
  },
  {
    label: 'ZK Badges',
    credentials: [],
  },
];
