import { Box, Divider, Hidden, Stack, Typography } from '@mui/material';
import { BORDER_RADIUS_S, FontFamilies, Icon } from 'shared';

export const LensPost = ({ post }: any) => {
  if (!post) return null;

  const { profile } = post;

  return (
    <Box pt={3}>
      <Divider />
      <Stack direction="row" justifyContent="space-between" pt={3} mx="auto" sx={{ width: { sm: '90%', xs: '100%' } }}>
        <Typography
          variant="h2"
          fontWeight="normal"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            window.open(`https://lenster.xyz/u/${profile.handle}`, '_blank');
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Last Activity</span>
          <span style={{ color: 'gray', paddingLeft: '20px' }}>{profile.stats.totalPosts} posts</span>
        </Typography>
        {/* <Typography variant='h2' fontWeight="normal" sx={{cursor: 'pointer'}}  onClick={() => {window.open(`https://lenster.xyz/u/${profile.handle}`, '_blank')}}>Total: {profile.stats.totalPosts} posts</Typography> */}
      </Stack>
      <Stack direction="row" justifyContent="space-between" pt={3} mx="auto" width="90%">
        <Hidden smDown>
          <Stack sx={{ width: '20%' }}>
            <Box
              component="img"
              src={profile.picture ? profile.picture.original.url : ''}
              sx={{ height: 45, width: 45, borderRadius: '50%' }}
              onError={(e: any) => {
                e.target.style.visibility = 'hidden';
              }}
            />
          </Stack>
        </Hidden>
        <Stack direction="column" sx={{ width: { sm: '80%', xs: '100%' } }} justifyContent="space-between">
          <Typography color="black" fontSize={18} className="secondary">
            {profile.name}
          </Typography>
          <Typography fontSize={14}>@{profile.handle.slice(0, -5)}</Typography>
          <Typography pt={2.5}>{post.metadata.content}</Typography>
          {post.metadata.image && (
            <Box
              component="img"
              pt={2.5}
              sx={{ width: { sm: '80%', xs: '100%' }, borderRadius: BORDER_RADIUS_S }}
              src={
                post.metadata.image.startsWith('ipfs://')
                  ? `https://ipfs.io/ipfs/${post.metadata.image.slice(7)}`
                  : post.metadata.image
              }
            />
          )}
          <Stack direction="row" justifyContent="space-between" pt={2.5} sx={{ width: { sm: '80%', xs: '100%' } }}>
            <Stack direction="row" justifyContent="space-between">
              <Icon type="lens-comment" size={16} />
              <Typography fontSize={12} color="rgba(59, 130, 246, 1)" pl={1}>
                {post.stats.totalAmountOfComments}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Icon type="lens-mirror" size={16} />
              <Typography fontSize={12} color="rgba(139, 92, 246, 1)" pl={1}>
                {post.stats.totalAmountOfMirrors}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Icon type="lens-like" size={16} />
              <Typography fontSize={12} color="rgba(236, 72, 153, 1)" pl={1}>
                {post.stats.totalUpvotes}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
