import { CSSProperties, FC } from 'react';
import { styled } from '@mui/material';

import icons from './icons';

const Sizes = {
  small: 24,
  medium: 40,
  large: 96,
};

export type IconType = keyof typeof icons;

interface IIconProps {
  type: IconType;
  size?: keyof typeof Sizes | number;
  color?: CSSProperties['color'];
  stroke?: CSSProperties['stroke'];
  pointerEvents?: string;
}

const wrapToColors = (Component: FC) =>
  styled(Component)<
    { fillColor?: string; strokeColor?: string; width?: string | number; height?: string | number } & Omit<
      IIconProps,
      'type'
    >
  >`
    & *[fill] {
      fill: ${({ fillColor }) => fillColor || ''};
    }
    & *[stroke] {
      stroke: ${({ strokeColor }) => strokeColor || ''};
    }
  `;

export const Icon: FC<IIconProps> = ({ type, size = 'small', color, stroke, pointerEvents = 'auto' }) => {
  const IconComponent = icons[type];
  const width = typeof size === 'number' ? size : Sizes[size];
  const height = typeof size === 'number' ? size : Sizes[size];
  const ColoredIcon = wrapToColors(IconComponent);
  return (
    <ColoredIcon width={width} height={height} pointerEvents={pointerEvents} fillColor={color} strokeColor={stroke} />
  );
};
