import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_RADIUS_S } from 'shared/config/theme/common';

import { FontWeights } from '../Typography';

export const getMuiMenuOverrides = (theme: Theme): ComponentsOverrides['MuiMenu'] => ({
  paper: {
    backgroundColor: theme.themeColors.colorBackground,
    border: 'none',
    borderRadius: BORDER_RADIUS_S,
    padding: theme.spacing(1, 0.5),
  },
  list: {
    padding: 0,
  },
});

export const getMuiMenuItemOverrides = (theme: Theme): ComponentsOverrides['MuiMenuItem'] => ({
  root: {
    color: theme.themeColors.colorTypographyPrimary,
    fontWeight: FontWeights.Medium,
    padding: theme.spacing(2),

    '&.no-padding': {
      padding: 0,
    },

    '& + &': {
      marginTop: theme.spacing(1),
    },
    borderRadius: BORDER_RADIUS_S,
    '&:hover': {
      background: theme.themeColors.backgroundSecondary,
    },

    '&.Mui-selected': {
      backgroundColor: theme.themeColors.backgroundSecondary,

      '&:hover': {
        background: theme.themeColors.backgroundSecondary,
      },
    },
  },
});

export const getMuiMenuItemDefaultProps = (): ComponentsProps['MuiMenuItem'] => ({
  disableRipple: true,
});
