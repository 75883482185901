import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, Menu, MenuItem, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import copy from 'copy-to-clipboard';
import { Domain } from 'entities/domain';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { userModel } from 'entities/user';
import { ConnectButton } from 'features';
import {
  amplitudeInstance,
  BORDER_RADIUS_S,
  BORDER_RADIUS_XS,
  BORDER_S,
  flexHelper,
  getToastMessage,
  Icon,
  OnlinePoint,
  onPinataImageError,
  routes,
  shortenPhrase,
  useShallowSelector,
  useWalletConnectorContext,
} from 'shared';
import { EditProfileForm } from 'widgets/edit-profile-form';

type AccountMenuProps = {
  isMenuOpen: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
};
const StyledMenu = styled(Menu)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '.MuiPaper-root': {
      top: '0 !important',
      left: '0 !important',
      maxWidth: '100vw',
      width: '100%',
      maxHeight: '100vh',
      height: '100%',
      boxShadow: 'none',

      '.MuiList-root': {
        ...flexHelper('start', 'center', 'column'),
        height: '100%',
        paddingBottom: '20px',
      },
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...flexHelper('space-between'),
  width: 366,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const ProfileWrapper = styled(Box)(({ theme }) => ({
  width: 56,
  height: 56,
  background: theme.themeColors.backgroundSecondary,
  borderRadius: BORDER_RADIUS_XS,
  overflow: 'hidden',
  position: 'relative',

  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const MintButton = styled(Box)(({ theme }) => ({
  background: theme.themeColors.backgroundSecondary,
  border: BORDER_S,
  borderRadius: BORDER_RADIUS_S,
  borderColor: theme.themeColors.colorAccent,
  padding: theme.spacing(0.5, 1.5),
  ...theme.typography.subtitle2,
  fontSize: 16,
  cursor: 'pointer',
}));

const CloseButton = styled(Button)(({ theme }) => ({
  background: theme.themeColors.backgroundSecondary,
  color: theme.themeColors.colorTypographyPrimary,
}));

export const AccountMenu = ({ isMenuOpen, anchorEl, handleClose }: AccountMenuProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { disconnect } = useWalletConnectorContext();
  const {
    address,
    avatar,
    name,
    profileContractAddress,
    isWhitelisted,
    domain: { domainName },
  } = useShallowSelector(userModel.selectors.getUser);

  const handleDisconnect = () => {
    disconnect();
    handleClose();
  };
  const handleCopyProfileLink = () => {
    const link = `${window.location.origin}/${domainName || address}`;
    copy(link);
    getToastMessage('success', 'Profile link coppied');
    amplitudeInstance.track('copy_link');
  };

  const handleOpenProfile = () => {
    navigate(profileContractAddress ? routes.profile.getPathname(domainName || address) : routes.connect.path);
    handleClose();
  };

  const handleOpenEditProfile = () => {
    handleClose();
    dispatch(
      modalModel.modalActions.openModal({
        type: Modals.BaseModal,
        data: {
          title: 'Edit',
          children: <EditProfileForm />,
        },
      }),
    );
  };

  const handleMintName = () => {
    navigate(routes.domain.getPathname('mint'));
  };

  return (
    <StyledMenu
      id="account-menu"
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleClose}
      disablePortal
      MenuListProps={{
        'aria-labelledby': 'account-menu-button',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transitionDuration={200}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0,0,0,0.4)',
        },
      }}
    >
      <Stack p={2} spacing={{ xs: 5.75, md: 3.75 }} mb={{ xs: 10, sm: 1 }} width="100%">
        {!isDownSm && address && (
          <Stack direction="row" gap={0.5} alignItems="center">
            <ProfileWrapper>
              {avatar && <Box component="img" src={avatar} onError={(e) => onPinataImageError(e, avatar || '')} />}
            </ProfileWrapper>
            <Stack spacing={1} ml={1.5} mr="auto">
              <Stack direction="row" spacing={1} alignItems="center">
                <Icon type="wallet" size={20} /> <Typography className="small">Metamask</Typography> <OnlinePoint />
              </Stack>
              <Typography className="color-primary medium">{shortenPhrase(address, 5, 4)}</Typography>
            </Stack>
            <IconButton
              className="contained"
              size="large"
              onClick={handleCopyProfileLink}
              disabled={!profileContractAddress}
            >
              <Icon type="link" />
            </IconButton>
            <IconButton className="contained" size="large" onClick={handleDisconnect}>
              <Icon type="logout" stroke={theme.themeColors.colorRed} />
            </IconButton>
          </Stack>
        )}
        {isDownSm && !address && (
          <Stack sx={{ marginTop: '7%', width: '70%', mx: 'auto' }}>
            <ConnectButton />
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography variant="h2">{name || '-'}</Typography>
          {!domainName ? (
            <MintButton onClick={handleMintName}>
              {profileContractAddress ? 'mint {r} name' : 'build my profile'}
            </MintButton>
          ) : (
            <Domain name={domainName} />
          )}
        </Stack>
      </Stack>
      {profileContractAddress && (
        <StyledMenuItem onClick={handleOpenProfile}>
          View my profile
          <Icon type="profile" />
        </StyledMenuItem>
      )}
      {name && (
        <StyledMenuItem onClick={handleOpenEditProfile}>
          Change displayed name
          <Icon type="smile" stroke={theme.themeColors.colorTypographyPrimary} />
        </StyledMenuItem>
      )}
      {/* <StyledMenuItem onClick={handleClose}>
        More
        <Icon type="points" />
      </StyledMenuItem> */}
      {isDownSm && (
        <CloseButton fullWidth onClick={handleClose} sx={{ marginTop: '10%', mx: 2 }}>
          Close
        </CloseButton>
      )}
    </StyledMenu>
  );
};
