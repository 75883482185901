import axios, { AxiosResponse } from 'axios';
import { profileModel } from 'entities/profile';
import { fetchProfilePoaps } from 'entities/profile/api';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { CredentialData, ProfileState } from '../../types';
import { profileActions } from '../reducer';

export function* getProfileScoreSaga({ type }: ReturnType<typeof profileActions.getProfileScore>) {
  yield* put(request(type));

  const { address }: Pick<ProfileState, 'address'> = yield select(profileModel.selectors.getProfile);

  const RELINKD_API_URL = `https://api.relinkd.xyz/get-scores`;

  try {
    const { data }: AxiosResponse = yield axios.get(`${RELINKD_API_URL}?address=${address}`);
    // const poaps: CredentialData[] = yield fetchProfilePoaps(address);

    yield* put(
      profileActions.updateProfileState({
        score: data,
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getProfileScore', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileScore.type, getProfileScoreSaga);
}
