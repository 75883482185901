import axios from 'axios';
import { Guild } from 'entities/profile/types';

export async function fetchProfileGuilds(address: string) {
  const guilds: Guild[] = [];
  const GUILD_API_URL = `https://api.guild.xyz/v1/user/membership`;

  try {
    const response = await axios.get(`${GUILD_API_URL}/${address}`);

    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch profile guilds');
  }
}
