import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { BORDER_RADIUS_S, BORDER_S } from '../common';

export const getMuiIconButtonOverrides = (theme: Theme): ComponentsOverrides['MuiIconButton'] => ({
  root: {
    padding: theme.spacing(1.25),
    width: 24,
    height: 24,
  },
  sizeLarge: {
    padding: theme.spacing(1.75),
    width: 56,
    height: 56,
  },
  colorPrimary: {
    padding: 0,
    background: 'transparent',
    color: theme.themeColors.iconButtonColor,
    svg: {
      path: {
        stroke: theme.themeColors.iconButtonColor,
      },
    },
    '&:hover:not(.Mui-disabled)': {
      background: 'transparent',
      color: theme.themeColors.iconButtonColorHover,
      '&.contained': {
        background: theme.themeColors.backgroundSecondary,
      },
      svg: {
        path: {
          stroke: theme.themeColors.iconButtonColorHover,
        },
      },
    },
    '&.contained': {
      background: theme.themeColors.backgroundSecondary,
      borderRadius: BORDER_RADIUS_S,
    },
  },
  colorSecondary: {
    color: theme.themeColors.buttonOutlinedColor,
    background: theme.themeColors.buttonOutlinedBackground,
    border: BORDER_S,
    borderRadius: BORDER_RADIUS_S,
    borderColor: theme.themeColors.buttonOutlinedColorBorder,
    transition: '0.3s all ease',
    svg: {
      path: {
        stroke: theme.themeColors.buttonOutlinedColor,
      },
    },
    '&:hover': {
      background: theme.themeColors.buttonOutlinedBackgroundHover,
      color: theme.themeColors.buttonOutlinedColorHover,
      borderColor: theme.themeColors.buttonOutlinedBackgroundHover,
      svg: {
        path: {
          stroke: theme.themeColors.buttonOutlinedColorHover,
        },
      },
    },
  },
});

export const getMuiIconButtonDefaultProps = (): ComponentsProps['MuiIconButton'] => ({
  disableFocusRipple: true,
  disableRipple: true,
  size: 'medium',
  color: 'primary',
});
