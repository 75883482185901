import axios from 'axios';

export async function fetchProfilePoaps(address: string) {
  const headers = {
    accept: 'application/json',
    'x-api-key':
      '7fRXOiGVVcn3xDajaMf6hnGtQjbJ4foIupFVML8y3S7ULHBKeCLlYZ5bXH8wwSHhujIw1KEwzY7XcIM8sys20DQllN3kR8Ec2iAKMLQh2rSHeIpKLSq8ac6dYAoqTjwF',
  };

  const POAP_API_URL = `https://api.poap.tech/actions/scan`;

  const response = await axios.get(`${POAP_API_URL}/${address}`, { headers });

  const poaps = response.data.map((poap: any) => {
    return {
      id: poap.event.id,
      title: poap.event.name,
      subtitle: poap.event.description,
      usersCount: poap.event.supply,
      img: poap.event.image_url,
      ...poap,
    };
  });

  return poaps;
}
