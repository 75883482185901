import { forwardRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Modal, modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { fetchTokenRate, profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import { ethers } from 'ethers';
import { RangeSlider } from 'features';
import {
  BORDER_RADIUS_XS,
  ContractsNames,
  getContractDataByItsName,
  getMaxGas,
  shortenPhrase,
  useShallowSelector,
  useWeb3Provider,
} from 'shared';

import { ModalWrapper } from '../ModalWrapper';

export interface SendModalPayload {
  type: modalModel.Modals.SendModal;
  data: null;
}

export const SendModal = forwardRef<HTMLElement, Modal>(() => {
  const { address: userAddress, chainType } = useShallowSelector(userModel.selectors.selectUserWeb3Info);
  const { balance, avatar: userAvatar } = useShallowSelector(userModel.selectors.getUser);
  const {
    address: profileAddress,
    avatar: profileAvatar,
    name: profileName,
  } = useShallowSelector(profileModel.selectors.getProfile);
  const [maticPrice, setMaticPrice] = useState(-1);
  const web3Provider = useWeb3Provider();
  const dispatch = useDispatch();

  const min = 0.01;
  const max = 400;
  const averageValue = max / 2;
  const [value, setValue] = useState(averageValue);
  const [gas, setGas] = useState({}); // TODO optimise

  const location = useLocation();
  const navigate = useNavigate();

  const amount = ethers.utils.parseUnits(value.toString(), 'ether');

  useEffect(() => {
    (async () => {
      const price = await fetchTokenRate('MATIC');
      setMaticPrice(price);
      const { maxFeePerGas, maxPriorityFeePerGas } = await getMaxGas();
      setGas({ maxFeePerGas, maxPriorityFeePerGas });
    })();
  }, []);

  const transaction = {
    from: userAddress,
    to: profileAddress,
    value: amount.toString(),
    ...gas,
  };

  return (
    <ModalWrapper title={`Send MATIC to ${profileName}`} size="sm">
      <Stack mb={6} mt={2}>
        <RangeSlider min={min} max={max} value={value} setValue={setValue} />
        <Stack direction="row" spacing={3.75} mt={{ xs: 3, md: 6 }} justifyContent="center">
          <Typography>{maticPrice !== -1 && `${Math.floor(maticPrice * value * 100) / 100} $`}</Typography>
        </Stack>
        <Stack spacing={3.75} mt={{ xs: 3.5, md: 7 }} height="100%">
          <Stack direction="row" justifyContent="space-between">
            <Typography>Balance</Typography>
            <Typography>
              <Typography component="span" className="color-primary">
                {balance}{' '}
              </Typography>
              MATIC
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>MATIC price</Typography>
            <Typography className="color-primary">
              {maticPrice !== -1 ? `${Math.floor(maticPrice * 100) / 100} $` : '-'}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" pt={5.5}>
            <Typography>From</Typography>
            <Stack direction="row" spacing={2}>
              <Typography className="color-primary">{shortenPhrase(userAddress, 4, 4)}</Typography>
              <Box
                component="img"
                src={userAvatar}
                sx={{ width: '20px', height: '20px', borderRadius: BORDER_RADIUS_XS, objectFit: 'cover' }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>To</Typography>
            <Stack direction="row" spacing={2}>
              <Typography className="color-primary">{shortenPhrase(profileAddress, 4, 4)}</Typography>
              <Box
                component="img"
                src={profileAvatar}
                sx={{ width: '20px', height: '20px', borderRadius: BORDER_RADIUS_XS, objectFit: 'cover' }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Button
        fullWidth
        onClick={() => {
          if (userAddress) {
            web3Provider.eth.sendTransaction(transaction);
          } else {
            dispatch(
              modalModel.modalActions.openModal({
                type: Modals.ConnectModal,
                data: null,
              }),
            );
          }
        }}
      >
        Send
      </Button>
    </ModalWrapper>
  );
});

SendModal.displayName = 'SendModal';
