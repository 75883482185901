import { Button, ButtonProps, CircularProgress, useTheme } from '@mui/material';

type StateButtonProps = {
  state: 'default' | 'success' | 'error';
  text: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const StateButton = ({
  state,
  text,
  disabled,
  isLoading,
  onClick,
  ...buttonProps
}: StateButtonProps & ButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      size="medium"
      className={state === 'default' ? '' : state}
      disabled={state !== 'default' || isLoading || disabled}
      onClick={onClick}
      {...buttonProps}
    >
      {!isLoading ? text : <CircularProgress size={18} sx={{ color: theme.themeColors.background }} />}
    </Button>
  );
};
