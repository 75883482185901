import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { CredentialData } from 'entities/profile';
import { AccordionWrapper, BORDER_RADIUS_S, BORDER_S, Card, COLOR_ACCENT, Icon } from 'shared';

type CredentialAccordionProps = {
  label: string;
  credentials: CredentialData[];
  isExpanded: boolean;
  onToggle: (label: string) => void;
  type: 'poaps' | 'zkBadges';
};

export const CredentialCard = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(1.25),
  flexDirection: 'row',
  background: theme.themeColors.colorBackground,
  borderRadius: BORDER_RADIUS_S,
  padding: theme.spacing(1.25),
  border: BORDER_S,
  borderColor: 'transparent',
  cursor: 'pointer',
  overflow: 'hidden',

  '&:hover': {
    border: BORDER_S,
    borderColor: theme.themeColors.colorBorderHover,
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body1,
  height: '40px',
  margin: theme.spacing(1, 0, 0),
  [theme.breakpoints.down('md')]: {
    fontSize: 18,
  },
  '&:hover': {
    backgroundColor: theme.themeColors.buttonContainedBackgroundHover,
    color: theme.themeColors.buttonContainedColorHover,
  },
}));

export const CredentialAccordion = ({
  label,
  credentials: allCredentials,
  isExpanded,
  onToggle,
  type,
}: CredentialAccordionProps) => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [credentials, setCredentials] = useState(allCredentials.slice(0, isDownSm ? 3 : 4));
  const dispatch = useDispatch();

  const handleOpenCredentialModal = (id: number) => {
    dispatch(
      modalModel.modalActions.openModal({
        type: Modals.CredentialModal,
        data: {
          credentialId: id,
          type,
        },
      }),
    );
  };

  useEffect(() => {
    setCredentials(allCredentials.slice(0, isDownSm ? 3 : 4));
  }, [allCredentials]);

  const handleViewAll = () => {
    setCredentials(allCredentials);
  };

  return (
    <AccordionWrapper
      label={label}
      credentials={allCredentials}
      handleViewAll={handleViewAll}
      onToggle={onToggle}
      credentialsSliced={credentials}
      isExpanded={isExpanded}
    >
      {credentials.map(({ id, title, subtitle, usersCount, img }, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          key={id}
          onClick={() => {
            handleOpenCredentialModal(index);
          }}
        >
          <CredentialCard>
            <Box component="img" src={img} sx={{ width: 72, height: 72, objectFit: 'cover' }} />
            <Stack overflow="hidden">
              <Typography variant="subtitle2" className="ellipsis" fontSize={16}>
                {title}
              </Typography>
              <Typography className="small ellipsis">{subtitle}</Typography>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ svg: { path: { stroke: COLOR_ACCENT } } }}>
                <Icon type="smile" size={16} />
                <Typography className="small color-accent">
                  {usersCount.toLocaleString('en-US').replace(/,/g, '.')} have this
                </Typography>
              </Stack>
            </Stack>
          </CredentialCard>
        </Grid>
      ))}
    </AccordionWrapper>
  );
};
