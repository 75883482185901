import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_RADIUS_M, BORDER_RADIUS_S, BORDER_S } from 'shared/config/theme/common';
import { Icon } from 'shared/ui';

export const getMuiAccordion = (theme: Theme): ComponentsOverrides['MuiAccordion'] => ({
  root: {
    width: '100%',
    background: theme.themeColors.accordionBackground,
    boxShadow: 'none',
    border: BORDER_S,
    borderRadius: BORDER_RADIUS_M,
    borderColor: theme.themeColors.colorBorder,
    padding: theme.spacing(0),
    '&:before': {
      display: 'none',
    },
    '&.no-border': {
      borderColor: 'transparent',
    },

    '&.secondary': {
      background: theme.themeColors.accordionBackgroundSecondary,
      borderRadius: BORDER_RADIUS_S,
      borderColor: 'transparent',
    },
  },
});

export const getMuiAccordionSummary = (theme: Theme): ComponentsOverrides['MuiAccordionSummary'] => ({
  root: {
    padding: theme.spacing(2, 1),
    minHeight: 0,
    ...theme.typography.h3,
    '&.main': {
      padding: theme.spacing(2.5),
      ...theme.typography.h2,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
      },
    },
  },
  content: {
    margin: 0,
  },
});

export const getMuiAccordionDetails = (theme: Theme): ComponentsOverrides['MuiAccordionDetails'] => ({
  root: {
    ...theme.typography.body1,
    padding: theme.spacing(1.25, 1.5, 2),
    '&.secondary': {
      padding: theme.spacing(2, 1),
    },
  },
});

export const getMuiAccordionPropsDefaultProps = (): ComponentsProps['MuiAccordion'] => ({
  disableGutters: true,
  elevation: 0,
  square: true,
});

export const getMuiAccordionSummaryPropsDefaultProps = (): ComponentsProps['MuiAccordionSummary'] => ({
  expandIcon: <Icon type="arrow-down" />,
});
