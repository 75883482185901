import { all, call } from 'redux-saga/effects';

import addBlock from './addBlock';
import changeAvatar from './changeAvatar';
import changeBanner from './changeBanner';
import changeBio from './changeBio';
import changeName from './changeName';
import createProfile from './createProfile';
import deleteBlocks from './deleteBlocks';
import editBlocks from './editBlocks';
import editUserDescription from './editUserDescription';
import getIsUserWhitelisted from './getIsUserWhitelisted';
import getProfileContractAddress from './getProfileContractAddress';
import getScoreMintedTokenId from './getScoreMintedTokenId';
import getUserBalance from './getUserBalance';
import getUserDomain from './getUserDomain';
import getUserInfo from './getUserInfo';
import mintScore from './mintScore';
import updateBlocks from './updateBlocks';
import uploadAvatar from './uploadAvatar';
import getAuthQR from './getAuthQR';
import issueCredential from './issueCredential';
import connectPlug from './connectPlug';

export function* userSagas() {
  yield all(
    [
      getUserInfo,
      getUserDomain,
      getIsUserWhitelisted,
      createProfile,
      getProfileContractAddress,
      getUserBalance,
      addBlock,
      updateBlocks,
      deleteBlocks,
      editBlocks,
      changeName,
      changeBio,
      editUserDescription,
      uploadAvatar,
      changeAvatar,
      changeBanner,
      mintScore,
      getScoreMintedTokenId,
      getAuthQR,
      issueCredential,
      connectPlug,
    ].map(call),
  );
}
