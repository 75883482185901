import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { Badge } from 'entities/badge';
import { Domain } from 'entities/domain';
import { modalModel } from 'entities/modal';
import { profileModel } from 'entities/profile';
import { gitcoinProvidersToTags } from 'entities/profile/config'; // Export fix
import { userModel } from 'entities/user';
import { userActions } from 'entities/user/model';
import { ProfileForm, ProfileFormFields, userDescriptionMock } from 'features';
import {
  amplitudeInstance,
  checkRequestStatusEquality,
  RequestStatus,
  uiSelector,
  useShallowSelector,
  useWalletConnectorContext,
} from 'shared';

export const EditProfileForm = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { walletService } = useWalletConnectorContext();
  const uiState = useShallowSelector(uiSelector);
  // const { badges } = userDescriptionMock;
  const { name: existedName, bio: existedBio } = useShallowSelector(userModel.selectors.getUser);
  const { ensNames, domainName, gitcoinStamps, lens } = useShallowSelector(profileModel.selectors.getProfile);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const badges = gitcoinStamps.tags;

  const handleChangeSaveDisabled = (value: boolean) => setIsSaveDisabled(value);
  const isEditUserDescriptionLoading = checkRequestStatusEquality(uiState[userActions.editUserDescription?.type]);
  const isEditUserDescriptionSuccess = checkRequestStatusEquality(
    uiState[userActions.editUserDescription?.type],
    RequestStatus.SUCCESS,
  );

  const handleSave = ({ username, bio }: ProfileFormFields) => {
    if (existedName !== username) {
      amplitudeInstance.track('profile_edit_saved', { name: username });
    }
    if (existedBio !== bio) {
      amplitudeInstance.track('profile_edit_saved', { bio });
    }

    dispatch(
      userActions.editUserDescription({
        name: username,
        bio,
        web3Provider: walletService.Web3(),
      }),
    );
  };

  useEffect(() => {
    if (isEditUserDescriptionLoading) setIsSaveDisabled(true);
    if (isEditUserDescriptionSuccess) {
      setIsSaveDisabled(false);
      dispatch({ type: `${userActions.editUserDescription?.type}_RESET` });
      dispatch(modalModel.modalActions.closeModal());
    }
  }, [dispatch, isEditUserDescriptionLoading, isEditUserDescriptionSuccess]);

  return (
    <Stack spacing={9.5}>
      <ProfileForm
        changeIsSubmitDisabled={handleChangeSaveDisabled}
        onSubmit={handleSave}
        initialValues={{ username: existedName, bio: existedBio }}
      >
        <Stack spacing={3.75} mb={3.75}>
          <Stack spacing={2}>
            <Typography variant="subtitle1">Domains</Typography>
            <Stack direction="row" spacing={2}>
              {[...ensNames].map((domain) => domain && <Domain key={domain} name={domain} isEns />)}
              {lens.defaultProfile && (
                <Domain
                  key={lens.defaultProfile.handle}
                  isLens
                  address={lens.defaultProfile.handle}
                  name={lens.defaultProfile.handle}
                />
              )}
              <Domain key={domainName} name={domainName} isR />
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="subtitle1">Credentials</Typography>
            <Stack direction="row" gap={1.5} flexWrap="wrap">
              {badges.map((badgeData) => (
                <Badge key={badgeData.text} {...badgeData} />
              ))}
              <Badge {...gitcoinProvidersToTags.Polygon} />
              <Badge {...gitcoinProvidersToTags.Relinkd} /> {/* TODO default badges to config */}
            </Stack>
          </Stack>
        </Stack>
      </ProfileForm>
      <Button fullWidth type="submit" form="profile-form" disabled={isSaveDisabled || isEditUserDescriptionLoading}>
        {isEditUserDescriptionLoading ? (
          <CircularProgress size={24} sx={{ color: theme.themeColors.background }} />
        ) : (
          'Save'
        )}
      </Button>
    </Stack>
  );
};
