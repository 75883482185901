import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_RADIUS_XS, FontWeights } from 'shared';

export const getMuiTooltipOverrides = (theme: Theme): ComponentsOverrides['MuiTooltip'] => ({
  tooltip: {
    borderRadius: BORDER_RADIUS_XS,
    fontWeight: FontWeights.Medium,
    fontSize: '16px',
    padding: theme.spacing(1),
  },
});

export const getMuiTooltipDefaultProps = (): ComponentsProps['MuiTooltip'] => ({
  arrow: true,
  placement: 'top',
});
