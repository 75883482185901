import { Alchemy, Network } from 'alchemy-sdk';

export async function fetchProfileNFTs(address: string) {
  const config = {
    apiKey: '0omvHRv7RNIXnzfEAO9_FFhFt6qRl5WJ',
    network: Network.ETH_MAINNET,
  };
  const alchemy = new Alchemy(config);

  const nfts = await alchemy.nft.getNftsForOwner(address);

  return nfts.ownedNfts;
}
