import { userModel } from 'entities/user';
import { ContractsNames, createContract, FactoryAbi, getContractDataByItsName, logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { profileActions } from '../reducer';

export function* getProfileContractAddressSaga({
  type,
  payload: { profileAddress },
}: ReturnType<typeof profileActions.getProfileContractAddress>) {
  yield* put(request(type));
  const { chainType } = yield select(userModel.selectors.selectUserWeb3Info);

  const [factoryAbi, factoryContractAddress] = getContractDataByItsName(ContractsNames.Factory, chainType);

  try {
    const factoryContract: FactoryAbi = yield createContract(factoryAbi, factoryContractAddress, chainType);
    const profileContractsArr = yield* call(factoryContract.methods.getUserProfiles(profileAddress).call);
    if (profileContractsArr.length) {
      yield* put(
        profileActions.updateProfileState({
          profileContractAddress: profileContractsArr[0],
        }),
      );
    }

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getProfileContractAddress', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileContractAddress.type, getProfileContractAddressSaga);
}
