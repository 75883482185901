import { useState } from 'react';
import { IconButton, Slider, Stack, styled, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { FontWeights, Icon } from 'shared';

const ValueTypography = styled(Typography)(() => ({
  fontSize: 60,
  fontWeight: FontWeights.Medium,
  lineHeight: '60px',
}));

type RangeSliderProps = {
  value: number;
  setValue: any;
  min: number;
  max: number;
};

export const RangeSlider = ({ value, setValue, min, max }: RangeSliderProps) => {
  const handleSliderChange = (e: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') setValue(newValue);
  };

  const handlePlusClick = () => {
    setValue((prev: any) => prev + 1);
  };

  const handleMinusClick = () => {
    setValue((prev: any) => parseFloat(new BigNumber(prev).minus(1).toFixed(2, 1)));
  };

  return (
    <Stack>
      <ValueTypography variant="h1" className="center">
        {value}
      </ValueTypography>
      <Stack direction="row" alignItems="center" spacing={2} mt={4.5}>
        <IconButton onClick={handleMinusClick} disabled={new BigNumber(value).minus(1).isLessThan(min)}>
          <Icon type="minus" size={30} />
        </IconButton>
        <Slider value={value} min={min} max={max} onChange={handleSliderChange} step={0.01} />
        <IconButton onClick={handlePlusClick} disabled={value + 1 > max}>
          <Icon type="plus" size={30} />
        </IconButton>
      </Stack>
    </Stack>
  );
};
