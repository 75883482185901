import { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Modal, modalModel } from 'entities/modal';
import { profileModel } from 'entities/profile';
import { COLOR_ACCENT, Icon, useShallowSelector } from 'shared';

import { ModalWrapper } from '../ModalWrapper';

export interface CredentialModalPayload {
  type: modalModel.Modals.CredentialModal;
  data: {
    credentialId: number;
    type: 'poaps' | 'zkBadges';
  };
}

export const CredentialModal = forwardRef<HTMLElement, Modal>(({ data: { credentialId, type } }) => {
  const { credentials }: any = useShallowSelector(profileModel.selectors.getProfile);

  const selectedCredential = credentials[type][credentialId];

  return (
    <ModalWrapper title="Credential Details" size="md">
      <Stack mb={6} mt={{ xs: 9, md: 2 }}>
        <Stack spacing={3.75}>
          <Stack direction="row" justifyContent="center" width="100%">
            <Box component="img" width="50%" src={selectedCredential.img} />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{ svg: { path: { stroke: COLOR_ACCENT } } }}
          >
            <Icon type="smile" size={16} />
            <Typography className="medium color-accent">
              {selectedCredential.usersCount.toLocaleString('en-US').replace(/,/g, '.')} have this
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={3.75} mt={7} height="100%">
          <Stack direction="row" justifyContent="center">
            <Typography variant="h2" align="center">
              {selectedCredential.title}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Typography align="center">{selectedCredential.subtitle}</Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={3.75}
          mt={7}
          height="100%"
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack direction="row" justifyContent="left" alignItems="center" sx={{ width: { sm: '50%', xs: '100%' } }}>
            <Icon type="location" size={22} />
            <Stack direction="column" alignItems="flex-start" pl={3}>
              <Typography align="center">Location</Typography>
              <Typography align="center" variant="h3">
                {selectedCredential.event.city}, {selectedCredential.event.country}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            ml="0!important"
            justifyContent="left"
            alignItems="center"
            sx={{ width: { sm: '50%', xs: '100%' } }}
          >
            <Icon type="date" size={22} />
            <Stack direction="column" alignItems="flex-start" pl={3}>
              <Typography align="center">Date</Typography>
              <Typography align="center" variant="h3">
                {selectedCredential.event.start_date}-{selectedCredential.event.end_date}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ModalWrapper>
  );
});

CredentialModal.displayName = 'CredentialModal';
