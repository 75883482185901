import { development, LensClient, production, PublicationTypes } from '@lens-protocol/client';
import { profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import { getToastMessage, logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { profileActions } from '../reducer';

// type lensAuth = {}

export function* followProfileLensSaga({
  type,
  payload: { web3Provider, profileId },
}: ReturnType<typeof profileActions.followProfileLens>) {
  yield* put(request(type));

  const { lens } = yield select(profileModel.selectors.getProfile);
  const { address: userAddress } = yield select(userModel.selectors.getUser);

  const lensClient = new LensClient({
    environment: production,
  });

  try {
    console.log(web3Provider, 'web3providerlens');
    const challenge: string = yield lensClient.authentication.generateChallenge(userAddress);
    const signature: string = yield web3Provider.eth.personal.sign(challenge, userAddress, '');

    yield lensClient.authentication.authenticate(userAddress, signature);

    yield lensClient.proxyAction.freeFollow(profileId);

    yield* put(
      profileActions.updateProfileLens({
        ...lens,
        isFollowed: true,
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('followProfileLens', err);
    getToastMessage('error', 'Something went wrong');
  }
}

export default function* listener() {
  yield takeLatest(profileActions.followProfileLens.type, followProfileLensSaga);
}
