import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'app/store';

import {
  BaseModalPayload,
  ConnectModalPayload,
  CredentialModalPayload,
  GuildModalPayload,
  LinksModalPayload,
  MintModalPayload,
  NFTModalPayload,
  QRModalPayload,
  ScoreModalPayload,
} from '../ui';
import { SendModalPayload } from '../ui/SendModal';

export enum Modals {
  LinksModal = 'LinksModal',
  BaseModal = 'BaseModal',
  SendModal = 'SendModal',
  QRModal = 'QRModal',
  CredentialModal = 'CredentialModal',
  GuildModal = 'GuildModal',
  NFTModal = 'NFTModal',
  ScoreModal = 'ScoreModal',
  ConnectModal = 'ConnectModal',
  MintModal = 'MintModal',
}

export type ModalPayload =
  | LinksModalPayload
  | BaseModalPayload
  | SendModalPayload
  | QRModalPayload
  | CredentialModalPayload
  | GuildModalPayload
  | NFTModalPayload
  | ScoreModalPayload
  | ConnectModalPayload
  | MintModalPayload;

export type ModalState = {
  modal: ModalPayload | null;
};

export const initialState: ModalState = {
  modal: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state: ModalState, action: PayloadAction<ModalState['modal']>) {
      state.modal = action.payload;
    },

    closeModal(state: ModalState) {
      state.modal = null;
    },
  },
});

export const { reducer } = modalSlice;
export const { actions: modalActions } = modalSlice;

export const modalSelector = (state: State): ModalState => state.modal;
