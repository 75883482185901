import { Stack, styled } from '@mui/material';
import { BORDER_RADIUS_S, COLOR_ACCENT, Icon } from 'shared';

export const Card = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(1.25),
  flexDirection: 'row',
  background: theme.themeColors.colorBackground,
  borderRadius: BORDER_RADIUS_S,
  padding: theme.spacing(1.25),

  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}));
