import { userModel } from 'entities/user';
import { ethers } from 'ethers';
import { ContractsNames, createContract, getContractDataByItsName, getToastMessage, ScoreAbi } from 'shared';
import { error, getMaxGas, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { userActions, UserState } from '../reducer';

export function* getScoreMintedTokenId({ type }: ReturnType<typeof userActions.getScoreMintedTokenId>) {
  yield* put(request(type));

  const { chainType, address }: Pick<UserState, 'chainType' | 'address'> = yield select(userModel.selectors.getUser);

  try {
    const [scoreAbi, scoreContractAddress] = getContractDataByItsName(ContractsNames.Score, chainType);

    const scoreContract: ScoreAbi = yield createContract(scoreAbi, scoreContractAddress, chainType);

    const tokenId = yield* call(scoreContract.methods.tokenOfOwnerByIndex(address, 0).call);

    yield* put(
      userActions.updateUserState({
        scoreMintedTokenId: Number(tokenId),
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(userActions.getScoreMintedTokenId.type, getScoreMintedTokenId);
}
