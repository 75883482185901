import { ApolloClient, gql, InMemoryCache } from '@apollo/client';

export async function fetchProfileLens(address: string) {
  const API_URL = 'https://api.lens.dev';

  const client = new ApolloClient({
    uri: API_URL,
    cache: new InMemoryCache(),
  });

  // 0xDD6BFbe9EC414FFABBcc80BB88378c0684e2Ad9c

  const query = gql`query DefaultProfile {
        defaultProfile(request: { ethereumAddress: "${address}"}) {
          id
          name
          bio
          isDefault
          attributes {
            displayType
            traitType
            key
            value
          }
          followNftAddress
          metadata
          handle
          picture {
            ... on NftImage {
              contractAddress
              tokenId
              uri
              chainId
              verified
            }
            ... on MediaSet {
              original {
                url
                mimeType
              }
            }
          }
          coverPicture {
            ... on NftImage {
              contractAddress
              tokenId
              uri
              chainId
              verified
            }
            ... on MediaSet {
              original {
                url
                mimeType
              }
            }
          }
          ownedBy
          dispatcher {
            address
            canUseRelay
          }
          stats {
            totalFollowers
            totalFollowing
            totalPosts
            totalComments
            totalMirrors
            totalPublications
            totalCollects
          }
          followModule {
            ... on FeeFollowModuleSettings {
              type
              contractAddress
              amount {
                asset {
                  name
                  symbol
                  decimals
                  address
                }
                value
              }
              recipient
            }
            ... on ProfileFollowModuleSettings {
             type
            }
            ... on RevertFollowModuleSettings {
             type
            }
          }
        }
      }`;

  const response = await client.query({ query });

  return response.data;
}
