import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { profileModel } from 'entities/profile';
import { routes, useShallowSelector } from 'shared';

import QRIcon from './assets/qr.svg';

export const ProfileQR = () => {
  const dispatch = useDispatch();

  const handleOpenQRModal = () => {
    dispatch(
      modalModel.modalActions.openModal({
        type: Modals.QRModal,
        data: null,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(modalModel.modalActions.closeModal());
    };
  }, [dispatch]);

  return (
    <Box
      sx={{
        cursor: 'pointer',
        position: 'absolute',
        right: '52px',
        top: '20px',
        height: '24px',
        width: '24px',
        filter: 'none!important',
      }}
      component="img"
      src={QRIcon}
      onClick={handleOpenQRModal}
    />
  );
};
