import { Box, Stack, styled, SvgIcon } from '@mui/material';
import { tags } from 'entities/profile';
import { BORDER_RADIUS_S } from 'shared';

export const BadgeWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'background',
})<{ color: string; background: string }>(({ theme, color, background }) => ({
  ...theme.typography.subtitle2,
  color,
  background,
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(0.25, 0.5),
  borderRadius: BORDER_RADIUS_S,
  whiteSpace: 'nowrap',
}));

export type BadgeProps = {
  icon: string;
  text: string;
  color: string;
  background: string;
};

export const Badge = ({ color, background, icon, text }: BadgeProps) => {
  const test: any = tags;
  const Icon = test[icon];

  return (
    <BadgeWrapper color={color} background={background}>
      {/* <Box component="img" src={icon} sx={{ width: '16px', height: '16px', objectFit: 'contain' }}  />  */}
      <Box sx={{ width: '16px', height: '16px', objectFit: 'contain' }}>
        {Icon ? <Icon width="100%" height="100%" /> : null}
        {/* <Icon width="100%" height="100%" /> */}
      </Box>
      {text}

      {/* <SvgIcon>
      <Icon />
    </SvgIcon>  */}
    </BadgeWrapper>
  );
};
