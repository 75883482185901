import * as Yup from 'yup';

export type ProfileFormFields = {
  username: string;
  bio: string;
};
export const initialFormState: ProfileFormFields = {
  username: '',
  bio: '',
};
export const validationSchema = Yup.object({
  username: Yup.string()
    .required('Required')
    .max(20, 'Maximum is 20 symbols')
    .test('Only Empty?', 'Only empty characters are forbidden', (value) => value.split(' ').join('').length !== 0),
  bio: Yup.string().max(500, 'Maximum is 500 symbols'),
});
