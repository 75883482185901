import { Alchemy, Network, TokenBalancesResponseErc20 } from 'alchemy-sdk';
import { ethSymbol } from 'entities/profile/assets/tags';
import { ethers } from 'ethers';

import { fetchTokenRate } from './fetchTokenRate';

export async function fetchProfileTokens(address: string) {
  const config = {
    apiKey: '0omvHRv7RNIXnzfEAO9_FFhFt6qRl5WJ',
    network: Network.ETH_MAINNET,
  };
  const alchemy = new Alchemy(config);

  const { tokenBalances }: TokenBalancesResponseErc20 = await alchemy.core.getTokenBalances(address);

  const tokens: any = [];

  const pushNativeToken = async () => {
    const balance: any = await alchemy.core.getBalance(address);
    console.log(balance);
    const balanceToken = ethers.utils.formatEther(parseInt(balance, 10).toString());
    const tokenPrice = await fetchTokenRate('ETH');

    tokens.push({
      img: ethSymbol,
      value: balanceToken,
      rate: tokenPrice,
      label: 'ETH',
    });
  };

  await pushNativeToken();

  await Promise.all(
    tokenBalances.map(async (token: any) => {
      const metadataToken = await alchemy.core.getTokenMetadata(token.contractAddress);
      const balanceToken = ethers.utils.formatEther(parseInt(token.tokenBalance, 16).toString());
      const tokenPrice = await fetchTokenRate(`${metadataToken.symbol}`);

      tokens.push({
        img: metadataToken.logo,
        value: balanceToken,
        rate: tokenPrice,
        label: metadataToken.symbol,
      });
    }),
  );

  return tokens;
}
