import { userModel } from 'entities/user';
import { ContractsNames, createContract, DomainAbi, getContractDataByItsName, logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { userActions } from '../reducer';

export function* getUserDomainSaga({ type }: ReturnType<typeof userActions.getUserDomain>) {
  yield* put(request(type));
  const { chainType, address: userAddress } = yield select(userModel.selectors.selectUserWeb3Info);
  const [domainAbi, domainContractAddress] = getContractDataByItsName(ContractsNames.Domain, chainType);
  try {
    const domainContract: DomainAbi = yield createContract(domainAbi, domainContractAddress, chainType);
    const domainIdsArr = yield* call(domainContract.methods.getUserTokenIds(userAddress).call);
    if (domainIdsArr.length) {
      const domainInfo = yield* call(domainContract.methods.tokenToInfo(domainIdsArr[0]).call);

      yield* put(
        userActions.updateUserDomain({
          domainTokenId: domainIdsArr[0],
          domainName: domainInfo.domain,
        }),
      );
    }

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getUserDomain', err);
  }
}

export default function* listener() {
  yield takeLatest(userActions.getUserDomain.type, getUserDomainSaga);
}
