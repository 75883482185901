import { useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { flexHelper, getToastMessage, onPinataImageError } from 'shared';
import { BORDER_RADIUS_S } from 'shared/config';

import { Icon } from '../Icon';

type UploadImageComponentProps = {
  isBanner?: boolean;
  disabled?: boolean;
  image?: string;
  onUpload?: (file: File) => void;
};

// 30MB
const MAX_SIZE = 30 * 1024 * 1024;

export const UploadImageComponent = ({
  image,
  isBanner = false,
  disabled = false,
  onUpload,
}: UploadImageComponentProps) => {
  const theme = useTheme();
  const [previewImage, setPreviewImage] = useState(image || '');

  useEffect(() => {
    if (image) {
      setPreviewImage(image as string);
    }
  }, [image]);

  const onDrop = async (acceptedFiles: File[], FileRejections: Array<FileRejection>) => {
    if (!acceptedFiles.length) {
      getToastMessage('error', FileRejections[0].errors[0].message);
      return;
    }

    const [currentFile] = acceptedFiles;

    if (onUpload) {
      try {
        await onUpload(currentFile);
      } catch (err) {
        getToastMessage('error', 'Unable to upload');
      }
    }

    const preview = URL.createObjectURL(currentFile);
    setPreviewImage(preview);
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: { 'image/*': [] },
    maxSize: MAX_SIZE,
    onDrop,
    multiple: false,
  });

  if (isBanner) {
    return (
      <>
        <Stack
          {...(!disabled ? getRootProps() : {})}
          sx={{
            background: theme.themeColors.colorUploadImageBackground,
            width: '100%',
            height: '140px',
            borderRadius: BORDER_RADIUS_S,
            boxSizing: 'border-box',
            cursor: disabled ? 'unset' : 'pointer',

            alignItems: { xs: 'end', sm: 'center' },
            justifyContent: 'center',
            flexDirection: 'column',
            paddingRight: { xs: '94px', sm: 0 },

            ...(previewImage && { display: 'none' }),
          }}
        >
          {!disabled && <input {...getInputProps()} />}

          <Icon type="upload-image" />
          {fileRejections?.[0]?.errors?.[0]?.message && (
            <Typography sx={{ mt: '16px', color: theme.themeColors.colorUploadImageError }}>
              {fileRejections?.[0]?.errors?.[0]?.message}
            </Typography>
          )}
        </Stack>
        <Box
          component="img"
          src={previewImage}
          onError={(e) => onPinataImageError(e, image || '')}
          {...(!disabled ? getRootProps() : {})}
          sx={{
            width: '100%',
            height: '140px',
            borderRadius: BORDER_RADIUS_S,
            objectFit: 'cover',
            cursor: disabled ? 'unset' : 'pointer',

            ...(!previewImage && { display: 'none' }),
          }}
        />
      </>
    );
  }

  return (
    <>
      <Stack
        alignItems="center"
        sx={{
          ...(!previewImage && { display: 'none' }),
        }}
      >
        <Box
          component="img"
          src={previewImage}
          {...(!disabled ? getRootProps() : {})}
          onError={(e) => onPinataImageError(e, image || '')}
          sx={{
            width: '100px',
            height: '100px',
            objectFit: 'cover',
            cursor: disabled ? 'unset' : 'pointer',

            ...(!previewImage && { display: 'none' }),
          }}
        />
      </Stack>
      <Stack
        alignItems="center"
        sx={{
          ...(previewImage && { display: 'none' }),
        }}
      >
        <Box
          {...(!disabled ? getRootProps() : {})}
          sx={{
            ...flexHelper(),
            width: '100px',
            height: '100px',
            background: theme.themeColors.colorUploadImageBackground,
            cursor: disabled ? 'unset' : 'pointer',
          }}
        >
          {!disabled && <input {...getInputProps()} />}
          <Icon type="smile" />
        </Box>
        {fileRejections?.[0]?.errors?.[0]?.message && (
          <Typography sx={{ mt: '16px', color: theme.themeColors.colorUploadImageError }}>
            {fileRejections?.[0]?.errors?.[0]?.message}
          </Typography>
        )}
      </Stack>
    </>
  );
};
