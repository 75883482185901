import axios, { AxiosResponse } from 'axios';
import { profileModel } from 'entities/profile';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { put, select, takeLatest } from 'typed-redux-saga';

import { ProfileState } from '../../types';
import { profileActions } from '../reducer';

export function* updateProfileToken({ type }: ReturnType<typeof profileActions.updateProfileToken>) {
  yield* put(request(type));

  const { address }: Pick<ProfileState, 'address'> = yield select(profileModel.selectors.getProfile);

  const RELINKD_API_URL = `https://api.relinkd.xyz/updateToken`;

  try {
    yield axios.get(`${RELINKD_API_URL}?address=${address}`);

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('updateProfileToken', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.updateProfileToken.type, updateProfileToken);
}
