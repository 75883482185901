import { routes } from 'shared';

export enum PointPathnameIds {
  domain = 1,
  createProfile = 2,
  socialLinks = 3,
  congrats = 4,
}

export const getPointId = (pathname: string): PointPathnameIds => {
  if (pathname === routes.socialLinks.path) return PointPathnameIds.socialLinks;
  if (pathname === routes.createProfile.path) return PointPathnameIds.createProfile;
  if (pathname === routes.congrats.path) return PointPathnameIds.congrats;
  return PointPathnameIds.domain;
};

export type PointPathnameStates = 'active' | 'past' | 'feature';

const getPointState = (id: number, currentId: number): PointPathnameStates => {
  if (id < currentId) return 'past';
  if (id > currentId) return 'feature';
  return 'active';
};

export const getPointsState = (currentPathname: string) => [
  {
    id: PointPathnameIds.domain,
    label: '{r}domain',
    state: getPointState(PointPathnameIds.domain, getPointId(currentPathname)),
  },
  {
    id: PointPathnameIds.createProfile,
    label: 'Edit profile',
    state: getPointState(PointPathnameIds.createProfile, getPointId(currentPathname)),
  },
  {
    id: PointPathnameIds.socialLinks,
    label: 'Social links',
    state: getPointState(PointPathnameIds.socialLinks, getPointId(currentPathname)),
  },
];
