/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { profileModel, verifyGitcoin } from 'entities/profile';
import { UserBlockData } from 'entities/user/model/types';
import { allSocialFieldsData } from 'features/add-links-form';
import { amplitudeInstance, BORDER_RADIUS_M, BORDER_S, FontWeights, Icon, Socials, useShallowSelector } from 'shared';
import { EditLinksForm } from 'widgets';

const Card = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    background: theme.themeColors.colorBackground,
    borderRadius: BORDER_RADIUS_M,
    padding: theme.spacing(2.25),

    border: BORDER_S,
    borderColor: theme.themeColors.colorBorder,
  },
  [theme.breakpoints.down('md')]: {
    background: theme.themeColors.backgroundSecondary,
    borderRadius: 0,
    padding: theme.spacing(2, 4),
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, -2),
  },
}));

const SocialButton = styled(Button)(({ theme }) => ({
  maxWidth: 300,
  background: theme.themeColors.backgroundSecondary,
  fontWeight: FontWeights.Regular,
  '&:hover': {
    backgroundColor: theme.themeColors.buttonContainedBackgroundHoverSecondary,
    color: theme.themeColors.buttonContainedColorHoverSecondary,
  },
  '	.MuiButton-startIcon': { position: 'absolute', left: '16px' },
  '	.MuiButton-endIcon': { position: 'absolute', right: '16px' },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
    background: theme.themeColors.background,
  },
})) as typeof Button;

export const ProfileLinksBlock = () => {
  const dispatch = useDispatch();
  const { links, isCurrentUser } = useShallowSelector(profileModel.selectors.getProfile);
  const { address, gitcoinStamps } = useShallowSelector(profileModel.selectors.getProfile);
  const typedLinks = links as unknown as Array<Omit<UserBlockData, 'blockType'> & { blockType: Socials }>;

  const handleOpenEditModal = () => {
    amplitudeInstance.track('profile_edit_start');
    dispatch(
      modalModel.modalActions.openModal({
        type: Modals.BaseModal,
        data: {
          title: 'Links',
          children: <EditLinksForm />,
        },
      }),
    );
  };

  console.log(links);

  useEffect(() => {
    if (address) {
      dispatch(profileModel.profileActions.getProfileGitcoin());
    }
  }, [dispatch, address]);

  useEffect(() => {
    return () => {
      dispatch(modalModel.modalActions.closeModal());
    };
  }, [dispatch]);

  if (!isCurrentUser && !typedLinks.length) return null;

  return (
    <Card container>
      {isCurrentUser && (
        <Grid item container justifyContent="end" alignItems="center" pb={2.25} xs={12} pr={2}>
          <IconButton onClick={handleOpenEditModal}>
            <Icon type="edit" />
          </IconButton>
        </Grid>
      )}
      {!!typedLinks.length && (
        <Grid item container spacing={1} xs={12} mb={0.75}>
          {typedLinks.map(({ blockType, blockDescription, blockPayload }) => (
            <Grid item xs={12} sm={6} key={blockDescription + (blockPayload?.contents || '')}>
              <SocialButton
                component={Link}
                color="secondary"
                fullWidth
                href={
                  allSocialFieldsData.find(({ name }) => name === Socials[blockType])?.link
                    ? (allSocialFieldsData.find(({ name }) => name === Socials[blockType])?.link || '') +
                      (blockPayload?.contents || '')
                    : blockPayload?.contents.split('https://').length > 1
                    ? blockPayload?.contents
                    : `https://${blockPayload?.contents}`
                }
                target="_blank"
                rel="noreferrer"
                startIcon={
                  <Icon type={allSocialFieldsData.find(({ name }) => name === blockType)?.icon || 'smile'} size={20} />
                } // isCurrentUser &&
                endIcon={
                  gitcoinStamps.socials[blockType] && (
                    <Tooltip
                      title={
                        <Stack py={1}>
                          <Typography color="#FFF">This link is verified via Gitcoin Passport</Typography>
                          <Stack pt={1}>
                            <List sx={{ listStyleType: 'disc!important', pl: 2 }}>
                              <ListItem sx={{ display: 'list-item!important' }}>
                                <ListItemText
                                  primary={<Typography style={{ color: '#FFFFFF' }}>Account encrypted</Typography>}
                                />
                              </ListItem>
                              {gitcoinStamps.socials[blockType].verified.map((stamp: any) => {
                                return (
                                  <ListItem sx={{ display: 'list-item!important' }} key={stamp.title}>
                                    <ListItemText
                                      primary={<Typography style={{ color: '#FFFFFF' }}>{stamp.title}</Typography>}
                                    />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Stack>
                        </Stack>
                      }
                      placement="bottom-end"
                    >
                      <Box>
                        <Icon type="approved" size={20} />
                      </Box>
                    </Tooltip>
                  )
                }
              >
                {blockDescription}
              </SocialButton>
            </Grid>
          ))}
          {isCurrentUser && (
            <Grid spacing={1} pt={2} pl={1} xs={12} sm={12}>
              <SocialButton
                startIcon={<Icon type="gitcoin-passport" size={20} />}
                color="secondary"
                style={{ width: '100%', maxWidth: 'none' }}
                onClick={() => {
                  amplitudeInstance.track('verify_socials');
                  verifyGitcoin();
                }}
              >
                Verify social links
              </SocialButton>
            </Grid>
          )}
        </Grid>
      )}
    </Card>
  );
};
