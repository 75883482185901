// ===== Border-Radius
export const BORDER_RADIUS_L = '30px';
export const BORDER_RADIUS_M = '8px';
export const BORDER_RADIUS_S = '4px';
export const BORDER_RADIUS_XS = '2px';
export const BORDER_RADIUS_XXS = '1px';

// ===== Borders
export const BORDER_L = '4px solid';
export const BORDER_M = '2px solid';
export const BORDER_S = '1px solid';
export const BORDER_WIDTH_HOVER = '1px 1px 4px 1px';
export const BORDER_BOTTOM = '4px solid';

// ===== Transitions
export const TRANSITION_300 = 'all 0.3s ease-out';
export const TRANSITION_500 = 'all 0.5s ease-out';
