import { useDispatch } from 'react-redux';
import { Button, styled } from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';

const WhiteButton = styled(Button)(({ theme }) => ({
  background: theme.themeColors.backgroundSecondary,
  color: theme.themeColors.colorTypographyPrimary,
}));

export const ConnectButton = () => {
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(
      modalModel.modalActions.openModal({
        type: Modals.ConnectModal,
        data: null,
      }),
    );
  };

  return <WhiteButton onClick={openModal}>Connect Wallet</WhiteButton>;
};
