import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import axios, { AxiosResponse } from 'axios';

import { userActions } from '../reducer';

export function* issueCredential({ type, payload: { session, address } }: ReturnType<typeof userActions.issueCredential>) {
  yield* put(request(type));

  try {
    const credential: AxiosResponse = yield axios.get(`https://api.relinkd.xyz/issue?session=${session}&address=${address}`);

    yield* put(
        userActions.updateUserState({
            credential: JSON.stringify(credential.data),
        })
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('issueCredential', err);
  }
}

export default function* listener() {
  yield takeLatest(userActions.issueCredential.type, issueCredential);
}
