import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { profileModel } from 'entities/profile';
import { profileActions } from 'entities/profile/model';
import { userModel } from 'entities/user';
import { LensPost } from 'features/lens-post';
import {
  checkRequestStatusEquality,
  getTheme,
  getToastMessage,
  uiSelector,
  useShallowSelector,
  useWalletConnectorContext,
  useWeb3Provider,
} from 'shared';

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '1px',
  background: theme.themeColors.colorBorder,
}));

export const SocialsBlock = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const selector = useSelector(profileModel.selectors.selectProfileLens);
  const web3Provider = useWeb3Provider();
  const { walletService } = useWalletConnectorContext();
  const location = useLocation();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = () => {
    setIsExpanded(!isExpanded);
  };

  const handleChangeSlow = () => {
    setTimeout(() => {
      setIsExpanded(true);
    }, 300);
  };

  const { address, lens, isCurrentUser } = useShallowSelector(profileModel.selectors.getProfile);
  const { address: userAddress } = useShallowSelector(userModel.selectors.getUser);

  const uiState = useShallowSelector(uiSelector);
  const isFollowingLoading = checkRequestStatusEquality(uiState[profileActions?.getIsProfileFollowed?.type]);

  useEffect(() => {
    dispatch(profileActions.updateProfileLens({ ...lens, defaultProfile: null }));
  }, []);

  const handleFollowClick = () => {
    if (!userAddress) {
      window.open(`/p/connect?redirect=${location.pathname}`, '_self');
      return;
    }
    if (lens.defaultProfile) {
      if (!lens.isFollowed) {
        dispatch(
          profileModel.profileActions.followProfileLens({
            web3Provider: walletService.Web3(),
            profileId: lens.defaultProfile.id,
          }),
        );
      }
    } else {
      getToastMessage('error', "You don't have lens profile");
    }
  };

  const lensterRedirect = () => {
    if (!lens.defaultProfile) return;
    window.open(`https://lenster.xyz/u/${lens.defaultProfile.handle}`, '_blank');
  };

  useEffect(() => {
    dispatch(profileModel.profileActions.getProfileLens());
  }, [dispatch, address, location]);

  useEffect(() => {
    (async () => {
      if (lens.defaultProfile) {
        dispatch(profileModel.profileActions.getProfileLensPosts());
        dispatch(profileModel.profileActions.getIsProfileFollowed());
      }
    })();
  }, [lens.defaultProfile]);

  return (
    <Accordion className={isDownMd ? 'secondary' : ''} onChange={handleChangeSlow}>
      <AccordionSummary className="main">Social graph</AccordionSummary>
      <AccordionDetails>
        <Accordion
          className={isDownMd ? 'secondary' : ''}
          expanded={isExpanded}
          onChange={handleChange}
          sx={{ backgroundColor: 'white!important' }}
        >
          <AccordionSummary className="main">Lens 🌿</AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" spacing={2.5} px={{ xs: 0.5, md: 1.25 }}>
              <Stack width="50%" spacing={0.5}>
                <Typography sx={{ cursor: 'pointer' }} onClick={lensterRedirect} variant="h1">
                  {selector.lens.defaultProfile ? selector.lens.defaultProfile.stats.totalFollowing : 0}
                </Typography>
                <Typography className="small">Following</Typography>
              </Stack>
              <StyledDivider flexItem orientation="horizontal" />
              <Stack width="50%" spacing={0.5}>
                <Typography sx={{ cursor: 'pointer' }} onClick={lensterRedirect} variant="h1">
                  {selector.lens.defaultProfile ? selector.lens.defaultProfile.stats.totalFollowers : 0}
                </Typography>
                <Typography className="small">Followers</Typography>
              </Stack>
            </Stack>
            {!isCurrentUser && lens.defaultProfile && (
              <Button
                variant="contained"
                sx={{ height: 30, mt: 2.5 }}
                className="success"
                fullWidth
                onClick={handleFollowClick}
                disabled={lens.isFollowed}
              >
                {lens.isFollowed ? 'Followed' : 'Follow'}
              </Button>
            )}
            {lens.defaultProfile && (
              <Stack spacing={2.5} px={{ xs: 0.5, md: 1.25 }}>
                <LensPost post={lens.posts[0]} />
              </Stack>
            )}
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
};
