import { userModel } from 'entities/user';
import { error, getIpfsLink, request, success } from 'shared/api';
import { call, put, takeLatest } from 'typed-redux-saga';

import { userActions } from '../reducer';

export function* uploadAvatarSaga({ type, payload: { fileImg } }: ReturnType<typeof userActions.uploadAvatar>) {
  yield* put(request(type));

  const formData = new FormData();
  formData.append('file', fileImg);
  try {
    const { ipfsLink } = yield* call(getIpfsLink, formData);

    yield* put(
      userModel.userActions.updateUserState({
        avatar: ipfsLink,
      }),
    );
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(userActions.uploadAvatar.type, uploadAvatarSaga);
}
