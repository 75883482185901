import { ENS } from '@ensdomains/ensjs';
import { profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import { UserState } from 'entities/user/model';
import { ethers } from 'ethers';
import { Chains, chains, logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ProfileState } from '../../types';
import { profileActions } from '../reducer';
import { GetEnsNamesReq } from '../types';

export function* getEnsNamesSaga({ type }: ReturnType<typeof profileActions.getEnsNames>) {
  yield* put(request(type));
  const { chainType }: Pick<UserState, 'chainType'> = yield select(userModel.selectors.selectUserWeb3Info);
  const { address: profileAddress }: Pick<ProfileState, 'address'> = yield select(profileModel.selectors.getProfile);

  const { chainId } = chains[Chains.Polygon][chainType];
  const rpcUrl = chains[Chains.Polygon][chainType].provider.WalletConnect.provider.rpc.rpc[chainId];
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      'https://mainnet.infura.io/v3/b1108ebd7d3a44e3a665dbb16907b5b4',
    );

    const ENSInstance = new ENS();

    yield call(ENSInstance.setProvider, provider);
    const ensDataArr = yield* call(ENSInstance.getNames, {
      address: profileAddress,
      type: 'owner',
      page: undefined,
      pageSize: undefined,
      orderDirection: 'asc',
      orderBy: 'labelName',
    } as GetEnsNamesReq);

    const ensNames = ensDataArr.map(({ truncatedName }) => truncatedName || '');
    if (ensNames.length) {
      yield* put(
        profileActions.updateProfileState({
          ensNames,
        }),
      );
    }

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getEnsNames', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getEnsNames.type, getEnsNamesSaga);
}
