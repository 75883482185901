import { profileActions } from 'entities/profile/model';
import { getProfileInfoSaga } from 'entities/profile/model/sagas/getProfileInfo';
import { userModel } from 'entities/user';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { userActions, UserState } from '../reducer';
import { UpdateBlockData } from '../types';

import { addBlockSaga } from './addBlock';
import { getUserInfoSaga } from './getUserInfo';
import { updateBlocksSaga } from './updateBlocks';

export function* changeBioSaga({ type, payload: { bio, web3Provider } }: ReturnType<typeof userActions.changeBio>) {
  yield* put(request(type));

  const {
    bioBlockData,
    domain: { domainName },
    address,
  }: Pick<UserState, 'bioBlockData' | 'domain' | 'address'> = yield select(userModel.selectors.getUser);

  try {
    if (bioBlockData) {
      const newBioBlock: UpdateBlockData = {
        blockId: bioBlockData.blockId,
        blockPayload: { contents: bio },
        blockDescription: bioBlockData.blockDescription,
      };

      yield* call(updateBlocksSaga, {
        type: userActions.updateBlocks.type,
        payload: { blocksArray: [newBioBlock], web3Provider },
      });

      yield* put(
        userModel.userActions.updateUserState({
          bio,
        }),
      );
      yield* put(
        userModel.userActions.updateUserBioBlockDataState({
          blockPayload: { contents: bio },
        }),
      );
    } else {
      yield* call(addBlockSaga, {
        type: userActions.addBlock.type,
        payload: {
          blocksArray: [
            {
              blockType: 'Bio',
              blockPayload: { contents: bio },
              blockDescription: ' ',
            },
          ],
          web3Provider,
          withThrowError: true,
        },
      });

      yield* call(getUserInfoSaga, {
        type: userActions.getUserInfo.type,
        payload: undefined,
      });
    }

    yield* call(getProfileInfoSaga, {
      type: profileActions.getProfileInfo.type,
      payload: { name: domainName || address },
    });

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    throw new Error('Bio has not changed');
  }
}

export default function* listener() {
  yield takeLatest(userActions.changeBio.type, changeBioSaga);
}
