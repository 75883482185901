import { forwardRef } from 'react';
import { styled, TextField, TextFieldProps, Typography } from '@mui/material';
import { Box } from '@mui/system';

export type TextfieldWithLabelProps = {
  label: string;
  isError?: boolean;
  isOptional?: boolean;
} & TextFieldProps;

export const TextFieldLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError?: boolean }>(({ theme, isError }) => ({
  color: isError ? theme.themeColors.colorRed : theme.themeColors.colorTypographySecondary,
  marginBottom: theme.spacing(2),
}));

export const TextfieldWithLabel = forwardRef<HTMLInputElement, TextfieldWithLabelProps>(
  ({ label, isError, isOptional, ...textFieldProps }, ref) => {
    return (
      <Box>
        {label && (
          <TextFieldLabel variant="subtitle1" className={isOptional ? 'color-primary' : ''} isError={isError}>
            {label}
            {isOptional && !isError && (
              <Typography component="span" variant="subtitle1">
                {' '}
                (Optional)
              </Typography>
            )}
          </TextFieldLabel>
        )}
        <TextField {...textFieldProps} inputRef={ref} error={isError} />
      </Box>
    );
  },
);

TextfieldWithLabel.displayName = 'TextfieldWithLabel';
