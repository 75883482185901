import axios from 'axios';

import { gitcoinProvidersToLinksMatches, gitcoinProvidersToTags, gitcoinStampsToSocials } from '../../config';

export async function fetchProfileGitcoin(address: string) {
  const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': 'Jtqysgdo.I7yu9LfaQTklHrvuak9Q8CQUAlrHMkLs',
    Accept: 'application/json',
  };

  const GITCOIN_API_URL = 'https://api.scorer.gitcoin.co/registry/stamps';
  const GITCOIN_API_METADATA = 'https://api.scorer.gitcoin.co/registry/stamp-metadata';

  const response = await axios.get(`${GITCOIN_API_URL}/${address}?include_metadata=true`, { headers });
  // const response2 = await axios.get(`${GITCOIN_API_METADATA}`, { headers } );

  // console.log(response2, 'gitcoin metadata')

  console.log(response, 'gitcoin metadata');

  const filteredSocialStamps = response.data.items.filter((stamp: any) => {
    return !!gitcoinProvidersToLinksMatches[stamp.credential.credentialSubject.provider];
  });

  const filteredTagsStamps = response.data.items.filter((stamp: any) => {
    return !!gitcoinProvidersToTags[stamp.credential.credentialSubject.provider];
  });

  const filteredSocialSmallStamps = response.data.items.filter((stamp: any) => {
    return !!gitcoinStampsToSocials[stamp.credential.credentialSubject.provider];
  });

  console.log(filteredSocialSmallStamps, 'uuu!');

  console.log(filteredTagsStamps, 'tags');

  const socials: any = {};
  const tags: any = [];

  filteredSocialStamps.forEach((stamp: any) => {
    socials[gitcoinProvidersToLinksMatches[stamp.credential.credentialSubject.provider]] = { ...stamp, verified: [] };
  });

  filteredTagsStamps.forEach((stamp: any) => {
    tags.push(gitcoinProvidersToTags[stamp.credential.credentialSubject.provider]);
  });

  filteredSocialSmallStamps.forEach((stamp: any) => {
    if (!socials[gitcoinStampsToSocials[stamp.credential.credentialSubject.provider].provider]) return;

    socials[gitcoinStampsToSocials[stamp.credential.credentialSubject.provider].provider].verified.push(
      gitcoinStampsToSocials[stamp.credential.credentialSubject.provider],
    );
  });

  return { socials, tags };
}
