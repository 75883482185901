import axios, { AxiosResponse } from 'axios';
import { profileModel } from 'entities/profile';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';

import { profileActions } from '../reducer';

export function* getProfileGitcoinScore({ type }: ReturnType<typeof profileActions.getProfileGitcoinScore>) {
  yield put(request(type));

  const { address } = yield select(profileModel.selectors.getProfile);

  try {
    const { data } = yield axios({
      method: 'get',
      url: `https://api.scorer.gitcoin.co/registry/score/97/${address}`,
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'Jtqysgdo.I7yu9LfaQTklHrvuak9Q8CQUAlrHMkLs',
        Accept: 'application/json',
      },
    });

    yield* put(profileActions.updateProfileScores({ gitcoin: data.score ? data.score : -2 }));

    yield put(success(type));
  } catch (err) {
    yield put(error(type, err));
    console.error('Failed to get profile gitcoin score', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileGitcoinScore.type, getProfileGitcoinScore);
}
