import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_RADIUS_S, BORDER_S } from 'shared/config';

export const getMuiButtonOverrides = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: {
    fontSize: 18,
    lineHeight: '20px',
    textTransform: 'unset',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
  },

  sizeMedium: {
    fontSize: 16,
    height: 48,
    minWidth: '48px',
    padding: theme.spacing(1.75, 1.5),

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },

  sizeLarge: {
    height: 56,
    minWidth: '56px',
  },

  startIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    marginRight: theme.spacing(1.25),
  },

  endIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    marginLeft: theme.spacing(1.25),
  },

  contained: {
    backgroundColor: theme.themeColors.buttonContainedBackground,
    color: theme.themeColors.buttonContainedColor,
    borderRadius: BORDER_RADIUS_S,
    padding: theme.spacing(2, 3.75),

    '&:hover, &.active': {
      background: theme.themeColors.buttonContainedBackgroundHover,
      color: theme.themeColors.buttonContainedColorHover,
    },
    '&.active': {
      svg: { path: { fill: theme.themeColors.buttonContainedColor } },
    },
    '&:disabled': {
      backgroundColor: theme.themeColors.buttonDisabledBackground,
      color: theme.themeColors.buttonDisabledColor,
    },

    '&.success': {
      backgroundColor: theme.themeColors.buttonSuccessBackground,
      color: theme.themeColors.buttonSuccessColor,
      '&:hover, &.active': {
        background: theme.themeColors.buttonSuccessBackgroundHover,
        color: theme.themeColors.buttonSuccessColorHover,
      },
      '&.active': {
        svg: { path: { fill: theme.themeColors.buttonSuccessColor } },
      },
      '&:disabled': {
        backgroundColor: theme.themeColors.buttonSuccessBackground,
        color: theme.themeColors.buttonSuccessColor,
      },
    },

    '&.error': {
      backgroundColor: theme.themeColors.buttonErrorBackground,
      color: theme.themeColors.buttonErrorColor,
      '&:hover, &.active': {
        background: theme.themeColors.buttonErrorBackgroundHover,
        color: theme.themeColors.buttonErrorColorHover,
      },
      '&.active': {
        svg: { path: { fill: theme.themeColors.buttonErrorColor } },
      },
      '&:disabled': {
        backgroundColor: theme.themeColors.buttonErrorBackground,
        color: theme.themeColors.buttonErrorColor,
      },
    },
  },
  containedSecondary: {
    color: theme.themeColors.buttonContainedSecondaryColor,
    background: theme.themeColors.buttonContainedSecondaryBackground,
    '&:hover': {
      background: theme.themeColors.buttonContainedSecondaryBackgroundHover,
      color: theme.themeColors.buttonContainedSecondaryColorHover,
    },
  },

  outlined: {
    color: theme.themeColors.buttonOutlinedColor,
    background: theme.themeColors.buttonOutlinedBackground,
    border: BORDER_S,
    borderRadius: BORDER_RADIUS_S,
    borderColor: theme.themeColors.buttonOutlinedColorBorder,
    transition: '0.3s all ease',

    '&:hover, &.active': {
      background: theme.themeColors.buttonOutlinedBackgroundHover,
      color: theme.themeColors.buttonOutlinedColorHover,
      borderColor: theme.themeColors.buttonOutlinedBackgroundHover,
      '&:not(.stroke-color)': {
        svg: {
          path: {
            fill: theme.themeColors.buttonOutlinedColorHover,
            transition: '0.3s all ease',
          },
        },
      },
      '&.stroke-color': {
        svg: {
          path: {
            stroke: theme.themeColors.buttonOutlinedColorHover,
            transition: '0.3s all ease',
          },
        },
      },
    },

    '@media (hover: none)': {
      '&:hover:not(.active)': {
        background: theme.themeColors.buttonOutlinedBackground,
        color: theme.themeColors.buttonOutlinedColor,
        borderColor: theme.themeColors.buttonOutlinedColorBorder,
        svg: {
          path: {
            fill: theme.themeColors.buttonOutlinedColor,
          },
        },
      },
    },

    '&:disabled': {
      backgroundColor: theme.themeColors.buttonDisabledBackground,
      color: theme.themeColors.buttonDisabledColor,
      borderColor: theme.themeColors.buttonDisabledBackground,
      '&:not(.stroke-color)': {
        svg: {
          path: {
            fill: theme.themeColors.buttonDisabledColor,
            transition: '0.3s all ease',
          },
        },
      },
      '&.stroke-color': {
        svg: {
          path: {
            stroke: theme.themeColors.buttonDisabledColor,
            transition: '0.3s all ease',
          },
        },
      },
    },
  },

  text: {
    ...theme.typography.button,
    '&:hover, &:active': {
      background: 'none',
    },
  },
});

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  size: 'large',
  variant: 'contained',
});
