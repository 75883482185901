import { userModel } from 'entities/user';
import { userActions, UserState } from 'entities/user/model';
import { ContractsNames, getContractDataByItsName, getToastMessage, logger, ProfileAbi } from 'shared';
import { error, getMaxGas, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { getUserInfoSaga } from './getUserInfo';

export function* addBlockSaga({
  type,
  payload: { web3Provider, blocksArray, withGetUserInfoOnSuccess = false, withThrowError = false },
}: ReturnType<typeof userActions.addBlock>) {
  yield* put(request(type));

  const {
    chainType,
    address: userAddress,
    profileContractAddress,
  }: Pick<UserState, 'chainType' | 'address' | 'profileContractAddress'> = yield select(userModel.selectors.getUser);

  const [profileAbi] = getContractDataByItsName(ContractsNames.Profile, chainType);

  try {
    const profileContract: ProfileAbi = yield new web3Provider.eth.Contract(profileAbi, profileContractAddress);
    const formatedBlocksArray = blocksArray.map(
      ({ blockType, blockPayload, blockDescription }): [string, string, string] => [
        blockType,
        JSON.stringify(blockPayload),
        blockDescription,
      ],
    );

    const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
    const { transactionHash } = yield* call(profileContract.methods.addBlock(formatedBlocksArray).send, {
      from: userAddress,
      to: profileContractAddress,
      maxFeePerGas,
      maxPriorityFeePerGas,
    });

    if (withGetUserInfoOnSuccess) {
      yield* call(getUserInfoSaga, {
        type: userActions.getUserInfo.type,
        payload: undefined,
      });
    }

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    getToastMessage('error', 'Something went wrong');
    if (withThrowError) {
      throw new Error('Adding failed');
    }
    logger('addBlock', err);
  }
}

export default function* listener() {
  yield takeLatest(userActions.addBlock.type, addBlockSaga);
}
