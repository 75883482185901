import { fetchProfileZKBadges, profileModel } from 'entities/profile';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { CredentialData, ProfileState } from '../../types';
import { profileActions } from '../reducer';

export function* getProfileZKBadgesSaga({ type }: ReturnType<typeof profileActions.getProfilePoaps>) {
  yield* put(request(type));

  const { address }: Pick<ProfileState, 'address'> = yield select(profileModel.selectors.getProfile);

  try {
    const zkBadges: CredentialData[] = yield fetchProfileZKBadges(address);

    yield* put(
      profileActions.updateProfileCredentials({
        zkBadges,
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getProfilePoaps', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileZKBadges.type, getProfileZKBadgesSaga);
}
