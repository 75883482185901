import { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, BoxProps, Container, Stack, styled } from '@mui/material';
import { useWindowState } from 'shared';

import { BgLarge, BgSmall } from './assets';
import { Header } from './header';

const StyledLayout = styled(Box)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  position: 'relative',
  background: props.theme.themeColors.colorBackground,
  zIndex: 1,
  ...(props.className && {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: props.className === 'withBgSm' ? `url(${BgSmall})  no-repeat` : `url(${BgLarge}) no-repeat`,
      backgroundSize: '100%',
      backgroundPosition: props.className === 'withBgSm' ? 'center' : 'unset',
      backgroundBlendMode: 'screen',
      zIndex: '-1',
      [props.theme.breakpoints.down('md')]: {
        background: 'none',
      },
    },
  }),
}));

export enum LayoutBackground {
  Lg = 'withBgLg',
  Sm = 'withBgSm',
}

type LayoutProps = {
  bg?: LayoutBackground;
  footerChildren?: ReactNode;
};

export const Layout: FC<PropsWithChildren<LayoutProps & BoxProps>> = ({
  children,
  footerChildren,
  bg = null,
  ...boxProps
}) => {
  const { height: windowHeight } = useWindowState();

  return (
    <StyledLayout className={bg || ''} {...boxProps}>
      <Header />
      <Container
        sx={{
          pt: 2,
          px: { xs: 1, md: 0 },
          flex: windowHeight > 922 || windowHeight === 0 ? 'unset' : 1,
          marginBottom: { xs: 1, sm: 4 },
          minHeight: windowHeight > 922 || windowHeight === 0 ? 700 : 'unset',
        }}
      >
        {children}
      </Container>
      <Stack maxWidth={1440} width="100%" mx="auto">
        {footerChildren && footerChildren}
      </Stack>
    </StyledLayout>
  );
};
