import { COLOR_ACCENT, COLOR_BLACK, COLOR_RED, COLOR_TEXT_GRAY, COLOR_TEXTFIELD_LIGHT } from '../colors.constants';

export const colorsTextField = {
  // Textfield color
  textFieldBorderFocused: COLOR_ACCENT,
  textFieldColorDisabled: COLOR_TEXT_GRAY,
  textFieldColorError: COLOR_RED,
  textFieldFormLabel: COLOR_TEXT_GRAY,

  textFieldBackground: COLOR_TEXTFIELD_LIGHT,
  textFieldColor: COLOR_BLACK,
  textFieldActiveColor: COLOR_ACCENT,
  textFieldBorderHover: COLOR_ACCENT,
};
