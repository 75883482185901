import { userModel } from 'entities/user';
import { getToastMessage, logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { userActions, UserState } from '../reducer';

import { changeBioSaga } from './changeBio';
import { changeNameSaga } from './changeName';

export function* editUserDescriptionSaga({
  type,
  payload: { name, bio, web3Provider },
}: ReturnType<typeof userActions.editUserDescription>) {
  yield* put(request(type));

  const { name: existedName, bio: existedBio }: Pick<UserState, 'name' | 'bio'> = yield select(
    userModel.selectors.getUser,
  );

  try {
    if (name !== existedName) {
      yield* call(changeNameSaga, {
        type: userActions.changeName.type,
        payload: { name, web3Provider },
      });
    }

    if (existedBio !== bio || !existedBio) {
      yield* call(changeBioSaga, {
        type: userActions.changeBio.type,
        payload: { bio, web3Provider },
      });
    }
    getToastMessage('success', 'Successfully changed');

    yield* put(success(type));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    yield* put(error(type, err));
    logger('wrapper', err);
    getToastMessage('error', err.message);
  }
}

export default function* listener() {
  yield takeLatest(userActions.editUserDescription.type, editUserDescriptionSaga);
}
