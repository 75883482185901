import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import axios, { AxiosResponse } from 'axios';

import { userActions } from '../reducer';

export function* connectPlug({ type }: ReturnType<typeof userActions.getAuthQR>) {
  yield* put(request(type));

  try {
    yield window?.ic?.plug.requestConnect();

    yield* put(
        userActions.updateUserState({
            icpAddress: window?.ic?.plug?.principalId,
        })
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getAuthQR', err);
  }
}

export default function* listener() {
  yield takeLatest(userActions.connectPlug.type, connectPlug);
}
