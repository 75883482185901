export const verifyGitcoin = () => {
  const GITCOIN_URL = 'https://passport.gitcoin.co/';

  const width = window.innerWidth / 2;
  const height = window.innerHeight / 2;
  const left = window.innerWidth / 4;
  const top = window.innerHeight / 4;
  const options = `width=${width},height=${height},left=${left},top=${top}`;

  window.open(GITCOIN_URL, '_blank', options);
};
