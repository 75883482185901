import { Box } from '@mui/material';
import { Chains, CoinbaseLogo, CoinbaseLogoMobile, Icon, WalletProviders } from 'shared';

export enum TextVariants {
  Metamask = 'Metamask',
  WalletConnect = 'Wallet Connect',
  CoinbaseWallet = 'Coinbase Wallet',
}

export const walletsOptions = [
  {
    provider: WalletProviders.metamask,
    chain: Chains.Polygon,
    icon: <Icon type="metamask" size={80} />,
    iconMobile: <Icon type="metamask" size={40} />,
    text: TextVariants.Metamask,
  },
  {
    provider: WalletProviders.walletConnect,
    chain: Chains.Polygon,
    icon: <Icon type="wallet-connect" size={80} />,
    iconMobile: <Icon type="wallet-connect-mobile" size={40} />,
    text: TextVariants.WalletConnect,
  },
  {
    provider: WalletProviders.coinbase,
    chain: Chains.Polygon,
    icon: <Box component="img" src={CoinbaseLogo} width={80} height={80} />,
    iconMobile: <Box component="img" src={CoinbaseLogoMobile} width={40} height={40} />,
    text: TextVariants.CoinbaseWallet,
  },
];
