import { toast } from 'react-toastify';
import { userModel } from 'entities/user';
import { userActions, UserState } from 'entities/user/model';
import { ContractsNames, getContractDataByItsName, logger, ProfileAbi } from 'shared';
import { error, getMaxGas, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

export function* deleteBlocksSaga({
  type,
  payload: { web3Provider, blocksIds },
}: ReturnType<typeof userActions.deleteBlocks>) {
  yield* put(request(type));

  const {
    chainType,
    address: userAddress,
    profileContractAddress,
  }: Pick<UserState, 'chainType' | 'address' | 'profileContractAddress'> = yield select(userModel.selectors.getUser);

  const [profileAbi] = getContractDataByItsName(ContractsNames.Profile, chainType);

  try {
    const profileContract: ProfileAbi = yield new web3Provider.eth.Contract(profileAbi, profileContractAddress);

    const sortedBlocksIds = blocksIds.sort((a, b) => b - a);

    const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
    const { transactionHash } = yield* call(profileContract.methods.deleteBlock(sortedBlocksIds).send, {
      from: userAddress,
      to: profileContractAddress,
      maxFeePerGas,
      maxPriorityFeePerGas,
    });

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('deleteBlocks', err);
    throw new Error('Deletion failed');
  }
}

export default function* listener() {
  yield takeLatest(userActions.deleteBlocks.type, deleteBlocksSaga);
}
