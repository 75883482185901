import { ComponentsOverrides, Theme } from '@mui/material';

import { BORDER_RADIUS_XS } from '../common';

export const getMuiSlider = (theme: Theme): ComponentsOverrides['MuiSlider'] => {
  return {
    root: {
      color: theme.themeColors.colorSlider,
      height: 1,
    },
    rail: {
      color: theme.themeColors.colorSlider,
      opacity: 1,
      height: 1,
    },
    thumb: {
      height: 56,
      width: 8,
      background: theme.themeColors.colorSliderThumb,
      borderRadius: BORDER_RADIUS_XS,

      '&.Mui-active, &:hover': {
        color: theme.themeColors.colorSliderThumb,
        boxShadow: 'none !important',
      },
    },
    active: {},
    valueLabel: {
      display: 'none',
    },
    track: {
      borderRadius: 4,
      border: 'none',
      color: theme.themeColors.colorSlider,
    },
  };
};
