import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { userModel } from 'entities/user';
import { userActions } from 'entities/user/model';
import { amplitudeInstance, checkRequestStatusEquality, routes, uiSelector, useShallowSelector } from 'shared';
import { Layout } from 'widgets';

export const AgreementPage = () => {
  const navigate = useNavigate();
  const uiState = useShallowSelector(uiSelector);
  const {
    name,
    address,
    links,
    profileContractAddress,
    domain: { domainName },
  } = useShallowSelector(userModel.selectors.getUser);
  const dispatch = useDispatch();

  const isGetUserLoading = checkRequestStatusEquality(uiState[userActions.getUserInfo?.type]);

  useEffect(() => {
    if ((domainName && profileContractAddress) || profileContractAddress) {
      navigate(routes.profile.getPathname(domainName || profileContractAddress));
    }
  });

  const handleButtonClick = () => {
    amplitudeInstance.track('welcomescreen_viewed');
    if (!address) {
      dispatch(
        modalModel.modalActions.openModal({
          type: Modals.ConnectModal,
          data: null,
        }),
      );
    }

    if (address) {
      if (links.length) {
        navigate(routes.profile.getPathname(domainName || address));
      } else if (name) {
        navigate(routes.socialLinks.path);
      } else if (domainName) {
        navigate(routes.createProfile.path);
      } else {
        navigate(routes.domain.getPathname('initial'));
      }
    }
  };

  return (
    <Layout>
      <Typography variant="h1" mt={{ xs: 3, md: 10 }} mb={{ xs: 5, md: 9 }}>
        Complete your identity profile in 3 easy steps.
      </Typography>
      <Stack spacing={4.125} mb={{ xs: 5, md: 10, xl: 15 }}>
        <Stack>
          <Typography>
            You will be prompted to confirm the profile in a true web3 manner than involves confirming 3 transactions on
            Polygon network (don&apos;t worry, it costs a couple of cents):
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="1. domain name mint" />
            </ListItem>
            <ListItem>
              <ListItemText primary="2. profile smart contract deployment" />
            </ListItem>
            <ListItem>
              <ListItemText primary="3. profile information adding" />
            </ListItem>
          </List>
        </Stack>
      </Stack>
      <Button variant="contained" fullWidth onClick={handleButtonClick} disabled={isGetUserLoading}>
        Let&apos;s go!
      </Button>
    </Layout>
  );
};
