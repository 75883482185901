import { Box, styled } from '@mui/material';
import { COLOR_GREEN, COLOR_WH } from 'shared';
import { BORDER_RADIUS_XS, BORDER_S } from 'shared/config';

export const OnlinePoint = styled(Box)(() => ({
  width: 8,
  height: 8,
  background: COLOR_GREEN,
  borderRadius: BORDER_RADIUS_XS,
  border: BORDER_S,
  borderColor: COLOR_WH,
}));
