import { domainModel } from 'entities/domain';
import { userModel } from 'entities/user';
import { UserState } from 'entities/user/model';
import { ContractsNames, createContract, FactoryAbi, fromDecimals, getContractDataByItsName, logger } from 'shared';
import { error, getMaxGas, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { domainActions } from '../reducer';

export function* getFactoryStateSaga({ type }: ReturnType<typeof domainActions.getFactoryState>) {
  yield* put(request(type));
  const { chainType }: Pick<UserState, 'chainType'> = yield select(userModel.selectors.getUser);
  const [factoryAbi, factoryContractAddress] = getContractDataByItsName(ContractsNames.Factory, chainType);
  try {
    const factoryContract: FactoryAbi = yield createContract(factoryAbi, factoryContractAddress, chainType);

    const { maxFeePerGas } = yield* call(getMaxGas);
    const factoryState = yield* call(factoryContract.methods.factoryState().call);

    yield* put(
      domainModel.domainActions.updateDomainState({
        isFirstDomainFree: factoryState.isFirstDomainFree,
        domainPrice: +fromDecimals(factoryState.domainPrice),
        maxFeePerGas: +fromDecimals(maxFeePerGas || 0),
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getFactoryState', err);
  }
}

export default function* listener() {
  yield takeLatest(domainActions.getFactoryState.type, getFactoryStateSaga);
}
