import { domainAbi, factoryAbi, profileAbi, scoreAbi } from './abi';
import { Chains, ContractsNames, IContracts } from './types';

export const contracts: IContracts['contracts'] = {
  [ContractsNames.Factory]: {
    testnet: {
      address: {
        [Chains.Polygon]: '0x88f479CB776a7A3f67016036042e1Cf336cB2342',
      },
      abi: factoryAbi,
    },
    mainnet: {
      address: {
        [Chains.Polygon]: '0x7cFeD0274D01dD32C0fd8dE7016c0c9bB4faf624',
      },
      abi: factoryAbi,
    },
  },
  [ContractsNames.Domain]: {
    testnet: {
      address: {
        [Chains.Polygon]: '0xC278913cF7B5B92AFa6dE06F098214a35B06F4bD',
      },
      abi: domainAbi,
    },
    mainnet: {
      address: {
        [Chains.Polygon]: '0x7c642658327BF5765057b23BdA2C9652f5f20332',
      },
      abi: domainAbi,
    },
  },
  [ContractsNames.Profile]: {
    testnet: {
      address: {
        [Chains.Polygon]: '0xf56C60B777CDeEfb8d0E70981872533a31a7791B',
      },
      abi: profileAbi,
    },
    mainnet: {
      address: {
        [Chains.Polygon]: '0x636656219417AC9A5D4d5d4c7D29C98c0aA5DFd0',
      },
      abi: profileAbi,
    },
  },
  [ContractsNames.Score]: {
    testnet: {
      address: {
        [Chains.Polygon]: '0x78114F22B19A38f630467a3d6CeB9552b84E83a1',
      },
      abi: scoreAbi,
    },
    mainnet: {
      address: {
        [Chains.Polygon]: '0x90b7D42b7179753229196130B441A0401b34ed18',
      },
      abi: scoreAbi,
    },
  },
};

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts,
};
