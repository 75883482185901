import { AgreementPage } from 'pages/agreement';
import { CongratsPage } from 'pages/congrats';
import { ConnectPage } from 'pages/connect';
import { CreateProfilePage } from 'pages/create-profile';
import { DomainPage } from 'pages/domain';
import { NoProfile } from 'pages/no-profile';
import { NoWhitelistedPage } from 'pages/no-whitelisted';
import { ProfilePage } from 'pages/profile';
import { SocialLinksPage } from 'pages/socialLinks';
import { routes } from 'shared';

import { RoutesProps } from './types';

export const appRoutes: RoutesProps = {
  connect: {
    path: routes.connect.path,
    component: <ConnectPage />,
  },
  agreement: {
    path: routes.agreement.path,
    component: <AgreementPage />,
  },
  domain: {
    path: routes.domain.path,
    component: <DomainPage />,
    isProtected: true,
  },
  socialLinks: {
    path: routes.socialLinks.path,
    component: <SocialLinksPage />,
    isProtected: true,
  },
  createProfile: {
    path: routes.createProfile.path,
    component: <CreateProfilePage />,
    isProtected: true,
  },
  congrats: {
    path: routes.congrats.path,
    component: <CongratsPage />,
    isProtected: true,
  },
  profile: {
    path: routes.profile.path,
    component: <ProfilePage />,
  },
  noWhitelisted: {
    path: routes.noWhitelisted.path,
    component: <NoWhitelistedPage />,
    isProtected: true,
  },
  noProfile: {
    path: routes.noProfile.path,
    component: <NoProfile />,
  },
};
