import { useState } from 'react';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { Icon, SocialField } from 'shared';

import { optionalSocialFieldsData } from './config';

type AddLinksFormProps = { onSave: (v: SocialField[]) => void };

export const AddLinksForm = ({ onSave }: AddLinksFormProps) => {
  const [chosenSocials, setChosenSocials] = useState<SocialField[]>([]);

  const onChooseSocialClick = (social: SocialField) => {
    setChosenSocials((prev) =>
      prev.find((prevSocial) => prevSocial.name === social.name)
        ? [...prev.filter((prevSocial) => prevSocial.name !== social.name)]
        : [...prev, social],
    );
  };

  const handleSave = () => {
    onSave(chosenSocials);
  };

  return (
    <Stack spacing={{ xs: 4, md: 8 }}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        {optionalSocialFieldsData.map(({ name, label, icon, link, placeholder }) => (
          <Button
            variant="outlined"
            className={chosenSocials?.find((activeSocial) => activeSocial.name === name) ? 'active' : ''}
            key={label}
            fullWidth
            onClick={() => onChooseSocialClick({ name, label, icon, link, placeholder })}
            sx={{ maxWidth: 292, span: { position: 'absolute', left: '16px' } }}
            startIcon={<Icon type={icon} size={32} />}
          >
            {name}
          </Button>
        ))}
      </Stack>
      <Button fullWidth onClick={handleSave}>
        Save
      </Button>
    </Stack>
  );
};
