import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  styled,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { fetchTokenRate, profileModel } from 'entities/profile';
import { profileActions, selectors } from 'entities/profile/model';
import { CredentialAccordion, credentialsMock } from 'features';
import { GuildAccordion } from 'features/guild-accordion';
import { amplitudeInstance, BORDER_RADIUS_S, BORDER_S, Icon, useShallowSelector } from 'shared';

import { credentialAccordionsMock } from '../config';

const Block = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'row',
  gap: theme.spacing(1.5),
  borderRadius: BORDER_RADIUS_S,
  border: BORDER_S,
  height: '56px',
  marginBottom: theme.spacing(3.75),

  borderColor: theme.themeColors.colorBorder,
  padding: theme.spacing(1.875, 1),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2.25, 2),
    background: theme.themeColors.background,
    borderColor: 'transparent',
  },
}));

const ExpandWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(1.5, 1),
  cursor: 'pointer',
}));

export const SkillsSection = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const { credentials } = useShallowSelector(selectors.selectProfileCredentials);

  const { address, guilds } = useShallowSelector(profileModel.selectors.getProfile);

  useEffect(() => {
    if (address) {
      dispatch(profileModel.profileActions.getProfilePoaps());
      dispatch(profileModel.profileActions.getProfileZKBadges());
      dispatch(profileModel.profileActions.getProfileGuilds());
      dispatch(profileModel.profileActions.getProfileNFTs());
      dispatch(profileModel.profileActions.getProfileTokens());
    }
  }, [dispatch, address]);
  const { isCurrentUser } = useShallowSelector(profileModel.selectors.getProfile);
  const accordions = credentialAccordionsMock;

  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);

  const handleToggle = (label: string) => {
    setExpandedAccordions((prev) =>
      prev.find((item) => item === label) ? prev.filter((l) => l !== label) : [...prev, label],
    );
  };

  const handleExtend = () => {
    setExpandedAccordions(accordions.map(({ label }) => label));
  };
  const handleCollapse = () => {
    setExpandedAccordions([]);
  };

  const handleSwitchClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
  const handleEditClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    amplitudeInstance.track('profile_edit_start');
  };

  return (
    <Accordion className={isDownMd ? 'secondary' : ''}>
      <AccordionSummary className="main">
        Skills & Credentials
        {/* {isCurrentUser && (
          <Stack direction="row" spacing={2} ml="auto" mr={2}>
            <IconButton onClick={handleEditClick}>
              <Icon type="edit" />
            </IconButton>
            <Switch onClick={handleSwitchClick} />
          </Stack>
        )} */}
      </AccordionSummary>
      <AccordionDetails>
        <Block>
          <Typography className="color-primary">All</Typography>
          <Typography>{credentials.poaps.length + credentials.zkBadges.length + guilds.length}</Typography>
          <IconButton sx={{ ml: 'auto', mr: 1 }}>
            <Icon type="sort" />
          </IconButton>
        </Block>
        <Stack spacing={2}>
          {/* <CredentialAccordion label="Verifiable credentials" credentials={credentialsMock} isExpanded={expandedAccordions.includes("Verifiable credentials")} onToggle={handleToggle}/> */}
          <CredentialAccordion
            label="POAPs"
            credentials={credentials.poaps}
            isExpanded={expandedAccordions.includes('POAPs')}
            onToggle={handleToggle}
            type="poaps"
          />
          <CredentialAccordion
            label="ZK Badges"
            credentials={credentials.zkBadges}
            isExpanded={expandedAccordions.includes('ZK Badges')}
            onToggle={handleToggle}
            type="zkBadges"
          />
          <GuildAccordion
            label="Guilds"
            guilds={guilds}
            isExpanded={expandedAccordions.includes('Guilds')}
            onToggle={handleToggle}
          />
          {/* {accordions.map(({ label, credentials }) => (
            <CredentialAccordion
              key={label}
              label={label}
              credentials={credentials}
              isExpanded={expandedAccordions.includes(label)}
              onToggle={handleToggle}
            />
          ))} */}
        </Stack>

        <ExpandWrapper mt={3} onClick={handleExtend}>
          <Typography>Extend all </Typography> <Icon type="extend" />
        </ExpandWrapper>

        <ExpandWrapper mb={{ xs: -1, md: 1 }} onClick={handleCollapse}>
          <Typography>Collapse all </Typography> <Icon type="collapse" />
        </ExpandWrapper>
      </AccordionDetails>
    </Accordion>
  );
};
