import { userModel } from 'entities/user';
import { fromDecimals, logger } from 'shared';
import { error, request, success } from 'shared/api';
import { put, select, takeLatest } from 'typed-redux-saga';

import { userActions } from '../reducer';

export function* getUserBalanceSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof userActions.getUserBalance>) {
  yield* put(request(type));
  const { address: userAddress } = yield select(userModel.selectors.selectUserWeb3Info);
  try {
    const nativeBalanceInWei: string = yield web3Provider.eth.getBalance(userAddress);
    const balance = fromDecimals(nativeBalanceInWei);

    yield* put(
      userActions.updateUserState({
        balance,
      }),
    );
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getUserBalance', err);
  }
}

export default function* listener() {
  yield takeLatest(userActions.getUserBalance.type, getUserBalanceSaga);
}
