import { Alchemy, Network } from 'alchemy-sdk';
import { fetchProfileTokens, profileModel } from 'entities/profile';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ProfileState } from '../../types';
import { profileActions } from '../reducer';

// export function* fetchGuildData(guild: any) {
//   const config = {
//     apiKey: "0omvHRv7RNIXnzfEAO9_FFhFt6qRl5WJ",
//     network: Network.ETH_MAINNET,
//   };
//   const alchemy = new Alchemy(config);

//   try {
//     const result: AxiosResponse = yield axios.get(`https://api.guild.xyz/v1/guild/${guild.guildId}`);

//     const setRoles = result.data.roles.filter((role: any) => guild.roleIds.some((roleId: any) => roleId === role.id));

//     yield* put(profileActions.updateProfileGuilds([{ ...result.data, setRoles }]));
//   } catch (error2) {
//     console.error('Failed to fetch guild data', error2);
//     throw error2;
//   }
// }

// export function* getProfileGuildsSaga({ type }: ReturnType<typeof profileActions.getProfileGuilds>) {
//   yield put(request(type));

//   const { address, guilds } = yield select(profileModel.selectors.getProfile);

//   try {
//     const response: AxiosResponse = yield call(fetchProfileGuilds, address);

//     yield all(
//       response.data.map((guild: any) => {
//         return call(fetchGuildData, guild);
//       })
//     )

//     yield put(success(type));
//   } catch (err) {
//     yield put(error(type, err));
//     console.error('Failed to get profile guilds', err);
//   }
// }

// export default function* listener() {
//   yield takeLatest(profileActions.getProfileGuilds.type, getProfileGuildsSaga);
// }

export function* getProfileTokensSaga({ type }: ReturnType<typeof profileActions.getProfileTokens>) {
  yield* put(request(type));

  const { address }: Pick<ProfileState, 'address'> = yield select(profileModel.selectors.getProfile);

  try {
    const tokens: any[] = yield fetchProfileTokens(address);

    yield* put(
      profileActions.updateProfileAssets({
        tokens,
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getProfileTokens', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileTokens.type, getProfileTokensSaga);
}
