import axios, { AxiosResponse } from 'axios';
import { profileActions } from 'entities/profile/model';
import { getToastMessage } from 'shared';

export const gitcoinSupply = async (web3Provider: any, address: string, dispatch: any) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: 'https://api.scorer.gitcoin.co/registry/signing-message',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'Jtqysgdo.I7yu9LfaQTklHrvuak9Q8CQUAlrHMkLs',
        Accept: 'application/json',
      },
    });

    const signature = await web3Provider.eth.personal.sign(data.message, address, '');
    console.log(signature, 'signature');

    console.log(data, 'gitcoinSign');

    const { data: data2 } = await axios({
      method: 'post',
      url: 'https://api.scorer.gitcoin.co/registry/submit-passport',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'Jtqysgdo.I7yu9LfaQTklHrvuak9Q8CQUAlrHMkLs',
      },
      data: {
        address,
        community: '97',
        signature,
        nonce: data.nonce,
      },
    });

    console.log(data2, 'gitcoinScore');

    dispatch(profileActions.getProfileGitcoinScore());
  } catch {
    getToastMessage('error', 'Something went wrong');
  }
};
