export const formatNumber = (number: number | string) => new Intl.NumberFormat().format(+number);

export const formatCurrency = (amount: number | string, locale = 'en-US', currency = 'usd'): string =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(+amount);

export const kFormatter = (number: number) => {
  return Math.abs(number) > 999
    ? `${parseFloat((Math.sign(number) * (Math.abs(number) / 1000)).toFixed(2))} k`
    : Math.sign(number) * Math.abs(number);
};

export const compactNumberFormatter = (amount: number | string) =>
  Intl.NumberFormat('en', {
    notation: 'compact',
    maximumFractionDigits: 2,
  }).format(Number(amount));
