import { userModel } from 'entities/user';
import { userActions, UserState } from 'entities/user/model';
import { toSvg } from 'jdenticon';
import { ContractsNames, FactoryAbi, getContractDataByItsName, getToastMessage, logger } from 'shared';
import { error, getIpfsLink, getMaxGas, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { addBlockSaga } from './addBlock';
import { getProfileContractAddressSaga } from './getProfileContractAddress';

export function* createProfileSaga({
  type,
  payload: { web3Provider, name, withDomain, tokenId = 0, avatar, banner, bio, attributes },
}: ReturnType<typeof userActions.createProfile>) {
  yield* put(request(type));
  const { chainType, address: userAddress }: Pick<UserState, 'chainType' | 'address'> = yield select(
    userModel.selectors.getUser,
  );
  const [factoryAbi, factoryContractAddress] = getContractDataByItsName(ContractsNames.Factory, chainType);

  let profileTransactionHash = '';
  let defaultAvatar = '';
  try {
    const factoryContract: FactoryAbi = yield new web3Provider.eth.Contract(factoryAbi, factoryContractAddress);
    const stylesArr = yield* call(factoryContract.methods.getStyles().call);

    if (!avatar) {
      const svgString = toSvg(userAddress, 100);
      const blob = new Blob([svgString], { type: 'image/svg+xml' });
      const formData = new FormData();
      formData.append('file', blob);
      const { ipfsLink } = yield* call(getIpfsLink, formData);
      defaultAvatar = ipfsLink;
    }

    const metadata: [string, string, string] = [
      name,
      avatar || defaultAvatar,
      attributes ? JSON.stringify(attributes) : '{}',
    ];

    const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
    const { transactionHash } = yield* call(
      factoryContract.methods.createProfile(stylesArr[0], metadata, withDomain, tokenId).send,
      {
        from: userAddress,
        to: factoryContractAddress,
        maxFeePerGas,
        maxPriorityFeePerGas,
      },
    );
    profileTransactionHash = transactionHash;
    yield* call(getProfileContractAddressSaga, {
      type: userActions.getProfileContractAddress.type,
      payload: undefined,
    });

    if (bio || banner) {
      yield* call(addBlockSaga, {
        type: userActions.addBlock.type,
        payload: {
          web3Provider,
          blocksArray: [
            {
              blockType: 'Bio',
              blockPayload: { contents: bio },
              blockDescription: ' ',
            },
            {
              blockType: 'Banner',
              blockPayload: { contents: banner },
              blockDescription: ' ',
            },
          ],
        },
      });
    }

    yield* put(
      userModel.userActions.updateUserState({
        name,
        avatar: avatar || defaultAvatar,
        bio,
        banner,
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('createProfile', err);

    if (profileTransactionHash) {
      getToastMessage('success', 'Profile sucessfully created');
      getToastMessage('error', 'Bio and banner not added');

      yield* put(
        userModel.userActions.updateUserState({
          name,
          avatar: avatar || defaultAvatar,
        }),
      );
    } else {
      getToastMessage('error', 'Profile not created');
    }
  }
}

export default function* listener() {
  yield takeLatest(userActions.createProfile.type, createProfileSaga);
}
