import { domainModel } from 'entities/domain';
import { userModel } from 'entities/user';
import { ContractsNames, createContract, DomainAbi, getContractDataByItsName, getToastMessage, logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { domainActions } from '../reducer';

export function* getDomainInfoSaga({ type, payload: { name } }: ReturnType<typeof domainActions.getDomainInfo>) {
  yield* put(request(type));
  const { chainType } = yield select(userModel.selectors.selectUserWeb3Info);
  const [domainAbi, domainContractAddress] = getContractDataByItsName(ContractsNames.Domain, chainType);
  try {
    const domainContract: DomainAbi = yield createContract(domainAbi, domainContractAddress, chainType);
    const domainInfo = yield* call(domainContract.methods.getDomainInfo(name).call);

    yield* put(
      domainModel.domainActions.updateDomainState({
        name,
        ...domainInfo,
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    getToastMessage('error', 'Something went wrong');
    logger('getDomainInfo', err);
  }
}

export default function* listener() {
  yield takeLatest(domainActions.getDomainInfo.type, getDomainInfoSaga);
}
