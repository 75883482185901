import { domainModel } from 'entities/domain';
import { modalModel } from 'entities/modal';
import { profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import { uiReducer } from 'shared/config/ui/reducer';

export default {
  ui: uiReducer,
  modal: modalModel.reducer,
  user: userModel.reducer,
  domain: domainModel.reducer,
  profile: profileModel.reducer,
};
