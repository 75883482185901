import axios, { AxiosResponse } from 'axios';
import { profileModel } from 'entities/profile';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';

import { profileActions } from '../reducer';

export function* getProfileNomisScore({ type }: ReturnType<typeof profileActions.getProfileNomisScore>) {
  yield put(request(type));

  const { address } = yield select(profileModel.selectors.getProfile);

  try {
    const resultEth: AxiosResponse = yield axios.get(
      `https://api.nomis.cc/api/v1/ethereum/wallet/${address}/score?scoreType=0&nonce=0`,
    );
    const resultPolygon: AxiosResponse = yield axios.get(
      `https://api.nomis.cc/api/v1/polygon/wallet/${address}/score?scoreType=0&nonce=0`,
    );

    yield* put(
      profileActions.updateProfileScores({
        nomis: {
          polygon: resultPolygon.data.data.score ? resultPolygon.data.data.score * 100 : -2,
          ethereum: resultEth.data.data.score ? resultEth.data.data.score * 100 : -2,
        },
      }),
    );

    yield put(success(type));
  } catch (err) {
    yield put(error(type, err));
    console.error('Failed to get profile nomis score', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileNomisScore.type, getProfileNomisScore);
}
