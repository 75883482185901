import { createSelector } from '@reduxjs/toolkit';
import { State } from 'app/store';

const getDomain = (state: State) => state.domain;

const selectDomainInfo = createSelector(getDomain, (data) => ({
  ...data,
}));

export const selectors = {
  selectDomainInfo,
};
