import { useFieldArray, useForm } from 'react-hook-form';
import { permanentSocialFieldsData } from 'features';
import { Socials } from 'shared';

type LinkField = {
  name: Socials;
  label: string;
  value: string;
  linkUrl: string;
  placeholder: string;
};
export type LinksFormFields = { links: LinkField[] };

const initialValues: LinkField[] = permanentSocialFieldsData.map(({ name, label, link, placeholder }) => ({
  name,
  value: '',
  label,
  placeholder,
  linkUrl: link,
}));

export const useValidateForm = () => {
  const { register, control, handleSubmit, getValues } = useForm<LinksFormFields>({
    defaultValues: {
      links: initialValues,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'links',
  });

  return { register, control, fields, getValues, append, remove, handleSubmit };
};
