import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { userModel } from 'entities/user';
import { userActions } from 'entities/user/model';
import {
  amplitudeInstance,
  checkRequestStatusEquality,
  FontFamilies,
  routes,
  uiSelector,
  useShallowSelector,
} from 'shared';
import { Layout } from 'widgets';

export const NoProfile = () => {
  const navigate = useNavigate();
  const uiState = useShallowSelector(uiSelector);
  const {
    name,
    address,
    links,
    domain: { domainName },
  } = useShallowSelector(userModel.selectors.getUser);

  const isGetUserLoading = checkRequestStatusEquality(uiState[userActions.getUserInfo?.type]);

  const handleButtonClick = () => {
    amplitudeInstance.track('welcomescreen_viewed');
    if (links.length) {
      navigate(routes.profile.getPathname(domainName || address));
    } else if (name) {
      navigate(routes.socialLinks.path);
    } else if (domainName) {
      navigate(routes.createProfile.path);
    } else {
      navigate(routes.domain.getPathname('initial'));
    }
  };

  return (
    <Layout>
      <Typography
        variant="h1"
        textAlign="center"
        className="secondary"
        mt={{ xs: 7, md: 10 }}
        mb={9}
        sx={{ textTransform: 'uppercase' }}
      >
        This profile does not exist
      </Typography>
      <Stack spacing={4.125} mb={{ xs: 10, md: 10, xl: 15 }}>
        <Typography>
          <Typography
            textAlign="center"
            sx={{ textTransform: 'uppercase', fontSize: { xs: 40, sm: 48 } }}
            color="#000"
            lineHeight="45px"
          >
            <span style={{ fontFamily: FontFamilies.quaternary, fontStyle: 'italic' }}>&#123;R&#125;</span>
            <span style={{ fontFamily: FontFamilies.secondary }}>elin</span>
            <span style={{ fontFamily: FontFamilies.primary }}>kd your</span>
          </Typography>

          <div className="marker-word" style={{ position: 'relative' }}>
            <Typography
              textAlign="center"
              sx={{ textTransform: 'uppercase', zIndex: 2, position: 'relative', fontSize: { xs: 40, sm: 48 } }}
              color="#000"
              lineHeight="45px"
            >
              di<span style={{ fontFamily: FontFamilies.secondary }}>gital </span>
              <span>ident</span>
              <span style={{ fontFamily: FontFamilies.secondary, fontStyle: 'italic' }}>i</span>
              <span style={{ fontFamily: FontFamilies.quaternary, fontStyle: 'italic' }}>ty</span>
            </Typography>
            <Stack
              className="marker"
              style={{
                position: 'absolute',
                bottom: 0,
                backgroundColor: '#ECFF9E',
                height: '70%',
                zIndex: 1,
              }}
              sx={{ width: { sm: '32%', xs: '47%' }, right: { sm: '18%', xs: '5%' } }}
            />
          </div>

          <Typography
            textAlign="center"
            sx={{ textTransform: 'uppercase', fontSize: { xs: 40, sm: 48 } }}
            color="#000"
            lineHeight="45px"
          >
            <span style={{ fontFamily: FontFamilies.secondary, fontStyle: 'italic' }}>t</span>
            <span style={{ fontFamily: FontFamilies.quaternary, fontStyle: 'italic' }}>o</span>
            <span style={{ fontFamily: FontFamilies.primary }}>web</span>
            <span style={{ fontFamily: FontFamilies.quaternary, fontStyle: 'italic' }}>3</span>
          </Typography>
        </Typography>
      </Stack>
      <Button variant="contained" fullWidth onClick={handleButtonClick} disabled={isGetUserLoading}>
        Get Profile
      </Button>
    </Layout>
  );
};
