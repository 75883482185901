import { alpha } from '@mui/material';

export const COLOR_WH = '#FFFFFF';
export const COLOR_BLACK = '#000000';
export const COLOR_ACCENT = '#7D85FF';
export const COLOR_ACCENT_DARK = '#6369CB';
export const COLOR_ACCENT_ALPHA = alpha('#7D85FF', 0.06);
export const COLOR_TEXT_GRAY = '#7F8196';
export const COLOR_LIGHT_GRAY = '#E3E4ED';
export const COLOR_BUTTON_DISABLED = '#C7C7C7';
export const COLOR_BUTTON_SECONDARY = '#D9DCE5';
export const COLOR_TEXTFIELD_LIGHT = '#F5F6FA';
export const COLOR_GRAY_HOVER = '#f0f0fa';
export const COLOR_GREEN = '#6BDFC3';
export const COLOR_GREEN_ALPHA = alpha('#6BDFC3', 0.2);
export const COLOR_RED = '#DF6B6B';
export const COLOR_RED_ALPHA = alpha('#DF6B6B', 0.2);

export const COLOR_PURPURE = '#604290';
export const COLOR_STROKE = '#5C5C5C';
export const COLOR_CARD = '#d9d9d91a';
export const COLOR_DARK = '#1D1D1D';
export const COLOR_WH_10 = 'rgba(255, 255, 255, 0.1)';
