import { profileModel } from 'entities/profile';
import { fetchProfileNFTs } from 'entities/profile/api';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { profileActions } from '../reducer';
import { ProfileGitcoinState, ProfileState } from '..';

export function* getProfileNFTsSaga({ type }: ReturnType<typeof profileActions.getProfileGitcoin>) {
  yield* put(request(type));

  const { address }: Pick<ProfileState, 'address'> = yield select(profileModel.selectors.getProfile);

  try {
    const nfts: any[] = yield fetchProfileNFTs(address);
    console.log(nfts);

    yield* put(profileActions.updateProfileAssets({ nfts }));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getProfileNFTs', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileNFTs.type, getProfileNFTsSaga);
}
