import axios, { AxiosResponse } from 'axios';
import { profileModel } from 'entities/profile';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';

import { profileActions } from '../reducer';

export function* getProfileCredScore({ type }: ReturnType<typeof profileActions.getProfileCredScore>) {
  yield put(request(type));

  const { address } = yield select(profileModel.selectors.getProfile);

  try {
    const result: AxiosResponse = yield axios({
      method: 'get',
      url: `https://beta.credprotocol.com/api/score/address/${address}`,
      headers: {
        Authorization: 'Token d9e4fcea438f1c230ecd497c3ca7d0498b3a0f31',
      },
    });

    yield* put(profileActions.updateProfileScores({ cred: result.data.value ? result.data.value : -2 }));

    yield put(success(type));
  } catch (err) {
    yield put(error(type, err));
    console.error('Failed to get profile nomis score', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileCredScore.type, getProfileCredScore);
}
