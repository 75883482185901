import axios, { AxiosResponse } from 'axios';
import { profileModel } from 'entities/profile';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';

import { profileActions } from '../reducer';

export function* getProfileDegenScore({ type }: ReturnType<typeof profileActions.getProfileDegenScore>) {
  yield put(request(type));

  const { address } = yield select(profileModel.selectors.getProfile);

  try {
    const result: AxiosResponse = yield axios.get(`https://beacon.degenscore.com/v1/beacon/${address}`);
    console.log(result, 'degenScore');

    yield* put(
      profileActions.updateProfileScores({
        degenScore: result.data.traits.degen_score.value ? result.data.traits.degen_score.value : -2,
      }),
    );

    yield put(success(type));
  } catch (err) {
    yield put(error(type, err));
    console.error('Failed to get profile degen score', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileDegenScore.type, getProfileDegenScore);
}
