import axios from 'axios';
import BigNumber from 'bignumber.js';
import { isMainnet, logger } from 'shared';

export const getMaxGas = async (): Promise<{ maxFeePerGas?: string; maxPriorityFeePerGas?: string }> => {
  let maxFeePerGas = new BigNumber(40000000000).toString(10);
  let maxPriorityFeePerGas = new BigNumber(40000000000).toString(10);

  try {
    const { data } = await axios({
      method: 'get',
      url: isMainnet ? 'https://gas-price-api.1inch.io/v1.4/137' : 'https://gasstation-testnet.polygon.technology/v2',
    });
    if (isMainnet) {
      maxFeePerGas = new BigNumber(data.instant.maxFeePerGas).toFixed(0, 1);
      maxPriorityFeePerGas = new BigNumber(data.instant.maxPriorityFeePerGas).toFixed(0, 1);
    } else {
      maxFeePerGas = new BigNumber(data.fast.maxFee).times(10 ** 9).toFixed(0, 1);
      maxPriorityFeePerGas = new BigNumber(data.fast.maxPriorityFee).times(10 ** 9).toFixed(0, 1);
    }
    // const gasPrice = await this.Web3().eth.getGasPrice();
    // const estimateGas = await this.Web3().eth.estimateGas({
    //   ...transactionConfig,
    //   from: walletAddress,
    // });
  } catch (e) {
    logger('getGasPrice', e, 'error');
  }

  return { maxFeePerGas, maxPriorityFeePerGas };
};
