import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { userModel } from 'entities/user';
import { routes, useShallowSelector } from 'shared';
import { Layout } from 'widgets';

export const NoWhitelistedPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isWhitelisted, address } = useShallowSelector(userModel.selectors.getUser);

  useEffect(() => {
    if (isWhitelisted) {
      navigate(routes.agreement.path);
    }
    dispatch(userModel.userActions.getIsUserWhitelisted());
  });

  return (
    <Layout>
      <Typography variant="h1" mt={{ xs: 3, md: 10 }} mb={{ xs: 5, md: 9 }}>
        &#123;r&#125;elinkd is available only to the whitelisted users
      </Typography>
      <Stack spacing={4.125} mb={{ xs: 5, md: 10, xl: 15 }}>
        <Typography>We&apos;re super excited to see you interested!</Typography>
        <Stack>
          <Typography>To try &#123;r&#125;elinkd Beta, please:</Typography>
          <List>
            <ListItem>
              <ListItemText primary="1. Fill out the form" />
            </ListItem>
            <ListItem>
              <ListItemText primary="2. Join our Discord" />
            </ListItem>
            <ListItem>
              <ListItemText primary="3. React to the message" />
            </ListItem>
          </List>
        </Stack>

        <Typography>We will add you to our private early adopters chat and whitelist your wallet address. </Typography>
      </Stack>
      <Button
        variant="contained"
        fullWidth
        data-tf-opacity="100"
        data-tf-size="100"
        data-tf-popup="bCey4yBk"
        data-tf-iframe-props="title=Who are relinkd users"
        data-tf-transitive-search-params
        data-tf-medium="snippet"
      >
        Get Whitelisted
      </Button>
    </Layout>
  );
};
