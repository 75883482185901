import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestWithWeb3Provider } from 'shared';

import {
  ProfileAssetsState,
  ProfileCredentialsState,
  ProfileGitcoinState,
  ProfileGuildsState,
  ProfileLensState,
  ProfileState,
  ProfileZKBadgesState,
} from '../types';

import { BlockData, ProfileContractAddressReq, ProfileInfoReq } from './types';

const initialProfileState: ProfileState = {
  address: '',
  isCurrentUser: false,

  profileContractAddress: '',
  name: '',
  bio: '',
  avatar: '',
  banner: '',
  score: {},
  scores: {
    relinkd: -1,
    degenScore: -1,
    gitcoin: -1,
    nomis: {
      polygon: -1,
      ethereum: -1,
    },
    cred: -1,
  },
  domainName: '',
  links: [],
  ensNames: [],
  credentials: {
    poaps: [],
    zkBadges: [],
  },
  lens: {
    defaultProfile: null,
    posts: [],
    isFollowed: false,
  },
  gitcoinStamps: {
    socials: {},
    tags: [],
  },
  assets: {
    tokens: [],
    nfts: [],
  },
  guilds: [],
};

export type FollowLensProfileReq = {
  profileId: string;
} & RequestWithWeb3Provider;

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialProfileState,
  reducers: {
    updateProfileCredentials: (state: ProfileState, action: PayloadAction<Partial<ProfileCredentialsState>>) => ({
      ...state,
      credentials: { ...state.credentials, ...action.payload },
    }),

    updateProfileLens: (state: ProfileState, action: PayloadAction<Partial<ProfileLensState>>) => ({
      ...state,
      lens: { ...state.lens, ...action.payload },
    }),

    updateProfileGitcoin: (state: ProfileState, action: PayloadAction<Partial<ProfileGitcoinState>>) => ({
      ...state,
      gitcoinStamps: { ...state.gitcoinStamps, ...action.payload },
    }),

    updateProfileScores: (state: ProfileState, action: PayloadAction<Partial<any>>) => ({
      ...state,
      scores: { ...state.scores, ...action.payload },
    }),

    updateProfileZKBadges: (state: ProfileState, action: PayloadAction<Partial<ProfileZKBadgesState>>) => ({
      ...state,
      credentials: { ...state.credentials, ...action.payload },
    }),

    updateProfileGuilds: (state: ProfileState, action: PayloadAction<Partial<ProfileGuildsState>>) => ({
      ...state,
      guilds: [...state.guilds, ...action.payload],
    }),

    updateProfileState: (state: ProfileState, action: PayloadAction<Partial<ProfileState>>) => ({
      ...state,
      ...action.payload,
    }),

    updateProfileAssets: (state: ProfileState, action: PayloadAction<Partial<ProfileAssetsState>>) => ({
      ...state,
      assets: { ...state.assets, ...action.payload },
    }),

    clearProfileState: () => ({
      ...initialProfileState,
    }),

    /** For saga */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    /* eslint-disable @typescript-eslint/no-empty-function */
    getProfileInfo(state, action: PayloadAction<ProfileInfoReq>) {},
    getProfileContractAddress(state, action: PayloadAction<ProfileContractAddressReq>) {},
    getEnsNames(state, action: PayloadAction) {},
    getProfilePoaps(state, action: PayloadAction) {},
    getProfileLens(state, action: PayloadAction) {},
    getProfileGitcoin(state, action: PayloadAction) {},
    getProfileZKBadges(state, action: PayloadAction) {},
    getProfileGuilds(state, action: PayloadAction) {},
    getProfileNFTs(state, action: PayloadAction) {},
    getProfileTokens(state, action: PayloadAction) {},
    getProfileLensPosts(state, action: PayloadAction) {},
    getIsProfileFollowed(state, action: PayloadAction) {},
    followProfileLens(state, action: PayloadAction<FollowLensProfileReq>) {},
    getProfileDegenScore(state, action: PayloadAction) {},
    getProfileGitcoinScore(state, action: PayloadAction) {},
    getProfileNomisScore(state, action: PayloadAction) {},
    getProfileCredScore(state, action: PayloadAction) {},
    getProfileScore(state, action: PayloadAction) {},
    updateProfileScore(state, action: PayloadAction) {},
    updateProfileToken(state, action: PayloadAction) {},
  },
});

export const { reducer } = profileSlice;
export const { actions: profileActions } = profileSlice;
