import { userModel } from 'entities/user';
import { ContractsNames, getContractDataByItsName, getToastMessage, ScoreAbi } from 'shared';
import { error, getMaxGas, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { userActions, UserState } from '../reducer';

export function* mintScore({ type, payload: { web3Provider } }: ReturnType<typeof userActions.mintScore>) {
  yield* put(request(type));

  const { chainType, address }: Pick<UserState, 'chainType' | 'address'> = yield select(userModel.selectors.getUser);

  try {
    const [scoreAbi, scoreContractAddress] = getContractDataByItsName(ContractsNames.Score, chainType);

    const scoreContract: ScoreAbi = yield new web3Provider.eth.Contract(scoreAbi, scoreContractAddress);

    const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
    const { transactionHash } = yield* call(scoreContract.methods.mint(address).send, {
      from: address,
      to: scoreContractAddress,
      maxFeePerGas,
      maxPriorityFeePerGas,
    });

    getToastMessage('success', 'Your token succesfully minted!');
    yield* put(success(type));
  } catch (err) {
    getToastMessage('error', 'Something went wrong');
    yield* put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(userActions.mintScore.type, mintScore);
}
