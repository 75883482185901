import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { profileModel } from 'entities/profile';
import { useShallowSelector } from 'shared';

export const ProfileScore = () => {
  const { score } = useShallowSelector(profileModel.selectors.getProfile);

  return <Box>{score?.score?.toFixed(2)}</Box>;
};
