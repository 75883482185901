import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Badge } from 'entities/badge';
import { Domain } from 'entities/domain';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { profileModel, verifyGitcoin } from 'entities/profile';
import { gitcoinProvidersToTags } from 'entities/profile/config';
import {
  amplitudeInstance,
  BORDER_RADIUS_M,
  BORDER_RADIUS_S,
  BORDER_S,
  COLOR_BUTTON_SECONDARY,
  Icon,
  useShallowSelector,
} from 'shared';
import { EditProfileForm } from 'widgets/edit-profile-form';

import { userDescriptionMock } from '../config';

const Card = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  padding: theme.spacing(0, 1.5),

  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
    background: theme.themeColors.colorBackground,
    borderRadius: BORDER_RADIUS_M,
    border: BORDER_S,
    borderColor: theme.themeColors.colorBorder,
    padding: theme.spacing(2.5),
  },
}));

const SystemBadge = styled(Box)(({ theme }) => ({
  '>*': {
    fontWeight: '600!important',
    '&:hover': {
      backgroundColor: '#b0ebc0',
    },
  },
}));

const ScrollingBox = styled(Box)(({ theme }) => ({
  overflowY: 'hidden',
  overflowX: 'scroll',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    height: 4,
    display: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    height: 1,
    background: 'transparent',
  },
}));

export const ProfileDescription = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { name, bio, ensNames, domainName, isCurrentUser, gitcoinStamps, address } = useShallowSelector(
    profileModel.selectors.getProfile,
  );
  const { lens } = useShallowSelector(profileModel.selectors.selectProfileLens);
  const lensLength = +!!lens.defaultProfile;

  const badges = gitcoinStamps.tags;

  const countDomains = (domainName ? ensNames.length + 1 : ensNames.length) + lensLength;
  const halfCountDomains = countDomains <= 6 ? 3 : Math.ceil(countDomains / 2);
  const halfCountEns = domainName ? halfCountDomains - 1 : halfCountDomains;
  const firstHalfEns = ensNames.slice(0, halfCountEns);
  const secondHalfEns = ensNames.slice(halfCountEns);
  const halfCountBadges = badges.length <= 6 ? 3 : Math.ceil(badges.length / 2);
  const firstHalfBadges = badges.slice(0, halfCountBadges);
  const secondHalfBadges = badges.slice(halfCountBadges);

  const handleOpenEditModal = () => {
    amplitudeInstance.track('profile_edit_start');
    dispatch(
      modalModel.modalActions.openModal({
        type: Modals.BaseModal,
        data: {
          title: 'Edit',
          children: <EditProfileForm />,
        },
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(modalModel.modalActions.closeModal());
    };
  }, [dispatch]);

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h1">{name}</Typography>
        {isCurrentUser ? (
          <IconButton onClick={handleOpenEditModal}>
            <Icon type="edit" />
          </IconButton>
        ) : // <IconButton onClick={() => {}}>
        //   <Icon type="mail" size={30} />
        // </IconButton>
        null}
      </Stack>

      {isDownMd
        ? (domainName || !!ensNames.length) && (
            <ScrollingBox>
              <Stack gap={2} direction="row">
                {address !== '0x2a81C13F9366395c8FD1EA24912294230d062Db3' &&
                  firstHalfEns.map(
                    (ensName) => ensName && <Domain key={ensName} name={ensName} isEns address={address} />,
                  )}
                {lens.defaultProfile && (
                  <Domain
                    key={lens.defaultProfile.handle}
                    isLens
                    address={lens.defaultProfile.handle}
                    name={lens.defaultProfile.handle}
                  />
                )}
                {domainName && <Domain key={domainName} name={domainName} isR />}
              </Stack>
              {address !== '0x2a81C13F9366395c8FD1EA24912294230d062Db3' && !!secondHalfEns.length && (
                <Stack gap={2} direction="row" mt={2}>
                  {secondHalfEns.map(
                    (ensName) => ensName && <Domain key={ensName} name={ensName} isEns address={address} />,
                  )}
                </Stack>
              )}
            </ScrollingBox>
          )
        : (domainName || !!ensNames.length) && (
            <Stack direction="row" flexWrap="wrap" gap={2}>
              {address !== '0x2a81C13F9366395c8FD1EA24912294230d062Db3' &&
                ensNames.map((ensName) => ensName && <Domain key={ensName} name={ensName} isEns address={address} />)}
              {lens.defaultProfile && (
                <Domain
                  key={lens.defaultProfile.handle}
                  isLens
                  address={lens.defaultProfile.handle}
                  name={lens.defaultProfile.handle}
                />
              )}
              {domainName && <Domain key={domainName} name={domainName} isR />}
            </Stack>
          )}

      {bio && (
        <Typography maxWidth={550} className="small" overflow="hidden" textOverflow="ellipsis" whiteSpace="pre-wrap">
          {bio}
        </Typography>
      )}

      {isDownMd
        ? !!badges.length && (
            <ScrollingBox>
              <Stack gap={2} direction="row">
                {firstHalfBadges.map((badgeData) => (
                  <Badge key={badgeData.text} {...badgeData} />
                ))}
              </Stack>
              <Stack gap={2} direction="row" mt={1.75}>
                {secondHalfBadges.map((badgeData) => (
                  <Badge key={badgeData.text} {...badgeData} />
                ))}
                <Badge {...gitcoinProvidersToTags.Polygon} />
                <Badge {...gitcoinProvidersToTags.Relinkd} />
                {isCurrentUser && (
                  <SystemBadge
                    onClick={() => {
                      amplitudeInstance.track('verify_tags');
                      verifyGitcoin();
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <Badge icon="gtc" text="Get Stamps" background="#e8ffee" color="#1a1a1a" />
                  </SystemBadge>
                )}
              </Stack>
            </ScrollingBox>
          )
        : !!badges.length && (
            <Stack direction="row" gap={1.5} flexWrap="wrap">
              {badges.map((badgeData) => (
                <Badge key={badgeData.text} {...badgeData} />
              ))}
              <Badge {...gitcoinProvidersToTags.Polygon} />
              <Badge {...gitcoinProvidersToTags.Relinkd} /> {/* TODO default badges to config */}
              {isCurrentUser && (
                <SystemBadge
                  onClick={() => {
                    amplitudeInstance.track('verify_tags');
                    verifyGitcoin();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Badge icon="gtc" text="Get Stamps" background="#e8ffee" color="#1a1a1a" />
                </SystemBadge>
              )}
            </Stack>
          )}
      {badges.length === 0 && isCurrentUser && (
        <Stack direction="row" gap={1.5} flexWrap="wrap">
          <Badge {...gitcoinProvidersToTags.Polygon} />
          <Badge {...gitcoinProvidersToTags.Relinkd} />
          <SystemBadge
            onClick={() => {
              amplitudeInstance.track('verify_tags');
              verifyGitcoin();
            }}
            style={{ cursor: 'pointer' }}
          >
            <Badge icon="gtc" text="Get Stamps" background="#e8ffee" color="#1a1a1a" />
          </SystemBadge>
        </Stack>
      )}
      {badges.length === 0 && !isCurrentUser && (
        <Stack direction="row" gap={1.5} flexWrap="wrap">
          <Badge {...gitcoinProvidersToTags.Polygon} />
          <Badge {...gitcoinProvidersToTags.Relinkd} />
        </Stack>
      )}
    </Card>
  );
};
