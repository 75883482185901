import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export type DomainFormFields = {
  domain: string;
};
export const initialState: DomainFormFields = {
  domain: '',
};

const domainRegex = /^[a-zA-Z0-9._]*$/;

export const validationSchema = Yup.object({
  domain: Yup.string()
    .max(31, 'Maximum is 31 symbols')
    .min(3, 'Minimum is 3 symbols')
    .nullable()
    .transform((value) => value || null)
    .matches(domainRegex, 'Only Latin letters, numbers, . and _ symbols are allowed'),
});

export const useValidateForm = (initialValues: DomainFormFields) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    formState: { isValid },
  } = useForm<DomainFormFields>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  return { register, watch, handleSubmit, errors, isValid };
};
