import { ComponentsOverrides, Theme } from '@mui/material';

import { BORDER_RADIUS_L } from '../common';

export const getMuiSwitchOverrides = (theme: Theme): ComponentsOverrides['MuiSwitch'] => ({
  root: {
    width: 44,
    height: 24,
    padding: 0,

    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.themeColors.colorSwitchActive,
          opacity: 1,
          border: 0,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.themeColors.colorSwitchThumb,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      backgroundColor: theme.themeColors.colorSwitchThumb,
      width: 20,
      height: 20,
    },
    '& .MuiSwitch-track': {
      borderRadius: BORDER_RADIUS_L,
      backgroundColor: theme.themeColors.colorSwitch,
      opacity: 1,
    },
  },
});
