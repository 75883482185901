import { userModel } from 'entities/user';
import { ContractsNames, createContract, FactoryAbi, getContractDataByItsName, logger, useWeb3Provider } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { userActions } from '../reducer';

export function* getIsUserWhitelistedSaga({ type }: ReturnType<typeof userActions.getIsUserWhitelisted>) {
  yield* put(request(type));
  const { chainType, address: userAddress } = yield select(userModel.selectors.selectUserWeb3Info);

  const [factoryAbi, factoryContractAddress] = getContractDataByItsName(ContractsNames.Factory, chainType);

  try {
    const factoryContract: FactoryAbi = yield createContract(factoryAbi, factoryContractAddress, chainType);
    console.log(factoryContract, 'Contract');

    const isUserWhitelisted = yield* call(factoryContract.methods.domainWhitelist(userAddress).call);

    yield* put(
      userActions.updateUserState({
        isWhitelisted: isUserWhitelisted,
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getIsUserWhitelisted', err);
  }
}

export default function* listener() {
  yield takeLatest(userActions.getIsUserWhitelisted.type, getIsUserWhitelistedSaga);
}
