import { profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import { userActions, UserState } from 'entities/user/model';
import { ContractsNames, getContractDataByItsName, getToastMessage, logger, ProfileAbi } from 'shared';
import { amplitudeInstance, error, getIpfsLink, getMaxGas, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

export function* changeAvatarSaga({
  type,
  payload: { web3Provider, fileImg },
}: ReturnType<typeof userActions.changeAvatar>) {
  yield* put(request(type));

  const {
    chainType,
    address: userAddress,
    profileContractAddress,
  }: Pick<UserState, 'chainType' | 'address' | 'profileContractAddress'> = yield select(userModel.selectors.getUser);

  const [profileAbi] = getContractDataByItsName(ContractsNames.Profile, chainType);

  const formData = new FormData();
  formData.append('file', fileImg);

  try {
    const profileContract: ProfileAbi = yield new web3Provider.eth.Contract(profileAbi, profileContractAddress);

    const { ipfsLink } = yield* call(getIpfsLink, formData);
    const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
    const { transactionHash } = yield* call(profileContract.methods.changeImage(ipfsLink).send, {
      from: userAddress,
      to: profileContractAddress,
      maxFeePerGas,
      maxPriorityFeePerGas,
    });

    amplitudeInstance.track('profile_edit_saved', { avatar_photo_source: ipfsLink });

    yield* put(
      userModel.userActions.updateUserState({
        avatar: ipfsLink,
      }),
    );
    yield* put(
      profileModel.profileActions.updateProfileState({
        avatar: ipfsLink,
      }),
    );

    getToastMessage('success', 'Avatar successfully changed');
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('changeAvatar', err);
    getToastMessage('error', 'Avatar has not changed');
  }
}

export default function* listener() {
  yield takeLatest(userActions.changeAvatar.type, changeAvatarSaga);
}
