import { Link, Stack, styled, Tooltip, Typography } from '@mui/material';
import { BORDER_RADIUS_S, FontFamilies, shortenPhrase } from 'shared';

const DomainWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '30px',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.themeColors.textFieldBackground,
  borderRadius: BORDER_RADIUS_S,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  fontFamily: FontFamilies.secondary,
  textDecoration: 'none',
  color: theme.themeColors.colorTypographyPrimary,
})) as typeof Typography;

export type DomainProps = {
  name: string;
  isEns?: boolean;
  address?: string;
  isLens?: boolean;
  isR?: boolean;
};

export const Domain = ({ name, isEns, isLens, isR, address = '' }: DomainProps) => {
  const domainName = isEns || isLens ? name : `{r}${name}`;
  let domainLink;
  let domainContent = domainName;
  if (isEns) {
    domainLink = `https://etherscan.io/address/${address}`;
    domainContent = shortenPhrase(name, 10, 3);
  } else if (isLens) {
    domainLink = `https://lenster.xyz/u/${address}`;
    domainContent = shortenPhrase(name, 10, 4);
  } else if (isR) {
    domainLink = 'https://opensea.io/collection/relinkd-names'; // TODO To config
  } else domainContent = `{r}${shortenPhrase(name, 7, 0)}`;

  return domainName.length > 10 ? (
    <DomainWrapper>
      <Tooltip title={name}>
        <StyledTypography
          component={isEns || isLens || isR ? Link : 'p'}
          href={domainLink}
          target="_blank"
          rel="noreferrer"
        >
          {domainContent}
        </StyledTypography>
      </Tooltip>
    </DomainWrapper>
  ) : (
    <DomainWrapper>
      <StyledTypography
        component={isEns || isLens || isR ? Link : 'p'}
        href={domainLink}
        target="_blank"
        rel="noreferrer"
      >
        {domainContent}
      </StyledTypography>
    </DomainWrapper>
  );
};
