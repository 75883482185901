import { profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import { userActions, UserState } from 'entities/user/model';
import { ContractsNames, getContractDataByItsName, logger, ProfileAbi } from 'shared';
import { error, getMaxGas, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

export function* changeNameSaga({ type, payload: { web3Provider, name } }: ReturnType<typeof userActions.changeName>) {
  yield* put(request(type));

  const {
    chainType,
    address: userAddress,
    profileContractAddress,
  }: Pick<UserState, 'chainType' | 'address' | 'profileContractAddress'> = yield select(userModel.selectors.getUser);

  const [profileAbi] = getContractDataByItsName(ContractsNames.Profile, chainType);

  try {
    const profileContract: ProfileAbi = yield new web3Provider.eth.Contract(profileAbi, profileContractAddress);

    const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
    const { transactionHash } = yield* call(profileContract.methods.changeName(name).send, {
      from: userAddress,
      to: profileContractAddress,
      maxFeePerGas,
      maxPriorityFeePerGas,
    });

    yield* put(
      userModel.userActions.updateUserState({
        name,
      }),
    );
    yield* put(
      profileModel.profileActions.updateProfileState({
        name,
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('changeName', err);
    throw new Error('Name has not changed');
  }
}

export default function* listener() {
  yield takeLatest(userActions.changeName.type, changeNameSaga);
}
