import { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Modal, modalModel } from 'entities/modal';
import { profileModel } from 'entities/profile';
import { COLOR_ACCENT, Icon, useShallowSelector } from 'shared';

import { ModalWrapper } from '../ModalWrapper';

export interface GuildModalPayload {
  type: modalModel.Modals.GuildModal;
  data: {
    guildId: number;
  };
}

export const GuildModal = forwardRef<HTMLElement, Modal>(({ data: { guildId } }) => {
  const { guilds }: any = useShallowSelector(profileModel.selectors.getProfile);

  const selectedGuild = guilds[guildId];

  return (
    <ModalWrapper title="Guild Details" size="md">
      <Stack mb={6} mt={{ xs: 9, md: 2 }}>
        <Stack spacing={3.75}>
          <Stack direction="row" justifyContent="center" width="100%">
            <Box component="img" width="50%" src={selectedGuild.imageUrl} />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{ svg: { path: { stroke: COLOR_ACCENT } } }}
          >
            <Icon type="smile" size={16} />
            <Typography className="medium color-accent">
              {selectedGuild.memberCount.toLocaleString('en-US').replace(/,/g, '.')} members
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Typography variant="h3" align="center">
              User Roles:&#160;
            </Typography>
            {selectedGuild.setRoles.map((role: any, index: number, roles: any[]) => {
              const roleText = roles.length - 1 === index ? role.name : `${role.name},`;
              return <Typography key={role.id}>{roleText}&#160;</Typography>;
            })}
          </Stack>
        </Stack>
        <Stack spacing={3.75} mt={14} height="100%">
          <Stack direction="row" justifyContent="center">
            <Typography variant="h2" align="center">
              {selectedGuild.name}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Typography align="center">{selectedGuild.description}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </ModalWrapper>
  );
});

GuildModal.displayName = 'GuildModal';
