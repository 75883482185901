import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, Hidden, Stack, styled, Typography, useTheme } from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { profileModel } from 'entities/profile';
import { profileActions } from 'entities/profile/model';
import { userModel } from 'entities/user';
import { ProfileQR } from 'features/profile-qr';
import { ProfileScore } from 'features/profile-score';
import { ProfileShare } from 'features/profile-share';
import {
  BORDER_RADIUS_M,
  BORDER_RADIUS_S,
  BORDER_RADIUS_XXS,
  BORDER_S,
  flexHelper,
  Icon,
  levelsImages,
  levelTitles,
  UploadImageComponent,
  useShallowSelector,
} from 'shared';
import { AccountBlock, AccountMenu } from 'widgets/layout/ui/header/components'; // TODO To share

import { Circles } from '../assets';

// TODO optimize component

type UploadProfileImagesProps = {
  withEdit: boolean;
  withCryptoNative?: boolean;
  isAvatarLoading?: boolean;
  isBannerLoading?: boolean;
  onUploadBanner: (file: File) => void;
  onUploadAvatar: (file: File) => void;
  variant?: 'primary' | 'secondary' | 'disabled';
};

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&.secondary': {
    padding: theme.spacing(0.5, 0.5, 6),
    border: BORDER_S,
    borderRadius: BORDER_RADIUS_M,
    borderColor: theme.themeColors.colorBorder,
    backgroundColor: theme.themeColors.background,
    [theme.breakpoints.down('md')]: {
      borderColor: 'transparent',
      padding: theme.spacing(0, 0, 2.5),
    },
  },
}));

const ProfileWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '20px',
  bottom: '20px',
  height: '100px',
  borderRadius: BORDER_RADIUS_S,
  border: BORDER_S,
  borderColor: theme.themeColors.colorUploadImageBorder,
  overflow: 'hidden',
  div: {
    backgroundColor: theme.themeColors.colorUploadImageBackground,
  },
  '> svg': { display: 'none', position: 'absolute', left: '38px', top: '38px' },

  '&.secondary': {
    div: {
      backgroundColor: theme.themeColors.colorUploadImageBackgroundSecondary,
    },
    '&:not(.disabled)': {
      '&:hover': {
        div: { filter: 'brightness(50%)' },
        '> svg': { display: 'unset' },
      },
    },
    [theme.breakpoints.down('md')]: {
      left: '12px',
      bottom: 0,
    },
  },
}));

const BannerWrapper = styled(Box)(({ theme }) => ({
  height: 140,
  borderRadius: BORDER_RADIUS_S,
  div: {
    backgroundColor: theme.themeColors.colorUploadImageBackground,
  },
  '> svg': { display: 'none', position: 'absolute', top: '16px', left: '16px' },

  '&.secondary': {
    '&:hover': {
      '&:not(.disabled)': {
        div: { filter: 'brightness(50%)' },
        '> svg': { display: 'unset' },
        img: { filter: 'brightness(50%)' },
      },
    },
    div: {
      backgroundColor: theme.themeColors.colorUploadImageBackgroundSecondary,
      '> svg': { display: 'none' },
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, -0.75),
      '> div': {
        height: 112,
      },
      '& img:first-of-type': {
        height: 112,
      },
      '> svg': { top: '15%', left: '50%' },

      height: 112,
    },
  },
}));

const CryptoNativeBlock = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '140px',
  bottom: '20px',
  ...flexHelper(),
  gap: theme.spacing(1.5),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1.5),
  border: BORDER_S,
  borderRadius: BORDER_RADIUS_S,
  borderColor: theme.themeColors.colorBorder,
  backgroundColor: theme.themeColors.background,
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    left: '134px',
    bottom: 0,
  },
}));

const ProgressBar = styled(Stack)(({ theme }) => ({
  height: 4,
  width: 139,
  borderRadius: BORDER_RADIUS_XXS,
  flexDirection: 'row',
  marginTop: '2px!important',
  marginBottom: '2px!important',
  backgroundColor: theme.themeColors.colorsProgressBarBg,
  '> div': {
    backgroundColor: theme.themeColors.colorsProgressBarFilled,
    height: '100%',
  },
}));

export const UploadProfileImages = ({
  variant = 'primary',
  withEdit,
  withCryptoNative,
  isAvatarLoading,
  isBannerLoading,
  onUploadBanner,
  onUploadAvatar,
}: UploadProfileImagesProps) => {
  const theme = useTheme();
  const {
    avatar,
    banner,
    address: profileAddress,
    score: rScore,
  } = useShallowSelector(profileModel.selectors.getProfile);
  const dispatch = useDispatch();

  // const [score, setScore] = useState(0); // TODO Score to redux

  const { score } = rScore;

  const isLoading = isAvatarLoading || isBannerLoading;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClickAccount = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenScoreModal = () => {
    dispatch(
      modalModel.modalActions.openModal({
        type: Modals.ScoreModal,
        data: null,
      }),
    );
  };

  useEffect(() => {
    dispatch(profileActions.getProfileScore());
  }, [profileAddress]);

  return (
    <Box>
      <Wrapper className={variant}>
        <Hidden smUp>
          {
            /* !!address && isCurrentUser &&  */ <Box
              style={{
                zIndex: '2',
                filter: 'none!important',
                position: 'absolute',
                right: '88px',
                top: '20px',
                backgroundColor: 'rgba(255, 0, 0, 0.0)',
              }}
            >
              {/* <AccountBlock isMenuOpen={isMenuOpen} handleClick={handleClickAccount} /> */}
              <Box
                style={{ backgroundColor: 'rgba(255, 0, 0, 0.0)' }}
                height="24px"
                width="24px"
                onClick={handleClickAccount}
              >
                <Circles width="100%" height="100%" style={{ display: 'block' }} />
              </Box>
              {/* <Typography onClick={handleClickAccount}>click</Typography> */}
              <AccountMenu isMenuOpen={isMenuOpen} anchorEl={anchorEl} handleClose={handleCloseMenu} />
            </Box>
          }
        </Hidden>
        <BannerWrapper className={withEdit && !isLoading ? variant : `${variant} disabled`}>
          {(withEdit || !withEdit || banner) && (
            <UploadImageComponent isBanner disabled={!withEdit || isLoading} image={banner} onUpload={onUploadBanner} />
          )}
          <Icon type="edit" pointerEvents="none" />
          <ProfileQR />
          <ProfileShare />

          {isBannerLoading && (
            <CircularProgress
              size={24}
              sx={{ color: theme.themeColors.background, position: 'absolute', right: '16px', bottom: '48px' }}
            />
          )}
        </BannerWrapper>
        <ProfileWrapper className={withEdit && !isLoading ? variant : `${variant} disabled`}>
          <UploadImageComponent disabled={!withEdit || isLoading} image={avatar} onUpload={onUploadAvatar} />
          <Icon type="edit" pointerEvents="none" />
          {isAvatarLoading && (
            <CircularProgress
              size={24}
              sx={{ color: theme.themeColors.background, position: 'absolute', left: '38px', top: '38px' }}
            />
          )}
        </ProfileWrapper>
        {/* TODO: Remove CryptoNativeBlock to separate file */}
        {withCryptoNative && profileAddress !== '0x2a81C13F9366395c8FD1EA24912294230d062Db3' && (
          <CryptoNativeBlock onClick={handleOpenScoreModal}>
            {/* <Icon type="crypto-native" size={48} /> */}
            <Box
              component="img"
              width="44px"
              padding="4px"
              src={levelsImages[score > 100 ? 4 : Math.floor(score / 20)] || levelsImages[0]}
            />
            <Stack spacing={1.5}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" pr="10px" fontSize={14}>
                  {levelTitles[score > 100 ? 4 : Math.floor(score / 20)] || 'loading...'}
                </Typography>
                <Typography className="small">
                  <ProfileScore />
                </Typography>
              </Stack>
              <ProgressBar>
                {/* percentageValue
                    width=`${value}%` */}
                <Box width={`${score}%`} />
              </ProgressBar>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '0!important' }}>
                <Typography fontSize="9px!important">LVL</Typography>
                <Typography fontSize="9px!important">
                  {score > 100 ? 5 : `${Math.floor(score / 20) + 1 || 0}/${levelTitles.length}`}
                </Typography>
              </Box>
            </Stack>
          </CryptoNativeBlock>
        )}
      </Wrapper>
    </Box>
  );
};
