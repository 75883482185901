import { profileActions } from 'entities/profile/model';
import { getProfileInfoSaga } from 'entities/profile/model/sagas/getProfileInfo';
import { userModel } from 'entities/user';
import { getToastMessage } from 'shared';
import { amplitudeInstance, error, getIpfsLink, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { userActions, UserState } from '../reducer';
import { UpdateBlockData } from '../types';

import { addBlockSaga } from './addBlock';
import { getUserInfoSaga } from './getUserInfo';
import { updateBlocksSaga } from './updateBlocks';

export function* changeBannerSaga({
  type,
  payload: { fileImg, web3Provider },
}: ReturnType<typeof userActions.changeBanner>) {
  yield* put(request(type));

  const {
    domain: { domainName },
    bannerBlockData,
    address,
  }: Pick<UserState, 'domain' | 'bannerBlockData' | 'address'> = yield select(userModel.selectors.getUser);

  const formData = new FormData();
  formData.append('file', fileImg);
  try {
    const { ipfsLink } = yield* call(getIpfsLink, formData);

    if (bannerBlockData) {
      const newBioBlock: UpdateBlockData = {
        blockId: bannerBlockData.blockId,
        blockPayload: { contents: ipfsLink },
        blockDescription: bannerBlockData.blockDescription,
      };

      yield* call(updateBlocksSaga, {
        type: userActions.updateBlocks.type,
        payload: { blocksArray: [newBioBlock], web3Provider },
      });

      amplitudeInstance.track('profile_edit_saved', { banner_photo_source: ipfsLink });

      yield* put(
        userModel.userActions.updateUserState({
          banner: ipfsLink,
        }),
      );
      yield* put(
        userModel.userActions.updateUserBannerBlockDataState({
          blockPayload: { contents: ipfsLink },
        }),
      );
      yield* put(
        profileActions.updateProfileState({
          banner: ipfsLink,
        }),
      );
    } else {
      yield* call(addBlockSaga, {
        type: userActions.addBlock.type,
        payload: {
          blocksArray: [
            {
              blockType: 'Banner',
              blockPayload: { contents: ipfsLink },
              blockDescription: ' ',
            },
          ],
          web3Provider,
          withThrowError: true,
        },
      });

      amplitudeInstance.track('profile_edit_saved', { banner_photo_source: ipfsLink });

      yield* call(getUserInfoSaga, {
        type: userActions.getUserInfo.type,
        payload: undefined,
      });

      yield* call(getProfileInfoSaga, {
        type: profileActions.getProfileInfo.type,
        payload: { name: domainName || address },
      });
    }

    getToastMessage('success', 'Banner successfully changed');
    yield* put(success(type));
  } catch (err) {
    getToastMessage('error', 'Banner has not changed');
    yield* put(error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(userActions.changeBanner.type, changeBannerSaga);
}
