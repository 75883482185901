import { Box, Button, styled, Typography } from '@mui/material';
import { userModel } from 'entities/user';
import {
  BORDER_RADIUS_S,
  BORDER_RADIUS_XS,
  BORDER_S,
  OnlinePoint,
  onPinataImageError,
  shortenPhrase,
  useShallowSelector,
} from 'shared';

const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0.5),
  paddingLeft: theme.spacing(2.25),
  gap: theme.spacing(2),
  flexDirection: 'row',
  alignItems: 'center',
  background: theme.themeColors.colorBackground,
  borderRadius: BORDER_RADIUS_S,
  border: BORDER_S,
  borderColor: theme.themeColors.colorBorder,
  '&:hover': {
    background: theme.themeColors.colorBackground,
    borderColor: theme.themeColors.colorBorder,
  },
}));

const ProfileWrapper = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  background: theme.themeColors.backgroundSecondary,
  borderRadius: BORDER_RADIUS_XS,
  overflow: 'hidden',
  position: 'relative',

  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

type AccountBlockProps = {
  isMenuOpen: boolean;
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export const AccountBlock = ({ isMenuOpen, handleClick }: AccountBlockProps) => {
  const { address, avatar } = useShallowSelector(userModel.selectors.getUser);
  return (
    <Card
      component={Button}
      id="account-menu-button"
      aria-controls={isMenuOpen ? 'account-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={isMenuOpen ? 'true' : undefined}
      onClick={handleClick}
    >
      <Typography className="color-primary medium">{shortenPhrase(address, 5, 4)}</Typography>
      <ProfileWrapper>
        {avatar && <Box component="img" src={avatar} onError={(e) => onPinataImageError(e, avatar || '')} />}
        <OnlinePoint sx={{ position: 'absolute', right: 0, bottom: 0 }} />
      </ProfileWrapper>
    </Card>
  );
};
