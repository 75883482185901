import { ReactComponent as ApprovedIcon } from './components/approved.svg';
import { ReactComponent as ArrowIcon } from './components/arrow.svg';
import { ReactComponent as ArrowDownIcon } from './components/arrow-down.svg';
import { ReactComponent as CloseIcon } from './components/close.svg';
import { ReactComponent as CollapseIcon } from './components/collapse.svg';
import { ReactComponent as CryptoNativeIcon } from './components/crypto-native.svg';
import { ReactComponent as Date } from './components/date.svg';
import { ReactComponent as EditIcon } from './components/edit.svg';
import { ReactComponent as Eth } from './components/eth.svg';
import { ReactComponent as ExtendIcon } from './components/extend.svg';
import { ReactComponent as LensComment } from './components/lens-comment.svg';
import { ReactComponent as LensLike } from './components/lens-like.svg';
import { ReactComponent as LensMirror } from './components/lens-mirror.svg';
import { ReactComponent as LinkIcon } from './components/link.svg';
import { ReactComponent as Loader } from './components/loader.svg';
import { ReactComponent as Location } from './components/location.svg';
import { ReactComponent as LogoutIcon } from './components/logout.svg';
import { ReactComponent as MailIcon } from './components/mail.svg';
import { ReactComponent as Matic } from './components/matic.svg';
import { ReactComponent as Metamask } from './components/metamask.svg';
import { ReactComponent as MinusIcon } from './components/minus.svg';
import { ReactComponent as PassportLogo } from './components/passportLogo.svg';
import { ReactComponent as PlusIcon } from './components/plus.svg';
import { ReactComponent as PointsIcon } from './components/points.svg';
import { ReactComponent as Profile } from './components/profile.svg';
import { ReactComponent as SaveIcon } from './components/save.svg';
import { ReactComponent as SmileIcon } from './components/smile.svg';
import { ReactComponent as Сalendly } from './components/socials/calendly.svg';
import { ReactComponent as CustomSocial } from './components/socials/custom-social.svg';
import { ReactComponent as Discord } from './components/socials/discord.svg';
import { ReactComponent as Facebook } from './components/socials/facebook.svg';
import { ReactComponent as Github } from './components/socials/github.svg';
import { ReactComponent as Instagram } from './components/socials/instagram.svg';
import { ReactComponent as Lenster } from './components/socials/lenster.svg';
import { ReactComponent as Linkedin } from './components/socials/linkedin.svg';
import { ReactComponent as Telegram } from './components/socials/telegram.svg';
import { ReactComponent as Tiktok } from './components/socials/tiktok.svg';
import { ReactComponent as Twitter } from './components/socials/twitter.svg';
import { ReactComponent as Website } from './components/socials/website.svg';
import { ReactComponent as Whatsapp } from './components/socials/whatsapp.svg';
import { ReactComponent as SortIcon } from './components/sort.svg';
import { ReactComponent as SuccessIcon } from './components/success.svg';
import { ReactComponent as TouchIcon } from './components/touch.svg';
import { ReactComponent as TrashIcon } from './components/trash.svg';
import { ReactComponent as UploadImageIcon } from './components/upload-image.svg';
import { ReactComponent as WalletIcon } from './components/wallet.svg';
import { ReactComponent as WalletConnect } from './components/wallet-connect.svg';
import { ReactComponent as WalletConnectMobile } from './components/wallet-connect-mobile.svg';
import { ReactComponent as Dfinity} from './components/dfinity.svg';

export default {
  loader: Loader,
  'upload-image': UploadImageIcon,
  metamask: Metamask,
  'wallet-connect': WalletConnect,
  'wallet-connect-mobile': WalletConnectMobile,
  matic: Matic,
  smile: SmileIcon,
  success: SuccessIcon,
  edit: EditIcon,
  plus: PlusIcon,
  minus: MinusIcon,
  close: CloseIcon,
  arrow: ArrowIcon,
  extend: ExtendIcon,
  collapse: CollapseIcon,
  approved: ApprovedIcon,
  sort: SortIcon,
  save: SaveIcon,
  delete: TrashIcon,
  touch: TouchIcon,
  mail: MailIcon,
  points: PointsIcon,
  wallet: WalletIcon,
  link: LinkIcon,
  logout: LogoutIcon,
  'arrow-down': ArrowDownIcon,
  'crypto-native': CryptoNativeIcon,
  dfinity: Dfinity,

  website: Website,
  twitter: Twitter,
  lenster: Lenster,
  tiktok: Tiktok,
  instagram: Instagram,
  telegram: Telegram,
  facebook: Facebook,
  discord: Discord,
  whatsapp: Whatsapp,
  linkedin: Linkedin,
  calendly: Сalendly,
  github: Github,
  'gitcoin-passport': PassportLogo,
  'custom-social': CustomSocial,
  profile: Profile,
  location: Location,
  date: Date,
  eth: Eth,
  'lens-mirror': LensMirror,
  'lens-like': LensLike,
  'lens-comment': LensComment,
};
