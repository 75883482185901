import axios from 'axios';

export async function fetchTokenRate(token: string) {
  const symbol = token === 'USDT' ? 'USDSUSDT' : `${token}USDT`;

  const BINANCE_API_URL = `https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`;

  try {
    const response = await axios.get(BINANCE_API_URL);

    return response.data.price;
  } catch (error) {
    console.error(error);
    return -1;
    // throw new Error('Failed to fetch token price');
  }
}
