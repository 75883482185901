import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileFormFields, validationSchema } from 'features/profile-form';

export const useValidateForm = (initialValues: ProfileFormFields) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    watch,
  } = useForm<ProfileFormFields>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  return { register, handleSubmit, watch, errors, isValid };
};
