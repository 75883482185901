export const gitcoinProvidersToLinksMatches: any = {
  Github: 'Github',
  Discord: 'Discord',
  Linkedin: 'LinkedIn',
  Lens: 'Lenster',
  Twitter: 'Twitter',
};

export const gitcoinProvidersToTags: any = {
  Ens: {
    icon: 'ens',
    text: 'ENS name owner',
    color: '#5256FF',
    background: 'rgba(82,117,255, 0.1)',
  },
  NFT: {
    icon: 'nft',
    text: 'Ethereum NFT owner',
    color: '#2AB1B9',
    background: 'rgba(29,164,197, 0.1)',
  },
  POAP: {
    icon: 'poap',
    text: 'Over 15 days POAP owner',
    color: '#6534FF',
    background: 'rgba(101,52,255, 0.1)',
  },
  FirstEthTxnProvider: {
    icon: 'eth',
    text: 'ETH user',
    color: '#FF9C92',
    background: 'rgba(255,233,77, 0.1)',
  },
  Poh: {
    icon: 'poh',
    text: 'Is Human',
    color: '#FF6800',
    background: 'rgba(255,179,1, 0.1)',
  },
  Brightid: {
    icon: 'brightid',
    text: 'Brightid user',
    color: '#FB8960',
    background: 'rgba(251,137,96, 0.1)',
  },
  Snapshot: {
    icon: 'snapshot',
    text: 'Snapshot voter',
    color: '#FFAC33',
    background: 'rgba(255,172,51, 0.1)',
  },
  GTC: {
    icon: 'gtc',
    text: 'At least 10 GTC',
    color: '#0E0333',
    background: 'rgba(0, 230,168, 0.1)',
  },
  GtcStaking: {
    icon: 'gtc-staking',
    text: 'GTC staker',
    color: '#8C65F7',
    background: 'rgba(140,101,247, 0.1)',
  },
  ZkSync: {
    icon: 'zksync',
    text: 'Transacted on zkSync 1.0',
    color: '#4E529A',
    background: 'rgba(140,141,252, 0.1)',
  },
  GnosisSafe: {
    icon: 'gnosis-safe',
    text: 'Gnosis safe signer/owner',
    color: '#008B73',
    background: 'rgba(0,139,115, 0.1)',
  },
  Coinbase: {
    icon: 'coinbase',
    text: 'Coinbase user',
    color: '#0052FF',
    background: 'rgba(0,82,255, 0.1)',
  },
  GitPOAP: {
    icon: 'gitpoap',
    text: 'Over 15 days GitPOAP owner',
    color: '#17457C',
    background: 'rgba(24,69,124, 0.1)',
  },
  Polygon: {
    icon: 'polygon',
    text: 'Polygon user',
    color: '#8247E5',
    background: 'rgba(130,71,229, 0.1)',
  },
  Relinkd: {
    icon: 'relinkd',
    text: '{r}elinkd early adopter',
    color: '#000000',
    background: 'rgba(125,133,255, 0.1)',
  },
};

export const gitcoinStampsToSocials: any = {
  TwitterFollowerGT100: {
    provider: 'Twitter',
    title: 'More than 100 followers',
  },
  TwitterFollowerGT500: {
    provider: 'Twitter',
    title: 'More than 500 followers',
  },
  TwitterFollowerGTE1000: {
    provider: 'Twitter',
    title: 'More than 1000 followers',
  },
  TwitterFollowerGT5000: {
    provider: 'Twitter',
    title: 'More than 5000 followers',
  },
  TwitterTweetGT10: {
    provider: 'Twitter',
    title: 'More than 10 tweets',
  },
  FiveOrMoreGithubRepos: {
    provider: 'Github',
    title: 'Five or more Github repos',
  },
  ForkedGithubRepo: {
    provider: 'Github',
    title: 'At least 1 Github repo forked by another user',
  },
  StarredGithubRepo: {
    provider: 'Github',
    title: 'At least 1 Github repo starred by another user',
  },
  TenOrMoreGithubFollowers: {
    provider: 'Github',
    title: 'Ten or more Github followers',
  },
  FiftyOrMoreGithubFollowers: {
    provider: 'Github',
    title: 'Fifty or more Github followers',
  },
  FacebookProfilePicture: {
    provider: 'Facebook',
    title: 'Profile Picture attached',
  },
};

/* "gitcoin stamp provider": "type of block" */

export const walletsIntegationTest: any = {
  default: '0xDD6BFbe9EC414FFABBcc80BB88378c0684e2Ad9c',
  zkBadges: '0xf61cabba1e6fc166a66bca0fcaa83762edb6d4bd',
};
