import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { CredentialData } from 'entities/profile/types';

export async function fetchProfileZKBadges(address: string) {
  const API_URL = 'https://api.sismo.io';

  const client = new ApolloClient({
    uri: API_URL,
    cache: new InMemoryCache(),
  });

  const queryAllMintedBadgesForAccount = gql`
      query getAllMintedBadgesForAccount {
          mintedBadges (where: {owner: "${address}"} 
        ) {
            id
            level
            network
            owner {
              id
            }
            badge {
              tokenId
            }
            issuer
            mintedAt
            transaction {
              id
            }
            rawAttestation {
              id
              extraData
            }
          }
      }`;

  const badges: CredentialData[] = [];

  try {
    const response = await client.query({
      query: queryAllMintedBadgesForAccount,
    });

    await Promise.all(
      response.data.mintedBadges.map(async (badge: any) => {
        const queryBadgeById = gql`
              query getBadgeWithId {
                badge(id: ${badge.badge.tokenId}) {
                  attributes {
                    trait_type
                    value
                  }
                  availableNetworks
                  description
                  id
                  image
                  isCurated
                  tokenId
                  name
                  tokenIdHex
                  # here is the snapshot used for the badge
                  snapshot {
                    id
                    dataUrl
                    size
                    timestamp
                    valueDistribution {
                      numberOfAccounts
                      value
                    }
                    group {
                      id
                    }
                  }
                }
              }
            `;

        const { data: result }: any = await client.query({ query: queryBadgeById });
        const fetchedBadge = result.badge;
        console.log(fetchedBadge);

        badges.push({
          id: fetchedBadge.tokenId,
          title: fetchedBadge.name,
          img: fetchedBadge.image,
          subtitle: fetchedBadge.description,
          usersCount: fetchedBadge.snapshot ? fetchedBadge.snapshot.size : undefined,
        });
      }),
    );

    return badges;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch profile ZK badges');
  }
}
