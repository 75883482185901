import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BlockData, UpdateBlockData } from 'entities/user/model/types';
import { Socials } from 'shared';
import * as Yup from 'yup';

export type LinkField = {
  name: Socials;
  initialLabel: string;
  initialValue: string;
  label: string;
  value: string;
  placeholder: string;
  linkUrl: string;
  blockId: number | null;
};
export type UpdateLinkField = Omit<LinkField, 'blockId'> & { blockId: number };
export type LinksFormFields = { links: LinkField[] };

const linkFieldSchema = {
  name: Yup.string().required('Required'),
  label: Yup.string().required('Required'),
  value: Yup.string().required('Required'),
};

export const validationSchema = Yup.object({
  links: Yup.array().of(Yup.object().shape(linkFieldSchema)),
});

export const useValidateForm = () => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<LinksFormFields>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      links: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'links',
  });

  return { register, control, fields, errors, reset, append, remove, handleSubmit };
};

export const formatLinksToBlockData = (formLinks: LinksFormFields['links']): BlockData[] =>
  formLinks.map(({ name, value, label }) => ({
    blockType: name,
    blockPayload: { contents: value },
    blockDescription: label,
  }));

export const formatLinksToUserBlockData = (formLinks: UpdateLinkField[]): UpdateBlockData[] =>
  formLinks.map(({ value, label, blockId }) => ({
    blockPayload: { contents: value },
    blockDescription: label,
    blockId,
  }));
