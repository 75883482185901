import {
  COLOR_ACCENT,
  COLOR_BLACK,
  COLOR_BUTTON_DISABLED,
  COLOR_BUTTON_SECONDARY,
  COLOR_GRAY_HOVER,
  COLOR_GREEN,
  COLOR_GREEN_ALPHA,
  COLOR_LIGHT_GRAY,
  COLOR_RED,
  COLOR_RED_ALPHA,
  COLOR_WH,
} from '../colors.constants';

export const colorsButton = {
  buttonDisabledBackground: COLOR_BUTTON_DISABLED,
  buttonDisabledColor: COLOR_WH,
  //  contained button
  buttonContainedBackground: COLOR_BLACK,
  buttonContainedColor: COLOR_WH,
  buttonContainedBackgroundHover: COLOR_ACCENT,
  buttonContainedColorHover: COLOR_WH,
  buttonContainedBackgroundHoverSecondary: COLOR_GRAY_HOVER,
  buttonContainedColorHoverSecondary: COLOR_BLACK,
  //  contained secondary button
  buttonContainedSecondaryBackground: COLOR_BUTTON_SECONDARY,
  buttonContainedSecondaryColor: COLOR_BLACK,
  buttonContainedSecondaryBackgroundHover: COLOR_ACCENT,
  buttonContainedSecondaryColorHover: COLOR_WH,
  //  outlined button
  buttonOutlinedBackground: COLOR_WH,
  buttonOutlinedColor: COLOR_BLACK,
  buttonOutlinedColorBorder: COLOR_LIGHT_GRAY,
  buttonOutlinedBackgroundHover: COLOR_ACCENT,
  buttonOutlinedColorHover: COLOR_WH,
  //  success button
  buttonSuccessBackground: COLOR_GREEN_ALPHA,
  buttonSuccessColor: COLOR_GREEN,
  buttonSuccessBackgroundHover: COLOR_GREEN_ALPHA,
  buttonSuccessColorHover: COLOR_GREEN,
  //  error button
  buttonErrorBackground: COLOR_RED_ALPHA,
  buttonErrorColor: COLOR_RED,
  buttonErrorBackgroundHover: COLOR_RED_ALPHA,
  buttonErrorColorHover: COLOR_RED,
};
