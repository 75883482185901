import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import axios, { AxiosResponse } from 'axios';

import { userActions } from '../reducer';

export function* getAuthQR({ type }: ReturnType<typeof userActions.getAuthQR>) {
  yield* put(request(type));

  try {
    const authQR: AxiosResponse = yield axios.get('https://api.relinkd.xyz/auth');

    yield* put(
        userActions.updateUserState({
            authQR: JSON.stringify(authQR.data?.qr),
            session: authQR.data?.session
        })
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getAuthQR', err);
  }
}

export default function* listener() {
  yield takeLatest(userActions.getAuthQR.type, getAuthQR);
}
