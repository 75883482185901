import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CredentialData } from 'entities/profile';

const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body1,
  height: '40px',
  [theme.breakpoints.down('md')]: {
    fontSize: 18,
  },
}));

type CredentialAccordionProps = {
  credentials: any;
  label: string;
  children: any;
  handleViewAll: any;
  credentialsSliced: any;
  isExpanded?: boolean;
  onToggle?: (label: string) => void;
};

export const AccordionWrapper = ({
  children,
  label,
  credentials: allCredentials,
  handleViewAll,
  credentialsSliced,
  isExpanded,
  onToggle,
}: CredentialAccordionProps) => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Accordion className={isDownMd ? 'no-border' : 'secondary'} expanded={isExpanded}>
      <AccordionSummary
        onClick={() => {
          if (onToggle) onToggle(label);
        }}
      >
        {label}
        <Typography component="span" fontSize={20} ml={1.5}>
          {allCredentials.length}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {children}
          {credentialsSliced.length < allCredentials.length && (
            <Grid item container xs={12} justifyContent="center">
              <StyledButton variant="text" onClick={handleViewAll}>
                View all
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
