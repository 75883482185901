import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { domainModel } from 'entities/domain';
import { domainActions } from 'entities/domain/model';
import { userModel } from 'entities/user';
import { CreateProfileHeader, StateButton } from 'features';
import {
  amplitudeInstance,
  checkRequestStatusEquality,
  RequestStatus,
  routes,
  uiSelector,
  useShallowSelector,
  useWalletConnectorContext,
} from 'shared';
import { Layout } from 'widgets';

import { DomainFormFields, initialState, useValidateForm } from './config';

enum stateButtonTexts {
  default = 'check {r}name!',
  success = 'Available',
  error = 'Unavailable',
}

export const DomainPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { type } = useParams();
  const navigate = useNavigate();
  const isDownMdLayout = useMediaQuery(theme.breakpoints.down('md'));
  const uiState = useShallowSelector(uiSelector);
  const { busy, name, domainPrice, maxFeePerGas } = useShallowSelector(domainModel.selectors.selectDomainInfo);
  const {
    domain: { domainName: existedDomainName },
    profileContractAddress,
    address,
    links,
    balance,
  } = useShallowSelector(userModel.selectors.getUser);
  const { walletService } = useWalletConnectorContext();

  const [domainState, setDomainState] = useState<keyof typeof stateButtonTexts>('default');

  const { register, watch, handleSubmit, errors, isValid } = useValidateForm(initialState);
  const inputDomain = watch('domain');
  const isEnoughBalance = domainPrice + maxFeePerGas < +balance;

  const handleSubmitDomain = ({ domain }: DomainFormFields) => {
    if (domain) {
      dispatch(domainActions.createDomain({ name: domain, web3Provider: walletService.Web3() }));
      amplitudeInstance.track('rdomain_set', { domainname_set: domain });
    } else if (type === 'mint') {
      navigate(routes.profile.getPathname(address));
      amplitudeInstance.track('rdomain_skip');
    } else {
      navigate(routes.createProfile.path);
      amplitudeInstance.track('rdomain_skip');
    }
  };
  const isGetDomainInfoLoading = checkRequestStatusEquality(uiState[domainActions?.getDomainInfo?.type]);
  const isGetDomainInfoSuccess = checkRequestStatusEquality(
    uiState[domainActions?.getDomainInfo?.type],
    RequestStatus.SUCCESS,
  );
  const isCreateDomainLoading = checkRequestStatusEquality(uiState[domainActions.createDomain?.type]);
  const isCreateDomainError = checkRequestStatusEquality(
    uiState[domainActions.createDomain?.type],
    RequestStatus.ERROR,
  );

  const checkName = () => {
    dispatch(domainActions.getDomainInfo({ name: inputDomain }));
    amplitudeInstance.track('rdomain_check', { domainname_checked: inputDomain });
  };

  useEffect(() => {
    if (isGetDomainInfoSuccess) setDomainState(busy ? 'error' : 'success');
  }, [isGetDomainInfoSuccess, busy]);

  useEffect(() => {
    setDomainState('default');
  }, [inputDomain]);

  useEffect(() => {
    if (existedDomainName) {
      if (type === 'mint') {
        navigate(routes.profile.getPathname(existedDomainName));
      } else if (profileContractAddress) {
        if (links.length) {
          navigate(routes.profile.getPathname(existedDomainName));
        } else {
          navigate(routes.socialLinks.path);
        }
      } else {
        navigate(routes.createProfile.path);
      }
    }
  }, [existedDomainName, links, navigate, profileContractAddress, type]);

  useEffect(() => {
    if (isCreateDomainError) window.location.reload();
  }, [isCreateDomainError]);

  useEffect(() => {
    dispatch(domainActions.getFactoryState());
  }, [dispatch]);

  return (
    <Stack component="form" onSubmit={handleSubmit(handleSubmitDomain)}>
      <Layout
        footerChildren={
          <Button
            color={inputDomain ? 'primary' : 'secondary'}
            sx={{ m: 2.5, ml: 'auto' }}
            type="submit"
            disabled={
              !isValid ||
              isCreateDomainLoading ||
              (domainState !== 'success' && inputDomain !== '') ||
              (domainState === 'success' && !isEnoughBalance)
            }
          >
            {!isCreateDomainLoading && (inputDomain ? 'Mint' : 'Skip')}
            {isCreateDomainLoading ? <CircularProgress size={24} sx={{ color: theme.themeColors.background }} /> : ''}
          </Button>
        }
      >
        {type !== 'mint' && <CreateProfileHeader />}
        <Typography variant="h1" mt={{ xs: 5.5, md: 11.25 }} mb={{ xs: 13, md: 7.5 }}>
          Choose your &#123;r&#125;domain
        </Typography>
        <TextField
          {...register('domain')}
          disabled={isGetDomainInfoLoading}
          helperText={`${inputDomain.length}/31`}
          InputProps={{
            inputProps: { maxLength: 31, style: { textTransform: 'lowercase' } },
            startAdornment: (
              <InputAdornment position="start" variant="standard" sx={{ mr: 0 }}>
                <Typography variant="body2">app.relinkd.xyz/</Typography>
              </InputAdornment>
            ),
            endAdornment: !isDownMdLayout && (
              <InputAdornment position="end">
                <StateButton
                  state={domainState}
                  text={stateButtonTexts[domainState]}
                  disabled={inputDomain === '' || !isValid}
                  isLoading={isGetDomainInfoLoading}
                  onClick={checkName}
                  sx={{ minWidth: 95 }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Typography fontSize="16px" mt={0.5} mb={{ xs: 13, md: 7.5 }}>
          Domain cost is 1 MATIC + gas
        </Typography>
        <Typography mt={0.5} ml={1} className="color-error">
          {errors.domain?.message}
        </Typography>
        {!isEnoughBalance && (
          <Typography mt={1} ml={1} className="color-error">
            You don&apos;t have enough matics in your wallet. Domain price is {domainPrice} matic + gas fee
          </Typography>
        )}

        {isDownMdLayout && (
          <StateButton
            state={domainState}
            text={stateButtonTexts[domainState]}
            disabled={inputDomain === '' || !isValid}
            isLoading={isGetDomainInfoLoading}
            onClick={checkName}
            fullWidth
            sx={{ mt: 2, minWidth: 95 }}
          />
        )}
      </Layout>
    </Stack>
  );
};
