import { fetchProfileLens, profileModel } from 'entities/profile';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { ProfileLensState, ProfileState } from '../../types';
import { profileActions } from '../reducer';

export function* getProfileLensSaga({ type }: ReturnType<typeof profileActions.getProfileLens>) {
  yield* put(request(type));

  const { address }: Pick<ProfileState, 'address'> = yield select(profileModel.selectors.getProfile);

  try {
    const defaultProfile: ProfileLensState = yield fetchProfileLens(
      address === '0x2a81C13F9366395c8FD1EA24912294230d062Db3' ? '0x518f688265970cea7a5d83e3a2793be4211e88b9' : address,
    );

    yield* put(profileActions.updateProfileLens(defaultProfile));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getProfileLens', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileLens.type, getProfileLensSaga);
}
