import { toast, ToastOptions } from 'react-toastify';

import { ErrorToastIcon, SuccessToastIcon } from './icons';

type TypeMessageProps = 'success' | 'error' | 'info' | 'warning';

export const getToastMessage = (typeMessage: TypeMessageProps, message: string, otherProps?: ToastOptions) => {
  const icon = typeMessage === 'error' ? <ErrorToastIcon /> : <SuccessToastIcon />;

  return toast(message, { type: typeMessage, icon, closeButton: false, ...otherProps });
};
