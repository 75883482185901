import { userModel } from 'entities/user';
import { ContractsNames, createContract, FactoryAbi, getContractDataByItsName, logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { userActions } from '../reducer';

export function* getProfileContractAddressSaga({ type }: ReturnType<typeof userActions.getProfileContractAddress>) {
  yield* put(request(type));
  const { chainType, address: userAddress } = yield select(userModel.selectors.selectUserWeb3Info);

  const [factoryAbi, factoryContractAddress] = getContractDataByItsName(ContractsNames.Factory, chainType);

  try {
    const factoryContract: FactoryAbi = yield createContract(factoryAbi, factoryContractAddress, chainType);
    const profileContractsArr = yield* call(factoryContract.methods.getUserProfiles(userAddress).call);
    if (profileContractsArr.length) {
      yield* put(
        userActions.updateUserState({
          profileContractAddress: profileContractsArr[0],
        }),
      );
    }

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getProfileContractAddress', err);
  }
}

export default function* listener() {
  yield takeLatest(userActions.getProfileContractAddress.type, getProfileContractAddressSaga);
}
