import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { userModel } from 'entities/user';
import {
  amplitudeInstance,
  BORDER_RADIUS_S,
  BORDER_S,
  BORDER_WIDTH_HOVER,
  Chains,
  routes,
  useShallowSelector,
  useWalletConnectorContext,
  WalletProviders,
} from 'shared';
import { Layout, LayoutBackground } from 'widgets';

import { walletsOptions } from './helper';

const WalletCard = styled(Box)(({ theme }) => ({
  width: 200,
  height: 148,
  background: theme.themeColors.colorBackground,
  border: BORDER_S,
  borderColor: theme.themeColors.colorBorder,
  borderRadius: BORDER_RADIUS_S,
  padding: theme.spacing(2),
  '&:hover': {
    borderColor: theme.themeColors.colorBorderHover,
    borderWidth: BORDER_WIDTH_HOVER,
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: 56,
    padding: theme.spacing(1),
  },
}));

const InnerWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 116,
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    height: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    'img, svg': {
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
}));

export const ConnectPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const [walletProvider, setWalletProvider] = useState('');
  const { address } = useShallowSelector(userModel.selectors.selectUserWeb3Info);
  const { connect } = useWalletConnectorContext();

  const handleConnectWallet = useCallback(
    (provider: WalletProviders, chain: Chains) => {
      connect(provider, chain);
      setWalletProvider(provider.toLowerCase());
    },
    [connect],
  );

  useEffect(() => {
    if (address) {
      navigate(routes.agreement.path);
      amplitudeInstance.track('wallet_connect', {
        connectedwallet: walletProvider,
      });
    }
  }, [address, navigate]);

  return (
    <Layout bg={LayoutBackground.Sm}>
      <Typography
        variant="h1"
        className="center"
        mt={{ xs: 'calc(50vh - 256px )', md: 'calc(50vh - 220px )' }}
        mb={3.75}
      >
        Connect your wallet
      </Typography>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} justifyContent="space-between" alignItems="center">
        {walletsOptions.map(({ provider, chain, icon, iconMobile, text }) => (
          <WalletCard key={text} onClick={() => handleConnectWallet(provider, chain)}>
            <InnerWrapper>
              {isDownMd ? iconMobile : icon}
              <Typography className="color-primary">{text}</Typography>
            </InnerWrapper>
          </WalletCard>
        ))}
      </Stack>
      <Typography className="center" mt={{ xs: 3.75, md: 7.5 }}>
        Sign in by selecting your preferred wallet
      </Typography>
    </Layout>
  );
};
