import { FC, PropsWithChildren, useEffect } from 'react';
import { Box } from '@mui/material';
import { initialFormState, ProfileFormFields, useValidateForm } from 'features/profile-form';
import { TextfieldWithLabel } from 'shared';

type ProfileFormProps = {
  changeIsSubmitDisabled: (v: boolean) => void;
  onSubmit: (formValues: ProfileFormFields) => void;
  initialValues?: ProfileFormFields;
};

export const ProfileForm: FC<Partial<PropsWithChildren> & ProfileFormProps> = ({
  initialValues,
  changeIsSubmitDisabled,
  onSubmit,
  children,
}) => {
  const { register, handleSubmit, watch, errors, isValid } = useValidateForm(initialValues || initialFormState);
  const nameInputValue = watch('username');
  const bioInputValue = watch('bio');

  const handleSubmitProfile = (formValues: ProfileFormFields) => {
    onSubmit(formValues);
  };

  useEffect(() => {
    changeIsSubmitDisabled(!isValid);
  }, [isValid, changeIsSubmitDisabled]);

  return (
    <Box component="form" id="profile-form" onSubmit={handleSubmit(handleSubmitProfile)}>
      <TextfieldWithLabel
        label={errors.username?.message || 'Your displayed name'}
        {...register('username')}
        isError={!!errors.username}
        inputProps={{
          maxLength: 20,
        }}
        helperText={`${nameInputValue.length}/20`}
        sx={{ mb: 4 }}
      />
      {children}

      <TextfieldWithLabel
        label={errors.bio?.message || 'Bio'}
        {...register('bio')}
        isError={!!errors.bio}
        multiline
        rows={5}
        inputProps={{
          maxLength: 500,
        }}
        helperText={`${bioInputValue.length}/500`}
        sx={{ mb: 4 }}
      />
    </Box>
  );
};
