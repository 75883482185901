import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { userModel } from 'entities/user';
import { CreateProfileHeader } from 'features';
import { amplitudeInstance, COLOR_ACCENT_DARK, Icon, routes, useShallowSelector } from 'shared';
import { Layout } from 'widgets';

export const CongratsPage = () => {
  const navigate = useNavigate();
  const {
    address,
    domain: { domainName },
  } = useShallowSelector(userModel.selectors.getUser);

  const handleButtonClick = () => {
    navigate(routes.profile.getPathname(domainName || address));
    amplitudeInstance.track('profile_open', {
      profile_link: `${window.location.origin}/profile/${domainName || address}`,
    });
  };

  return (
    <Layout>
      <Stack alignItems="center">
        <CreateProfileHeader />
        <Stack alignItems="center" spacing={2} mt={23.5} mb={7.5}>
          <Icon type="success" size={98} />
          <Typography variant="h1">Congrats!</Typography>
          <Typography className="center">You are fully prepared to explore the metaverses!</Typography>
        </Stack>
        <Button
          onClick={handleButtonClick}
          sx={{
            '&:hover': {
              background: COLOR_ACCENT_DARK,
            },
          }}
        >
          Let&apos;s go!
        </Button>
      </Stack>
    </Layout>
  );
};
