import { domainModel } from 'entities/domain';
import { userModel } from 'entities/user';
import { UserState } from 'entities/user/model';
import {
  ContractsNames,
  FactoryAbi,
  getContractDataByItsName,
  getToastMessage,
  logger,
  mapChainToChainId,
  toDecimals,
} from 'shared';
import { error, getMaxGas, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { domainActions, DomainState } from '../reducer';

export function* createDomainSaga({
  type,
  payload: { web3Provider, name },
}: ReturnType<typeof domainActions.createDomain>) {
  yield* put(request(type));
  const {
    chainType,
    network,
    address: userAddress,
    profileContractAddress,
  }: Pick<UserState, 'chainType' | 'network' | 'address' | 'profileContractAddress'> = yield select(
    userModel.selectors.getUser,
  );
  const { isFirstDomainFree, domainPrice }: Pick<DomainState, 'isFirstDomainFree' | 'domainPrice'> = yield select(
    domainModel.selectors.selectDomainInfo,
  );
  const [factoryAbi, factoryContractAddress] = getContractDataByItsName(ContractsNames.Factory, chainType);
  const chainId = mapChainToChainId[network][chainType];
  try {
    const factoryContract: FactoryAbi = yield new web3Provider.eth.Contract(factoryAbi, factoryContractAddress);
    const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);

    const { transactionHash } = yield* call(
      factoryContract.methods.createDomain(
        name.toLowerCase(),
        profileContractAddress || '0x0000000000000000000000000000000000000000',
        chainId,
      ).send,
      {
        from: userAddress,
        to: factoryContractAddress,
        value: isFirstDomainFree ? 0 : toDecimals(domainPrice),
        maxFeePerGas,
        maxPriorityFeePerGas,
      },
    );

    yield* put(
      userModel.userActions.updateUserDomain({
        domainName: name.toLowerCase(),
      }),
    );

    getToastMessage('success', 'Successfully added');
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    getToastMessage('error', 'Something went wrong');
    logger('createDomain', err);
  }
}

export default function* listener() {
  yield takeLatest(domainActions.createDomain.type, createDomainSaga);
}
