import { CredentialData } from 'entities/profile';

import { CredentialImg } from '../assets';

export const credentialsMock: CredentialData[] = [
  {
    id: 1,
    title: 'Proof of rAAVE - ETH Amstr proof',
    subtitle: 'Good vibes, good music, great skills',
    usersCount: 12345,
    img: CredentialImg,
  },
  {
    id: 2,
    title: 'Proof of rAAVE - ETH Amstr proof',
    subtitle: 'Good vibes, good music, great skills',
    usersCount: 392,
    img: CredentialImg,
  },
  {
    id: 3,
    title: 'Proof of rAAVE - ETH Amstr proof',
    subtitle: 'Good vibes, good music, great skills',
    usersCount: 392,
    img: CredentialImg,
  },
  {
    id: 4,
    title: 'Proof of rAAVE - ETH Amstr proof',
    subtitle: 'Good vibes, good music, great skills',
    usersCount: 392,
    img: CredentialImg,
  },
  {
    id: 5,
    title: 'Proof of rAAVE - ETH Amstr proof',
    subtitle: 'Good vibes, good music, great skills',
    usersCount: 392,
    img: CredentialImg,
  },
];
