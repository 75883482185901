import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { modalModel } from 'entities/modal';
import { userModel } from 'entities/user';
import { userActions } from 'entities/user/model';
import { UserBlockData } from 'entities/user/model/types';
import { AddLinksForm, allSocialFieldsData } from 'features';
import {
  amplitudeInstance,
  BORDER_RADIUS_S,
  checkRequestStatusEquality,
  FontWeights,
  Icon,
  RequestStatus,
  SocialField,
  Socials,
  uiSelector,
  useShallowSelector,
  useWalletConnectorContext,
} from 'shared';

import {
  formatLinksToBlockData,
  formatLinksToUserBlockData,
  LinkField,
  LinksFormFields,
  UpdateLinkField,
  useValidateForm,
} from '../config';

const Card = styled(Grid)(({ theme }) => ({
  borderRadius: BORDER_RADIUS_S,
  padding: theme.spacing(2, 3.25),

  borderBottom: '4px solid transparent',
  boxShadow: `0 0 0 1px ${theme.themeColors.colorBorder}`,
  flexWrap: 'nowrap',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    flexWrap: 'wrap',
  },

  '&.active': {
    background: theme.themeColors.backgroundAccent,
    boxShadow: `0 0 0 1px ${theme.themeColors.colorBorderHover}`,
    borderColor: theme.themeColors.colorBorderHover,
  },
}));

export const EditLinksForm = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { walletService } = useWalletConnectorContext();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const uiState = useShallowSelector(uiSelector);
  const { links } = useShallowSelector(userModel.selectors.getUser);

  const [activeFieldId, setActiveFieldId] = useState('');
  const [isEditLabelActive, setIsEditLabelActive] = useState(false);
  const [isAddFormActive, setIsAddFormActive] = useState(false);
  const { register, fields, errors, control, reset, append, remove, handleSubmit } = useValidateForm();

  const isEditLinksLoading = checkRequestStatusEquality(uiState[userActions.editBlocks?.type]);
  const isEditLinksSuccess = checkRequestStatusEquality(uiState[userActions.editBlocks?.type], RequestStatus.SUCCESS);

  const handleEdit = (id: string) => {
    setActiveFieldId((prev) => (prev === id ? '' : id));
    setIsEditLabelActive(true);
  };

  const handleChooseSocial = (newSocials: SocialField[]) => {
    newSocials.forEach(({ name, label, link, placeholder }) => {
      append({
        name,
        initialLabel: '',
        initialValue: '',
        label,
        value: '',
        placeholder,
        linkUrl: link,
        blockId: null,
      });
    });
    setIsAddFormActive(false);
  };

  const handleOpenModal = () => {
    setIsAddFormActive(true);
  };

  const onSubmit = ({ links: formLinks }: LinksFormFields) => {
    const updateLinks = formLinks.filter(
      ({ blockId, initialLabel, initialValue, value, label }) =>
        blockId !== null && (initialLabel !== label || initialValue !== value),
    ) as unknown as UpdateLinkField[];
    const addLinks = formLinks.filter(({ blockId }) => blockId === null);

    const updateLinksBlockData = formatLinksToUserBlockData(updateLinks);
    const addLinksBlockData = formatLinksToBlockData(addLinks);
    const deleteLinksBlocksIds = links
      .filter(({ blockId: existedBlockId }) => !formLinks.find(({ blockId }) => existedBlockId === blockId))
      .map(({ blockId }) => blockId);
    const deleteLinksBlocksNames = links
      .filter(({ blockId: existedBlockId }) => !formLinks.find(({ blockId }) => existedBlockId === blockId))
      .map(({ blockType }) => blockType);

    if (addLinks.length) {
      addLinks.map(({ name }) =>
        amplitudeInstance.track('profile_edit_saved', { link_type_added: name.toLowerCase() }),
      );
    }
    if (updateLinks.length) {
      updateLinks.map(({ name }) =>
        amplitudeInstance.track('profile_edit_saved', { link_type_edited: name.toLowerCase() }),
      );
    }
    if (deleteLinksBlocksNames.length) {
      deleteLinksBlocksNames.map((name) =>
        amplitudeInstance.track('profile_edit_saved', { link_type_deleted: name.toLowerCase() }),
      );
    }

    dispatch(
      userActions.editBlocks({
        blocksToAdd: addLinksBlockData.length ? addLinksBlockData : null,
        blocksToUpdate: updateLinksBlockData.length ? updateLinksBlockData : null,
        blocksIdsToDelete: deleteLinksBlocksIds.length ? deleteLinksBlocksIds : null,
        web3Provider: walletService.Web3(),
      }),
    );
  };

  useEffect(() => {
    const typedLinks = links as unknown as Array<Omit<UserBlockData, 'blockType'> & { blockType: Socials }>;
    const linkFields: LinkField[] = typedLinks.map(({ blockType, blockPayload, blockDescription, blockId }) => ({
      name: Socials[blockType] || '',
      initialLabel: blockDescription,
      initialValue: blockPayload?.contents || '',
      label: blockDescription,
      value: blockPayload?.contents || '',
      placeholder: blockPayload?.contents || '',
      linkUrl: allSocialFieldsData.find(({ name }) => name === Socials[blockType])?.link || '',
      blockId,
    }));
    reset({ links: linkFields });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [append, links]);

  useEffect(() => {
    if (isEditLinksSuccess) {
      dispatch({ type: `${userActions.editBlocks?.type}_RESET` });
      dispatch(modalModel.modalActions.closeModal());
    }
  }, [dispatch, isEditLinksSuccess]);

  return isAddFormActive ? (
    <AddLinksForm onSave={handleChooseSocial} />
  ) : (
    <Stack spacing={{ xs: 3.75, md: 7.5 }}>
      <Stack gap={2} mb={3.75} component="form" id="edit-links-form" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, index) => (
          <Card container key={item.id} className={activeFieldId === item.id ? 'active' : ''} alignItems="center">
            <Grid item xs={2} sm={1} alignItems="center" display={{ xs: 'flex', md: 'none' }}>
              <IconButton sx={{ width: 40 }}>
                <Icon type="touch" size={40} />
              </IconButton>
            </Grid>

            <Grid item xs={2} alignItems="center" display={{ xs: 'none', md: 'flex' }}>
              <Icon type={allSocialFieldsData.find(({ name }) => name === item.name)?.icon || 'smile'} size={20} />
            </Grid>

            <Grid item container direction={{ xs: 'column', md: 'row' }} xs={10} sm={11} md={10} gap={2}>
              <Grid item container direction="row" xs={8} gap={1}>
                <TextField
                  variant="standard"
                  {...register(`links.${index}.label`)}
                  placeholder={item.name}
                  disabled={item.id !== activeFieldId}
                  inputRef={(input) => isEditLabelActive && input?.focus()}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mr: 1.75, display: { xs: 'flex', md: 'none' } }}>
                        <Icon
                          type={allSocialFieldsData.find(({ name }) => name === item.name)?.icon || 'smile'}
                          size={20}
                        />
                      </InputAdornment>
                    ),
                    sx: { '.MuiInputBase-input': { fontWeight: `${FontWeights.SemiBold} !important` } },
                  }}
                />
                {errors?.links?.[index]?.label?.message && (
                  <Typography className="color-error" fontSize={14}>
                    {errors?.links?.[index]?.label?.message || ''}
                  </Typography>
                )}
                <Controller
                  control={control}
                  name={`links.${index}.value`}
                  render={({ field }) => (
                    <TextField
                      variant="standard"
                      disabled={item.id !== activeFieldId}
                      placeholder={item.linkUrl ? '' : item.placeholder}
                      onChange={(v) => {
                        field.onChange(v);
                        setIsEditLabelActive(false);
                      }}
                      value={field.value}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start" sx={{ mr: 0 }} disablePointerEvents>
                            <Typography variant="body2">
                              {item.linkUrl.length > 21 && isDownSm ? `...${item.linkUrl.slice(21)}` : item.linkUrl}
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                {errors?.links?.[index]?.value?.message && (
                  <Typography className="color-error" fontSize={14}>
                    {errors?.links?.[index]?.value?.message || ''}
                  </Typography>
                )}
              </Grid>

              <Grid item container gap={2} alignItems="center" justifyContent="end" ml={{ xs: 0, md: 'auto' }}>
                <Grid item mr={{ xs: 'auto', md: 0 }}>
                  <IconButton disabled={isEditLinksLoading} onClick={() => handleEdit(item.id)}>
                    <Icon type="edit" />
                  </IconButton>
                </Grid>
                <IconButton disabled={isEditLinksLoading} onClick={() => remove(index)}>
                  <Icon type="delete" />
                </IconButton>
                {/* <Switch disabled={isEditLinksLoading} /> */}
              </Grid>
            </Grid>
          </Card>
        ))}
      </Stack>
      <Button
        variant="outlined"
        fullWidth
        disabled={isEditLinksLoading}
        startIcon={<Icon type="plus" />}
        onClick={handleOpenModal}
        className="stroke-color"
      >
        Add another link
      </Button>
      <Button fullWidth type="submit" form="edit-links-form" disabled={!!errors?.links || isEditLinksLoading}>
        {isEditLinksLoading ? <CircularProgress size={24} sx={{ color: theme.themeColors.background }} /> : 'Save'}
      </Button>
    </Stack>
  );
};
