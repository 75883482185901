import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, IconButton, Stack, styled, Switch, Typography } from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { fetchTokenRate, profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import {
  amplitudeInstance,
  BORDER_RADIUS_M,
  BORDER_RADIUS_S,
  BORDER_S,
  FontWeights,
  Icon,
  shortenPhrase,
  useShallowSelector,
} from 'shared';

const Card = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  flexDirection: 'row',
  alignItems: 'center',
  background: theme.themeColors.colorBackground,
  borderRadius: BORDER_RADIUS_M,
  border: BORDER_S,
  borderColor: theme.themeColors.colorBorder,
  padding: theme.spacing(2, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2.5),
    borderRadius: BORDER_RADIUS_S,
  },

  '&:hover': {
    background: theme.themeColors.backgroundSecondary,
    borderColor: theme.themeColors.colorBorderHover,
  },
}));

const BalanceButton = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
  background: theme.themeColors.textFieldBackground,
  padding: theme.spacing(0.25, 0.5),
  height: 'unset',
  fontWeight: FontWeights.Regular,
  '&:hover': {
    background: theme.themeColors.textFieldBackground,
  },
  '&.Mui-disabled': {
    color: theme.themeColors.colorTypographyPrimary,
  },
}));

export const PlugBlock = () => {
  const { address, isCurrentUser } = useShallowSelector(profileModel.selectors.getProfile);
  const { icpAddress } = useShallowSelector(userModel.selectors.getUser);
  const [icpPrice, setIcpPrice] = useState(-1);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const price = await fetchTokenRate('ICP');
      setIcpPrice(price);
    })();

    return () => {
      dispatch(modalModel.modalActions.closeModal());
    };
  }, [dispatch]);

  return (
    <Card
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        if(!icpAddress) {
          dispatch(userModel.userActions.connectPlug());
        }
      }}
    >
      <Icon type="dfinity" size={30} />
      <Button variant="text" disabled={isCurrentUser} sx={{ padding: 0, height: 'fit-content' }}>
        <Typography className="color-primary">{icpAddress ? shortenPhrase(icpAddress, 7, 5) : 'Connect'}</Typography>
      </Button>
      <Button
        sx={{
          ml: 'auto',
          color: '#000!important',
          height: 24,
          backgroundColor: '#F5F6FA!important',
          fontSize: { sm: 18, xs: 15 },
        }}
        disabled
      >
        {icpPrice !== -1 ? Math.floor(icpPrice * 100) / 100 : '-'} $
      </Button>
    </Card>
  );
};
