import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  styled,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { profileModel } from 'entities/profile';
import { CredentialCard } from 'features/credential-accordion';
import { amplitudeInstance, FontWeights, Icon, kFormatter, useShallowSelector } from 'shared';

import { assetMock } from '../config';

const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body1,
  height: '40px',
  [theme.breakpoints.down('md')]: {
    fontSize: 18,
  },
}));

export const NFTsBlock = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { isCurrentUser } = useShallowSelector(profileModel.selectors.getProfile);
  const { assets } = useShallowSelector(profileModel.selectors.getProfile);
  const [nfts, setNfts] = useState<any>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setNfts(assets.nfts.slice(0, 6));
  }, [assets.nfts]);

  const handleViewAll = () => {
    setNfts(assets.nfts);
  };

  const handleOpenNFTModal = (id: number) => {
    dispatch(
      modalModel.modalActions.openModal({
        type: Modals.NFTModal,
        data: {
          nftId: id,
        },
      }),
    );
  };

  return (
    <Accordion className={isDownMd ? 'secondary' : ''}>
      <AccordionSummary className="main">
        NFTs
        {/* {isCurrentUser && (
          <Stack direction="row" spacing={2} ml="auto" mr={2}>
            <IconButton onClick={handleEditClick}>
              <Icon type="edit" />
            </IconButton>
            <Switch onClick={handleSwitchClick} />
          </Stack>
        )} */}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} px={{ xs: 0.5, md: 1.25 }}>
          {nfts.map(
            (nft: any, index: any) =>
              nft.rawMetadata.image && (
                <Grid
                  item
                  container
                  gap={2}
                  xs={12}
                  sm={6}
                  p={2}
                  key={nft.tokenId}
                  onClick={() => {
                    handleOpenNFTModal(index);
                  }}
                >
                  <CredentialCard>
                    <Box
                      component="img"
                      width={72}
                      height={72}
                      src={
                        nft.rawMetadata.image.startsWith('ipfs://')
                          ? `https://ipfs.io/ipfs/${nft.rawMetadata.image.slice(7)}`
                          : nft.rawMetadata.image
                      }
                    />
                    <Stack flex={1}>
                      {' '}
                      {/* TODO gateway ipfs to config */}
                      <Typography variant="h5" className="uppercase">
                        {nft.title}
                      </Typography>
                      <Typography className="medium">
                        {nft.description.length > 100 ? `${nft.description.slice(0, 50)}...` : nft.description}
                      </Typography>
                    </Stack>
                    {/* <Button
                  className="success"
                  sx={{ height: 40, width: 80, whiteSpace: 'nowrap', fontWeight: FontWeights.Regular }}
                  disabled
                >
                  {`$ ${kFormatter(+value)}`}
                </Button> */}
                  </CredentialCard>
                </Grid>
              ),
          )}
        </Grid>
        {nfts.length < assets.nfts.length && (
          <Grid item container xs={12} justifyContent="center">
            <StyledButton variant="text" onClick={handleViewAll}>
              View all
            </StyledButton>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
