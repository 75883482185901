import { useCallback, useEffect, useState } from 'react';

export function useShareWebAPI(title: string, text: string, url: string) {
  const [isShareSupported, setSupported] = useState(false);

  useEffect(() => {
    setSupported(!!navigator.share);
  }, []);

  const share = useCallback(async () => {
    if (!isShareSupported) {
      return;
    }

    try {
      await navigator.share({
        title,
        text,
        url,
      });
    } catch (error) {
      console.error(error);
    }
  }, [isShareSupported]);

  return {
    isShareSupported,
    share,
  };
}
