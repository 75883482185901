import { development, LensClient, production, PublicationTypes } from '@lens-protocol/client';
import { fetchProfileZKBadges, profileModel } from 'entities/profile';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { CredentialData, ProfileState } from '../../types';
import { profileActions } from '../reducer';

type resultLens = {
  items: [];
};

export function* getProfileLensPostsSaga({ type }: ReturnType<typeof profileActions.getProfileLensPosts>) {
  yield* put(request(type));

  const { lens } = yield select(profileModel.selectors.getProfile);

  const lensClient = new LensClient({
    environment: production,
  });

  try {
    const result: resultLens = yield lensClient.publication.fetchAll({
      profileId: lens.defaultProfile.id,
      publicationTypes: [PublicationTypes.Post],
    });
    console.log(result, 'result');

    yield* put(
      profileActions.updateProfileLens({
        ...lens,
        posts: result.items,
      }),
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getProfileLensPosts', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getProfileLensPosts.type, getProfileLensPostsSaga);
}
