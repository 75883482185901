import axios from 'axios';
import { logger } from 'shared';

export const PINATA_QUERY = '&img-height=500&img-fit=contain';
export const getIpfsLink = async (formData: FormData): Promise<{ ipfsLink: string }> => {
  let ipfsLink = '';
  try {
    const { data } = await axios({
      method: 'post',
      url: 'https://api.pinata.cloud/pinning/pinFileToIPFS',
      data: formData,
      headers: {
        pinata_api_key: '6f3bb570e97cd2b53f9d',
        pinata_secret_api_key: '4906b6e66e00eb7972201683ceabed25a0b1cca2a58693419563158727846062',
        'Content-Type': 'multipart/form-data',
      },
    });
    ipfsLink = `https://relinkd.mypinata.cloud/ipfs/${data.IpfsHash}?pinataGatewayToken=M0jXutxjKvMaZ0T17GqbaHuwAaGfNN1FZ0lJyfNYUZZ4tPXrkh37snC41f1UWhtG${PINATA_QUERY}`;
  } catch (e) {
    logger('getIpfsLink', e, 'error');
  }
  return { ipfsLink };
};

export const onPinataImageError = (e: any, image: string) => {
  e.target.src = image?.split(PINATA_QUERY)[0];
};
