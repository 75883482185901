import { forwardRef, PropsWithChildren } from 'react';
import { Modal, modalModel } from 'entities/modal';

import { ModalWrapper } from '../ModalWrapper';

export interface LinksModalPayload {
  type: modalModel.Modals.LinksModal;
  data: PropsWithChildren;
}

export const LinksModal = forwardRef<HTMLElement, Modal>(({ data: { children } }) => {
  return <ModalWrapper title="Add link">{children}</ModalWrapper>;
});

LinksModal.displayName = 'LinksModal';
