import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useMediaQuery, useTheme } from '@mui/material';
import { ModalContainer } from 'entities/modal';
import { profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import { initAmplitude, routes, useShallowSelector } from 'shared';

import { withPersistor, withProviders, withRouter, withStore, withTheme, WithWalletConnect } from './providers';
import { RouteManager } from './router';

import 'react-toastify/dist/ReactToastify.css';
import './appStyles/index.scss';

const App = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { isAuthenticated } = useShallowSelector(userModel.selectors.selectIsAuthenticated);

  useEffect(() => {
    initAmplitude();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(userModel.userActions.getUserInfo());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (pathname === '/') {
      // navigate(routes.connect.path);
      navigate(routes.agreement.path);
    }
  }, [navigate, pathname]);

  return (
    <>
      <RouteManager />
      <ToastContainer
        autoClose={4000}
        hideProgressBar
        position={isDownMd ? 'top-center' : 'bottom-center'}
        closeButton
      />
      <ModalContainer />
    </>
  );
};

export default withProviders(withRouter, withTheme, withStore, WithWalletConnect, withPersistor)(App);
