import { profileActions } from 'entities/profile/model';
import { getProfileInfoSaga } from 'entities/profile/model/sagas/getProfileInfo';
import { userModel } from 'entities/user';
import { getToastMessage } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { userActions, UserState } from '../reducer';

import { addBlockSaga } from './addBlock';
import { deleteBlocksSaga } from './deleteBlocks';
import { getUserInfoSaga } from './getUserInfo';
import { updateBlocksSaga } from './updateBlocks';

export function* editBlocksSaga({
  type,
  payload: { blocksToAdd, blocksToUpdate, blocksIdsToDelete, web3Provider },
}: ReturnType<typeof userActions.editBlocks>) {
  yield* put(request(type));

  const {
    domain: { domainName },
    address,
  }: Pick<UserState, 'domain' | 'address'> = yield select(userModel.selectors.getUser);

  try {
    if (blocksToAdd) {
      yield* call(addBlockSaga, {
        type: userActions.addBlock.type,
        payload: { blocksArray: blocksToAdd, web3Provider, withThrowError: true },
      });
    }

    if (blocksToUpdate) {
      yield* call(updateBlocksSaga, {
        type: userActions.updateBlocks.type,
        payload: { blocksArray: blocksToUpdate, web3Provider },
      });
    }

    if (blocksIdsToDelete) {
      yield* call(deleteBlocksSaga, {
        type: userActions.deleteBlocks.type,
        payload: { blocksIds: blocksIdsToDelete, web3Provider },
      });
    }

    yield* put(success(type));
    getToastMessage('success', 'Successfully changed');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    yield* put(error(type, err));
    getToastMessage('error', err.message);
  } finally {
    yield* call(getUserInfoSaga, {
      type: userActions.getUserInfo.type,
      payload: undefined,
    });

    yield* call(getProfileInfoSaga, {
      type: profileActions.getProfileInfo.type,
      payload: { name: domainName || address },
    });
  }
}

export default function* listener() {
  yield takeLatest(userActions.editBlocks.type, editBlocksSaga);
}
