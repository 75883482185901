import { useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Box, Hidden, Stack } from '@mui/material';
import { userModel } from 'entities/user';
import { ConnectButton } from 'features/connect-button';
import { amplitudeInstance, routes, useShallowSelector } from 'shared';

import { Logo } from '../assets';

import { AccountBlock, AccountMenu } from './components';

export const Header = () => {
  const {
    address,
    profileContractAddress,
    domain: { domainName },
  } = useShallowSelector(userModel.selectors.getUser);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const location = useLocation();
  const { domain } = useParams();

  const handleClickAccount = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Hidden smDown={!!domain}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" px={2.5} pt={2.5}>
        <NavLink
          to={routes.agreement.path}
          style={{ lineHeight: 0 }}
          onClick={() => amplitudeInstance.track('create_profile_after_viewing')}
        >
          <img src={Logo} alt="logo" width={109} height={56} />
        </NavLink>
        {address ? (
          <Box>
            <AccountBlock isMenuOpen={isMenuOpen} handleClick={handleClickAccount} />
            <AccountMenu isMenuOpen={isMenuOpen} anchorEl={anchorEl} handleClose={handleCloseMenu} />
          </Box>
        ) : (
          <ConnectButton />
        )}
      </Stack>
    </Hidden>
  );
};
