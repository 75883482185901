import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Stack, styled, Typography } from '@mui/material';
import { BORDER_L, BORDER_RADIUS_S } from 'shared';

import { getPointsState } from '../config';

const StepPoint = styled(Box)(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: BORDER_RADIUS_S,
  '&.active': {
    border: BORDER_L,
    borderColor: theme.themeColors.colorBorderHover,
  },
  '&.past': {
    background: theme.themeColors.colorPointBackgroundSecondary,
  },
  '&.feature': {
    background: theme.themeColors.colorPointBackgroundTertiary,
  },
  [theme.breakpoints.down('md')]: {
    width: 18,
    height: 18,
  },
}));

const StepWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2.25),
  flexWrap: 'nowrap',
  p: { color: theme.themeColors.colorTypographySecondary },

  '&.active': {
    p: { color: theme.themeColors.colorTypographyPrimary },
  },

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1.5),
    '&.past': {
      p: { display: 'none' },
    },
  },
}));

export const CreateProfileHeader = () => {
  const { pathname } = useLocation();
  const pointsState = useMemo(() => getPointsState(pathname), [pathname]);

  return (
    <Stack direction="row" justifyContent={{ xs: 'start', md: 'center' }} spacing={{ xs: 3.75, md: 4 }} mt={2}>
      {pointsState.map(({ id, label, state }) => (
        <StepWrapper key={id} className={state}>
          <StepPoint className={state} />
          <Typography noWrap>{label}</Typography>
        </StepWrapper>
      ))}
    </Stack>
  );
};
