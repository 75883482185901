import { forwardRef,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Link, Stack, styled, Typography } from '@mui/material';
import { Modal, modalModel } from 'entities/modal';
import { profileModel } from 'entities/profile';
import { profileActions } from 'entities/profile/model';
import { userModel } from 'entities/user';
import { userActions } from 'entities/user/model';
import QRCode from 'react-qr-code';
import {
  BORDER_RADIUS_S,
  BORDER_S,
  checkRequestStatusEquality,
  COLOR_ACCENT,
  RequestStatus,
  uiSelector,
  useShallowSelector,
  useWalletConnectorContext,
} from 'shared';

import { ModalWrapper } from '../ModalWrapper';

export interface ScoreModalPayload {
  type: modalModel.Modals.ScoreModal;
  data: null;
}

const interactButtons = {
  height: 'auto',
  width: '40%',
  border: BORDER_S,
  borderRadius: BORDER_RADIUS_S,
  fontSize: 16,
  cursor: 'pointer',
};

const UpdateButton = styled(Button)(({ theme }) => ({
  ...theme.typography.subtitle2,
  ...interactButtons,
  borderColor: theme.themeColors.colorBorder,
  padding: theme.spacing(0.5, 1.5),
  background: theme.themeColors.colorBackground,
}));

const MintButton = styled(Button)(({ theme }) => ({
  ...theme.typography.subtitle2,
  ...interactButtons,
  borderColor: theme.themeColors.colorBorder,
  padding: theme.spacing(0.5, 1.5),
  backgroundColor: theme.themeColors.colorDark,
  color: theme.themeColors.buttonContainedColor,

  '&:hover, &.active': {
    background: theme.themeColors.buttonContainedBackgroundHover,
    color: theme.themeColors.buttonContainedColorHover,
  },
}));

export const ScoreModal = forwardRef<HTMLElement, Modal>(() => {
  const dispatch = useDispatch();
  const { isCurrentUser, score, address } = useShallowSelector(profileModel.selectors.getProfile);
  const { address: userAddress, scoreMintedTokenId, authQR, credential, session } = useShallowSelector(userModel.selectors.getUser);
  const uiState = useShallowSelector(uiSelector);
  const isMintScoreLoading = checkRequestStatusEquality(uiState[userActions?.mintScore?.type]);
  const isMintScoreSuccess = checkRequestStatusEquality(uiState[userActions?.mintScore?.type], RequestStatus.SUCCESS);
  const { walletService } = useWalletConnectorContext();

  let mintButtonContent;

  if (isMintScoreLoading) mintButtonContent = 'minting...';
  else mintButtonContent = scoreMintedTokenId !== -1 ? 'token' : 'mint';

  useEffect(() => {
    dispatch(userModel.userActions.getScoreMintedTokenId());
  }, [isMintScoreSuccess]);

  const QRWrapper = styled(Stack)({
    '> svg': { margin: '0 auto' },
    mx: 'auto',
    width: '100%',
  });

  useEffect(() => {
    dispatch(userActions.getAuthQR());
  }, [])

  return (
    <ModalWrapper title="" font="secondary" fontWeight="normal" size="sm">
      <Stack height="100%" justifyContent="center">
        <Box
          component="img"
          width="90%"
          padding="4px"
          src={`https://api.relinkd.xyz/token?address=${address.toLowerCase()}`}
          mx="auto"
        />
      </Stack>
      <Box justifyContent="space-evenly" my={3} display="flex" width="70%" mx="auto">
        <UpdateButton
          variant="outlined"
          size="medium"
          onClick={() => {
            dispatch(profileActions.updateProfileScore());
          }}
        >
          update
        </UpdateButton>

        {isCurrentUser && (
          <MintButton
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={() => {
              if (scoreMintedTokenId !== -1) {
                window.open(
                  `https://opensea.io/assets/matic/0x90b7D42b7179753229196130B441A0401b34ed18/${scoreMintedTokenId}`,
                );
              } else {
                dispatch(userActions.mintScore({ web3Provider: walletService.Web3() }));
              }
            }}
          >
            {mintButtonContent}
          </MintButton>
        )}
      </Box>
      {/* <Stack height="100%" justifyContent="center">
        <Box
          component="img"
          width="75%"
          padding="4px"
          src="https://api.relinkd.xyz/auth"
          mx="auto"
        />
      </Stack> */}
      { isCurrentUser && <Stack height="100%" justifyContent="center" mx="auto">
        {(credential || authQR) && <QRWrapper>
          <QRCode value={credential || authQR} />
        </QRWrapper>}
        <Stack direction="row" justifyContent="center">
            <Typography paddingTop={1} align="center" color="#000">
              {credential ? 'Scan your credential' : 'Auth from polygon ID app'}
            </Typography>
          </Stack>
        <Button variant="contained" disabled={!!credential} sx={{ margin: "20px auto", padding: 1.6, height: 'fit-content' }} onClick={() => {
          dispatch(userActions.issueCredential({
            session,
            address: userAddress,
          }));
        }}> 
          Issue credential on PolygonID
        </Button>
      </Stack>}
      {!isMintScoreSuccess ? (
        <Stack spacing={2.5} mt={2} height="100%" width="70%" mx="auto">
          <Stack direction="row" justifyContent="center">
            <Typography align="center" color="#000">
              This Dynamic NFT reputation token represents your
              <span style={{ fontWeight: 'bold' }}> web3 nativity.</span>
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Typography align="center" color="#000">
              Token data will be dynamically updated once you update your &#123;r&#125;score.
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <>
          <Stack width="80%" mx="auto" mt={3}>
            <Typography
              variant="h1"
              lineHeight="30px"
              align="center"
              className="small"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="pre-wrap"
            >
              Congratulation!
            </Typography>
          </Stack>
          <Stack spacing={3.75} mt={3} mb={4} height="100%" width="70%" mx="auto">
            <Stack direction="row" justifyContent="center">
              <Typography align="center" color="#000">
                You just minted your Dynamic NFT reputation token!
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="center">
              <Typography align="center" color="#000">
                Now you can view it on{' '}
                <Link
                  href={`https://opensea.io/assets/matic/0x90b7D42b7179753229196130B441A0401b34ed18/${scoreMintedTokenId}`}
                  underline="none"
                  color={COLOR_ACCENT}
                  target="_blank"
                >
                  OpenSea
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
    </ModalWrapper>
  );
});

ScoreModal.displayName = 'ScoreModal';
