import { development, LensClient, production, PublicationTypes } from '@lens-protocol/client';
import { fetchProfileZKBadges, profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import { logger } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { profileActions } from '../reducer';

type resultLens = {
  items: [];
};

export function* getIsProfileFollowed({ type }: ReturnType<typeof profileActions.getIsProfileFollowed>) {
  yield* put(request(type));

  const { lens } = yield select(profileModel.selectors.getProfile);
  const { address } = yield select(userModel.selectors.getUser);

  const lensClient = new LensClient({
    environment: production,
  });

  try {
    const result: any[] = yield lensClient.profile.doesFollow({
      followInfos: [
        {
          followerAddress: address,
          profileId: lens.defaultProfile.id,
        },
      ],
    });

    console.log(result, 'does follow');

    if (result[0]) {
      yield* put(
        profileActions.updateProfileLens({
          ...lens,
          isFollowed: result[0].follows,
        }),
      );
    }

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getIsProfileFollowed', err);
  }
}

export default function* listener() {
  yield takeLatest(profileActions.getIsProfileFollowed.type, getIsProfileFollowed);
}
