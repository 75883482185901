import { COLOR_ACCENT_ALPHA, COLOR_BLACK, COLOR_PURPURE, COLOR_TEXTFIELD_LIGHT, COLOR_WH } from './colors.constants';
import {
  appColors,
  colorsAccordion,
  colorsBorder,
  colorsButton,
  colorsIconButton,
  colorsLayout,
  colorsPoint,
  colorsProgressBar,
  colorsRadioAndCheckbox,
  colorsSearch,
  colorsSlider,
  colorsSwitch,
  colorsTextField,
  colorsTypography,
  colorsUploadImage,
} from './components';

export const colorThemes = {
  background: COLOR_WH,
  backgroundAccent: COLOR_ACCENT_ALPHA,
  backgroundSecondary: COLOR_TEXTFIELD_LIGHT,
  mainColor: COLOR_BLACK,
  borderMain: COLOR_PURPURE,
  ...appColors,
  ...colorsButton,
  ...colorsIconButton,
  ...colorsRadioAndCheckbox,
  ...colorsTextField,
  ...colorsTypography,
  ...colorsLayout,
  ...colorsUploadImage,
  ...colorsSwitch,
  ...colorsAccordion,
  ...colorsSearch,

  ...colorsBorder,
  ...colorsPoint,
  ...colorsSlider,
  ...colorsProgressBar,
};
