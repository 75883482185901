import { forwardRef, PropsWithChildren } from 'react';
import { Modal, modalModel } from 'entities/modal';

import { ModalWrapper } from '../ModalWrapper';

export interface BaseModalPayload {
  type: modalModel.Modals.BaseModal;
  data: { title: string } & PropsWithChildren;
}

export const BaseModal = forwardRef<HTMLElement, Modal>(({ data: { title, children } }) => {
  return <ModalWrapper title={title}>{children}</ModalWrapper>;
});

BaseModal.displayName = 'BaseModal';
