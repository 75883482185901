import { ComponentsProps, Theme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export type ThemeForTypography = Pick<Theme, 'themeColors' | 'breakpoints'>;

export enum FontWeights {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
}

export enum FontFamilies {
  primary = '"Chakra Petch", sans-serif',
  secondary = '"Alpha Lyrae", sans-serif',
  tertiary = '"Inter", sans-serif',
  quaternary = '"Redaction 20", sans-serif',
}

export const styleVariations = {
  '&.center': {
    textAlign: 'center',
  },
  '&.capitalize': {
    textTransform: 'capitalize',
  },
  '&.uppercase': {
    textTransform: 'uppercase',
  },
  '&.ellipsis': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

const familyVariations = {
  '&.primary': {
    fontFamily: FontFamilies.primary,
  },
  '&.secondary': {
    fontFamily: FontFamilies.secondary,
  },
  '&.tertiary': {
    fontFamily: FontFamilies.tertiary,
  },
};

const weightVariants = {
  '&.regular': {
    fontWeight: FontWeights.Regular,
  },
  '&.medium': {
    fontWeight: FontWeights.Medium,
  },
  '&.semi-bold': {
    fontWeight: FontWeights.SemiBold,
  },
};

export const getColorVariations = (theme: Theme) => {
  return {
    '&.color-secondary': {
      color: theme.themeColors.colorTypographySecondary,
    },
    '&.color-primary': {
      color: theme.themeColors.colorTypographyPrimary,
    },
    '&.color-accent': {
      color: theme.themeColors.colorAccent,
    },
    '&.color-error': {
      color: theme.themeColors.colorRed,
    },
  };
};

export const getTypographyOptions = (theme: Theme): TypographyOptions => ({
  allVariants: {
    textTransform: 'none',
    color: theme.themeColors.colorTypographyPrimary,
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.Medium,
    textAlign: 'left',
    fontStyle: 'normal',
    letterSpacing: 'unset',
    ...getColorVariations(theme),
    ...styleVariations,
    ...familyVariations,
    ...weightVariants,
  },

  h1: {
    fontSize: 28,
    lineHeight: '28px',
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.SemiBold,
    '&.small': {
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
    },
  },

  h2: {
    fontSize: 22,
    lineHeight: '24px',
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.SemiBold,
  },

  h3: {
    fontSize: 20,
    lineHeight: '24px',
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.SemiBold,
  },

  h4: {
    fontSize: 18,
    lineHeight: '20px',
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.SemiBold,
  },

  h5: {
    fontSize: 16,
    lineHeight: '20px',
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.SemiBold,
  },

  body1: {
    fontSize: 18,
    lineHeight: '20px',
    color: theme.themeColors.colorTypographySecondary,
    fontWeight: FontWeights.Regular,
    fontFamily: FontFamilies.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    '&.medium': {
      fontSize: 16,
    },
    '&.small': {
      fontSize: 14,
    },
  },

  body2: {
    fontSize: 22,
    lineHeight: '24px',
    color: theme.themeColors.colorTypographySecondary,
    fontWeight: FontWeights.Regular,
    fontFamily: FontFamilies.primary,

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      lineHeight: '20px',
    },
  },

  subtitle2: {
    fontSize: 14,
    lineHeight: '19px',
    fontWeight: FontWeights.Medium,
    fontFamily: FontFamilies.secondary,
    letterSpacing: 'unset',
  },

  subtitle1: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.themeColors.colorTypographySecondary,
    fontWeight: FontWeights.Medium,
    letterSpacing: 'unset',
  },

  button: {
    fontSize: 18,
    lineHeight: '20px',
    fontWeight: FontWeights.Medium,
    fontFamily: FontFamilies.primary,
  },
});

export const getTypographyDefaultProps = (): ComponentsProps['MuiTypography'] => ({
  variant: 'body1',
});
