import { userModel } from 'entities/user';
import { ContractsNames, createContract, getContractDataByItsName, logger, ProfileAbi } from 'shared';
import { error, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { userActions, UserState } from '../reducer';
import { BlockDataFromBlockchain, UserBlockData } from '../types';

import { getProfileContractAddressSaga } from './getProfileContractAddress';
import { getUserDomainSaga } from './getUserDomain';

export function* getUserInfoSaga({ type }: ReturnType<typeof userActions.getUserInfo>) {
  yield* put(request(type));
  const { chainType }: Pick<UserState, 'chainType'> = yield select(userModel.selectors.selectUserWeb3Info);
  const [profileAbi] = getContractDataByItsName(ContractsNames.Profile, chainType);
  try {
    yield* call(getUserDomainSaga, {
      type: userActions.getUserDomain.type,
      payload: undefined,
    });

    yield* call(getProfileContractAddressSaga, {
      type: userActions.getProfileContractAddress.type,
      payload: undefined,
    });

    const { profileContractAddress }: Pick<UserState, 'profileContractAddress'> = yield select(
      userModel.selectors.getUser,
    );
    if (profileContractAddress) {
      const profileContract: ProfileAbi = yield createContract(profileAbi, profileContractAddress, chainType);
      const {
        0: style,
        1: blocksData,
        2: blocksOrder,
        3: [name, avatarLink, attributes],
      } = yield* call(profileContract.methods.getProfile().call);

      const formatedBlocks = (blocksData as unknown as BlockDataFromBlockchain[]).map(
        ({ blockPayload, ...data }, index) => ({
          blockPayload: JSON.parse(blockPayload),
          blockId: index,
          ...data,
        }),
      ) as unknown as UserBlockData[];

      const links = formatedBlocks.filter(({ blockType }) => blockType !== 'Banner' && blockType !== 'Bio');
      const bannerData = formatedBlocks.find(({ blockType }) => blockType === 'Banner');
      const bioData = formatedBlocks.find(({ blockType }) => blockType === 'Bio');

      yield* put(
        userActions.updateUserState({
          name,
          avatar: avatarLink,
          banner: bannerData?.blockPayload?.contents || '',
          bannerBlockData: bannerData || null,
          bio: bioData?.blockPayload?.contents || '',
          bioBlockData: bioData || null,
          links,
        }),
      );
    }

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getUserInfo', err);
  }
}

export default function* listener() {
  yield takeLatest(userActions.getUserInfo.type, getUserInfoSaga);
}
