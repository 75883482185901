import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { userModel } from 'entities/user';
import { userActions } from 'entities/user/model';
import { CreateProfileHeader, ProfileForm, ProfileFormFields, UploadProfileImages } from 'features';
import {
  amplitudeInstance,
  checkRequestStatusEquality,
  getIpfsLink,
  RequestStatus,
  routes,
  uiSelector,
  useShallowSelector,
  useWalletConnectorContext,
} from 'shared';
import { Layout } from 'widgets';

export const CreateProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { walletService } = useWalletConnectorContext();
  const uiState = useShallowSelector(uiSelector);
  const {
    name,
    domain: { domainTokenId },
    links,
  } = useShallowSelector(userModel.selectors.getUser);

  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [avatarLink, setAvatarLink] = useState('');
  const [bannerLink, setBannerLink] = useState('');

  const isGetUserDomainLoading = checkRequestStatusEquality(uiState[userActions?.getUserDomain?.type]);
  const isCreateProfileLoading = checkRequestStatusEquality(uiState[userActions?.createProfile?.type]);
  const isCreateProfileSuccess = checkRequestStatusEquality(
    uiState[userActions?.createProfile?.type],
    RequestStatus.SUCCESS,
  );

  const handleChangeNextDisabled = (value: boolean) => setIsNextDisabled(value);

  const handleBack = () => {
    navigate(routes.domain.getPathname('initial'));
  };

  const handleNext = ({ username, bio }: ProfileFormFields) => {
    dispatch(
      userActions.createProfile({
        name: username,
        bio,
        web3Provider: walletService.Web3(),
        tokenId: domainTokenId || 0,
        withDomain: domainTokenId !== null,
        avatar: avatarLink,
        banner: bannerLink,
      }),
    );
    amplitudeInstance.track('name_add', { name: username });
    if (bio) {
      amplitudeInstance.track('bio_add', { bio });
    }
    if (avatarLink) {
      amplitudeInstance.track('profilephoto_add', { photo_source: avatarLink });
    }
    if (bannerLink) {
      amplitudeInstance.track('background_add', { photo_source: bannerLink });
    }
    amplitudeInstance.track('profile_created');
  };

  const uploadAvatar = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const { ipfsLink } = await getIpfsLink(formData);
    setAvatarLink(ipfsLink);
  };
  const uploadBanner = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const { ipfsLink } = await getIpfsLink(formData);
    setBannerLink(ipfsLink);
  };

  useEffect(() => {
    dispatch(userActions.getUserDomain());
  }, [dispatch]);

  useEffect(() => {
    if (name) navigate(routes.socialLinks.path);
  }, [navigate, name]);

  useEffect(() => {
    if (isCreateProfileSuccess) {
      amplitudeInstance.track('profile_created');
    }
  }, [isCreateProfileSuccess]);

  return (
    <Layout
      footerChildren={
        <Stack direction="row" p={2.5}>
          <Button variant="text" onClick={handleBack} disabled={!!domainTokenId}>
            Back
          </Button>
          <Button
            color="primary"
            type="submit"
            form="profile-form"
            sx={{ ml: 'auto', minWidth: 98 }}
            disabled={isNextDisabled || isGetUserDomainLoading || isCreateProfileLoading}
            onClick={() => amplitudeInstance.track('create_profile_after_viewing')}
          >
            {isGetUserDomainLoading || isCreateProfileLoading ? (
              <CircularProgress size={24} sx={{ color: theme.themeColors.background }} />
            ) : (
              'Build profile'
            )}
          </Button>
        </Stack>
      }
    >
      <CreateProfileHeader />
      <Typography variant="h1" mt={{ xs: 5.5, md: 11.25 }} mb={7.5}>
        Let&apos;s prepare your public profile
      </Typography>
      <UploadProfileImages withEdit onUploadAvatar={uploadAvatar} onUploadBanner={uploadBanner} />
      <Box mt={{ xs: 3.75, md: 7.5 }}>
        <ProfileForm changeIsSubmitDisabled={handleChangeNextDisabled} onSubmit={handleNext} />
      </Box>
    </Layout>
  );
};
