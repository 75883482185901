import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  styled,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { profileModel } from 'entities/profile';
import { profileActions } from 'entities/profile/model';
import { userModel } from 'entities/user';
import { BORDER_RADIUS_XXS, BORDER_S, COLOR_STROKE, levelsImages, useShallowSelector, useWeb3Provider } from 'shared';

import { cred, degenScore, gitcoin, nomisEth, nomisPolygon, relinkd } from '../assets';
import { gitcoinSupply } from '../lib/gitcoinSupply';

export const ScoreCard = styled(Stack)(({ theme }) => ({
  border: BORDER_S,
  borderColor: theme.themeColors.colorBorder,
  borderRadius: '6px',
  cursor: 'pointer',
  flexBasis: 'calc(50% - 16px)',
  margin: '8px',

  '&:hover': {
    borderColor: theme.themeColors.colorBorderHover,
    opacity: 1,
  },

  [theme.breakpoints.down('sm')]: {
    flexBasis: '100%',
    backgroundColor: theme.themeColors.accordionBackground,
  },
}));

type ScoreItemProps = {
  header: string;
  text: string;
  score: string | number;
  image: string;
  action?: any;
  supply?: any;
};

const ScoreItem = ({ header, text, score, image, action, supply }: ScoreItemProps) => {
  const theme = useTheme();
  const { isCurrentUser } = useShallowSelector(profileModel.selectors.getProfile);

  if (!isCurrentUser && Number(score) < 0) return null;

  return (
    <ScoreCard
      sx={{ opacity: Number(score) >= 0 ? 1 : 0.5 }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p="6px"
      height={120}
      onClick={() => {
        if (action && Number(score) >= 0) action();
        if (supply && Number(score) < 0) supply();
      }}
    >
      <Stack
        height={75}
        width={75}
        sx={{ background: theme.themeColors.backgroundSecondary, borderRadius: '6px' }}
        justifyContent="center"
        alignItems="center"
      >
        <Box component="img" height="60%" src={image} />
      </Stack>
      <Stack width="50%" height="70%" alignItems="flex-start">
        <Typography className="secondary" fontSize={14} color="black">
          {header}
        </Typography>
        <Typography pt={1} fontSize={12} lineHeight="14px">
          {text}
        </Typography>
      </Stack>
      <Stack alignItems="flex-start" height="70%">
        <Typography variant="h5" className="secondary" color={COLOR_STROKE}>
          {Number(score) >= 0 ? Math.floor(Number(score)) : '-'}
        </Typography>
      </Stack>
    </ScoreCard>
  );
};

export const ScoresGallery = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { scores, address } = useShallowSelector(profileModel.selectors.getProfile);
  const dispatch = useDispatch();
  const { address: userAddress } = useShallowSelector(userModel.selectors.getUser);
  const web3Provider = useWeb3Provider();
  const { relinkd: score } = scores;

  useEffect(() => {
    dispatch(
      profileActions.updateProfileState({
        scores: { ...scores, degenScore: -1, gitcoin: -1, nomis: { polygon: -1, ethereum: -1 }, cred: -1 },
      }),
    );

    dispatch(profileActions.getProfileDegenScore());
    dispatch(profileActions.getProfileGitcoinScore());
    dispatch(profileActions.getProfileNomisScore());
    dispatch(profileActions.getProfileCredScore());
  }, [address]);

  return (
    <Accordion className={isDownMd ? 'secondary' : ''}>
      <AccordionSummary className="main">Scores</AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" sx={{ flexWrap: { xs: 'wrap', sm: 'wrap' } }}>
          <ScoreItem
            header="{r}score"
            text="This is {r}score that represents your web3 nativity"
            score={scores.relinkd}
            image={relinkd}
            action={() => {
              dispatch(
                modalModel.modalActions.openModal({
                  type: Modals.ScoreModal,
                  data: null,
                }),
              );
            }}
          />
          <ScoreItem
            header="Gitcoin scorer"
            text="To show your score you need to sign suggestion"
            score={scores.gitcoin}
            image={gitcoin}
            supply={() => {
              gitcoinSupply(web3Provider, userAddress, dispatch);
            }}
          />
          <ScoreItem
            header="Degen score"
            text="To get you Degen score you need to mint Beacon token"
            score={scores.degenScore}
            image={degenScore}
            // supply={() => {window.open('https://degenscore.com/')}}
            // action={() => {window.open(`https://degenscore.com/beacon/${address}`)}}
          />
          <ScoreItem
            header="Cred score"
            text="To show more scores options make your Cred profile"
            score={scores.cred}
            image={cred}
            // action={() => {window.open('https://app.credprotocol.com/')}}
          />
          <ScoreItem
            header="Nomis Eth score"
            text="To Mint your Nomis score go to Nomis app"
            score={scores.nomis?.ethereum}
            image={nomisEth}
            // action={() => {window.open('https://nomis.cc/multichain')}}
          />
          <ScoreItem
            header="Nomis Polygon score"
            text="To Mint your Nomis score go to Nomis app"
            score={scores.nomis?.polygon}
            image={nomisPolygon}
            // action={() => {window.open('https://nomis.cc/multichain')}}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
