import { ReactComponent as brightid } from './brightid.svg';
import { ReactComponent as coinbase } from './coinbase.svg';
import { ReactComponent as ens } from './ens.svg';
import { ReactComponent as eth } from './eth.svg';
import { ReactComponent as gitpoap } from './gitpoap.svg';
import { ReactComponent as gnosisSafe } from './gnosis_safe.svg';
import { ReactComponent as gtc } from './gtc.svg';
import { ReactComponent as gtcStaking } from './gtc_staking.svg';
import { ReactComponent as nft } from './nft.svg';
import { ReactComponent as poap } from './poap.svg';
import { ReactComponent as poh } from './poh.svg';
import { ReactComponent as polygon } from './polygon.svg';
import { ReactComponent as relinkd } from './relinkd.svg';
import { ReactComponent as snapshot } from './snapshot.svg';
import { ReactComponent as zksync } from './zksync.svg';

export default {
  eth,
  gitpoap,
  ens,
  'gnosis-safe': gnosisSafe,
  brightid,
  coinbase,
  'gtc-staking': gtcStaking,
  gtc,
  nft,
  poap,
  poh,
  snapshot,
  zksync,
  polygon,
  relinkd,
};

export { default as ethSymbol } from './eth.png';
