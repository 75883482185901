import { forwardRef } from 'react';
import QRCode from 'react-qr-code';
import { Box, Stack, styled } from '@mui/material';
import { Modal, modalModel } from 'entities/modal';
import { profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import { routes, useShallowSelector } from 'shared';

import { ModalWrapper } from '../ModalWrapper';

export interface QRModalPayload {
  type: modalModel.Modals.QRModal;
  data: null;
}

export const QRModal = forwardRef<HTMLElement, Modal>(() => {
  const { name, domainName, address } = useShallowSelector(profileModel.selectors.getProfile);

  const path = `app.relinkd.xyz${routes.profile.getPathname(domainName || address)}`; // TODO domain to config

  const QRWrapper = styled(Stack)({
    '> svg': { margin: '0 auto' },
    mx: 'auto',
    width: '100%',
  });

  return (
    <ModalWrapper title={name} size="sm">
      <Stack mb={6} mt={{ xs: 9, md: 2 }}>
        <QRWrapper>
          <QRCode value={path} />
        </QRWrapper>
      </Stack>
    </ModalWrapper>
  );
});

QRModal.displayName = 'QRModal';
