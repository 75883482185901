export const routes = {
  connect: {
    path: '/p/connect',
  },
  agreement: {
    path: '/p/agreement',
  },
  domain: {
    path: '/p/domain/:type',
    getPathname: (type: string) => `/p/domain/${type}`,
  },
  socialLinks: {
    path: '/p/social-links',
  },
  createProfile: {
    path: '/p/create-profile',
  },
  congrats: {
    path: '/p/congrats',
  },
  profile: {
    path: '/:domain',
    getPathname: (domain: string) => `/${domain}`,
  },
  noWhitelisted: {
    path: '/p/whitelist',
  },
  noProfile: {
    path: '/p/notfound',
  },
};
