import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { Guild } from 'entities/profile';
import { CredentialCard } from 'features/credential-accordion'; // To shared TODO
import { AccordionWrapper, Card, COLOR_ACCENT, Icon } from 'shared';

type GuildAccordionProps = {
  guilds: Guild[];
  label: string;
  isExpanded: boolean;
  onToggle: (label: string) => void;
};

export const GuildAccordion = ({ label, guilds: allGuilds, isExpanded, onToggle }: GuildAccordionProps) => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [guilds, setGuilds] = useState(allGuilds.slice(0, isDownSm ? 3 : 4));

  const dispatch = useDispatch();

  const handleOpenGuildModal = (id: number) => {
    dispatch(
      modalModel.modalActions.openModal({
        type: Modals.GuildModal,
        data: {
          guildId: id,
        },
      }),
    );
  };

  useEffect(() => {
    setGuilds(allGuilds.slice(0, isDownSm ? 3 : 4));
  }, [allGuilds]);

  const handleViewAll = () => {
    setGuilds(allGuilds);
  };

  return (
    <AccordionWrapper
      label={label}
      credentials={allGuilds}
      handleViewAll={handleViewAll}
      onToggle={onToggle}
      credentialsSliced={guilds}
      isExpanded={isExpanded}
    >
      {guilds.map(({ id, name, description, memberCount, imageUrl, setRoles }, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          key={id}
          onClick={() => {
            handleOpenGuildModal(index);
          }}
        >
          <CredentialCard>
            <Box component="img" src={imageUrl} sx={{ width: 72, height: 72, objectFit: 'cover' }} />
            <Stack overflow="hidden" width="50%">
              <Typography variant="subtitle2" className="ellipsis" fontSize={16}>
                {name}
              </Typography>
              <Typography className="small ellipsis">{description}</Typography>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ svg: { path: { stroke: COLOR_ACCENT } } }}>
                <Typography className="small color-accent">
                  {memberCount.toLocaleString('en-US').replace(/,/g, '.')} members
                </Typography>
              </Stack>
            </Stack>
            {/* <Stack style={{width: '50%'}}>
                    {setRoles.map((role: any) => {
                      return <Typography align="center" key={role.id}>{role.name}</Typography>
                    })}
                </Stack> */}
          </CredentialCard>
        </Grid>
      ))}
    </AccordionWrapper>
  );
};
