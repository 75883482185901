import { all, call } from 'redux-saga/effects';

import followProfileLens from './followProfileLens';
import getEnsNames from './getEnsNames';
import getIsProfileFollowed from './getIsProfileFollowed';
import getProfileContractAddress from './getProfileContractAddress';
import getProfileCredScore from './getProfileCredScore';
import getProfileDegenScore from './getProfileDegenScore';
import getProfileGitcoin from './getProfileGitcoin';
import getProfileGitcoinScore from './getProfileGitcoinScore';
import getProfileGuilds from './getProfileGuilds';
import getProfileInfo from './getProfileInfo';
import getProfileLens from './getProfileLens';
import getProfileLensPosts from './getProfileLensPosts';
import getProfileNFTs from './getProfileNFTs';
import getProfileNomisScore from './getProfileNomisScore';
import getProfilePoaps from './getProfilePoaps';
import getProfileScore from './getProfileScore';
import getProfileTokens from './getProfileTokens';
import getProfileZKBadges from './getProfileZKBadges';
import updateProfileScore from './updateProfileScore';
import updateProfileToken from './updateProfileToken';

export function* profileSagas() {
  yield all(
    [
      getEnsNames,
      getProfilePoaps,
      getProfileLens,
      getProfileGitcoin,
      getProfileZKBadges,
      getProfileGuilds,
      getProfileInfo,
      getProfileContractAddress,
      getProfileNFTs,
      getProfileTokens,
      getProfileLensPosts,
      followProfileLens,
      getIsProfileFollowed,
      getProfileDegenScore,
      getProfileGitcoinScore,
      getProfileNomisScore,
      getProfileCredScore,
      getProfileScore,
      updateProfileScore,
      updateProfileToken,
    ].map(call),
  );
}
