import { userModel } from 'entities/user';
import { userActions, UserState } from 'entities/user/model';
import { ContractsNames, getContractDataByItsName, getToastMessage, logger, ProfileAbi } from 'shared';
import { error, getMaxGas, request, success } from 'shared/api';
import { call, put, select, takeLatest } from 'typed-redux-saga';

export function* updateBlocksSaga({
  type,
  payload: { web3Provider, blocksArray },
}: ReturnType<typeof userActions.updateBlocks>) {
  yield* put(request(type));

  const {
    chainType,
    address: userAddress,
    profileContractAddress,
  }: Pick<UserState, 'chainType' | 'address' | 'profileContractAddress'> = yield select(userModel.selectors.getUser);

  const [profileAbi] = getContractDataByItsName(ContractsNames.Profile, chainType);

  try {
    const profileContract: ProfileAbi = yield new web3Provider.eth.Contract(profileAbi, profileContractAddress);

    const blocksIds = blocksArray.map(({ blockId }) => blockId);
    const blocksPayloads = blocksArray.map(({ blockPayload }) => JSON.stringify(blockPayload));
    const blocksDescriptions = blocksArray.map(({ blockDescription }) => blockDescription);

    const { maxFeePerGas, maxPriorityFeePerGas } = yield* call(getMaxGas);
    const { transactionHash } = yield* call(
      profileContract.methods.updateBlock(blocksIds, blocksPayloads, blocksDescriptions).send,
      {
        from: userAddress,
        to: profileContractAddress,
        maxFeePerGas,
        maxPriorityFeePerGas,
      },
    );

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    getToastMessage('error', 'Something went wrong');
    logger('updateBlocks', err);
    throw new Error('Update failed');
  }
}

export default function* listener() {
  yield takeLatest(userActions.updateBlocks.type, updateBlocksSaga);
}
