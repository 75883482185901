import { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Link, Stack, styled, Typography } from '@mui/material';
import { Modal, modalModel } from 'entities/modal';
import { userModel } from 'entities/user';
import { userActions } from 'entities/user/model';
import {
  BORDER_RADIUS_S,
  BORDER_S,
  checkRequestStatusEquality,
  COLOR_ACCENT,
  contracts,
  RequestStatus,
  uiSelector,
  useShallowSelector,
  useWalletConnectorContext,
} from 'shared';

import { ModalWrapper } from '../ModalWrapper';

export interface MintModalPayload {
  type: modalModel.Modals.MintModal;
  data: null;
}

const interactButtons = {
  height: 'auto',
  width: '40%',
  border: BORDER_S,
  borderRadius: BORDER_RADIUS_S,
  fontSize: 16,
  cursor: 'pointer',
};

const MintButton = styled(Button)(({ theme }) => ({
  ...theme.typography.subtitle2,
  ...interactButtons,
  borderColor: theme.themeColors.colorBorder,
  padding: theme.spacing(0.5, 1.5),
  backgroundColor: theme.themeColors.colorDark,
  color: theme.themeColors.buttonContainedColor,

  '&:hover, &.active': {
    background: theme.themeColors.buttonContainedBackgroundHover,
    color: theme.themeColors.buttonContainedColorHover,
  },
}));

export const MintModal = forwardRef<HTMLElement, Modal>(() => {
  const dispatch = useDispatch();
  const { address, scoreMintedTokenId } = useShallowSelector(userModel.selectors.getUser);

  const uiState = useShallowSelector(uiSelector);
  const isMintScoreLoading = checkRequestStatusEquality(uiState[userActions?.mintScore?.type]);
  const isMintScoreSuccess = checkRequestStatusEquality(uiState[userActions?.mintScore?.type], RequestStatus.SUCCESS);

  useEffect(() => {
    dispatch(userModel.userActions.getScoreMintedTokenId());
  }, [isMintScoreSuccess]);

  const { walletService } = useWalletConnectorContext();

  return (
    <ModalWrapper title="NFT PREVIEW" font="secondary" fontWeight="normal" size="sm">
      <Stack height="100%" justifyContent="center" sx={{ mt: 2 }}>
        <Box
          component="img"
          width="90%"
          padding="4px"
          src={`https://api.relinkd.xyz/token?address=${address.toLowerCase()}`}
          mx="auto"
        />
      </Stack>
      {!isMintScoreSuccess && (
        <Box justifyContent="space-evenly" my={3} display="flex" width="70%" mx="auto">
          <MintButton
            variant="outlined"
            size="medium"
            color="secondary"
            onClick={() => {
              dispatch(userActions.mintScore({ web3Provider: walletService.Web3() }));
            }}
          >
            {!isMintScoreLoading ? 'mint' : 'minting...'}
          </MintButton>
        </Box>
      )}
      {!isMintScoreSuccess ? (
        <>
          <Stack width="80%" mx="auto">
            <Typography
              align="center"
              className="small"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="pre-wrap"
            >
              your live nft reputation proof that updates dynamically as you progress
            </Typography>
          </Stack>
          <Stack spacing={2.5} mt={7} height="100%" width="70%" mx="auto">
            <Stack direction="row" justifyContent="center">
              <Typography align="center" color="#000">
                This Dynamic NFT reputation token represents your
                <span style={{ fontWeight: 'bold' }}> web3 nativity.</span>
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="center">
              <Typography align="center" color="#000">
                Token data will be dynamically updated once you update your &#123;r&#125;score.
              </Typography>
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          <Stack width="80%" mx="auto" mt={8}>
            <Typography
              variant="h1"
              lineHeight="30px"
              align="center"
              className="small"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="pre-wrap"
            >
              Congratulation!
            </Typography>
          </Stack>
          <Stack spacing={3.75} mt={3} mb={4} height="100%" width="70%" mx="auto">
            <Stack direction="row" justifyContent="center">
              <Typography align="center" color="#000">
                You just minted your Dynamic NFT reputation token!
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="center">
              <Typography align="center" color="#000">
                Now you can view it on{' '}
                <Link
                  href={`https://opensea.io/assets/matic/0x90b7D42b7179753229196130B441A0401b34ed18/${scoreMintedTokenId}`}
                  underline="none"
                  color={COLOR_ACCENT}
                  target="_blank"
                >
                  OpenSea
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
    </ModalWrapper>
  );
});

MintModal.displayName = 'MintModal';
