import { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Modal, modalModel } from 'entities/modal';
import { profileModel } from 'entities/profile';
import { COLOR_ACCENT, Icon, useShallowSelector } from 'shared';

import { ModalWrapper } from '../ModalWrapper';

export interface NFTModalPayload {
  type: modalModel.Modals.NFTModal;
  data: {
    nftId: number;
  };
}

export const NFTModal = forwardRef<HTMLElement, Modal>(({ data: { nftId } }) => {
  const { assets }: any = useShallowSelector(profileModel.selectors.getProfile);

  const selectedNFT = assets.nfts[nftId];

  return (
    <ModalWrapper title="NFT Details" size="md">
      <Stack mb={6} mt={{ xs: 9, md: 2 }}>
        <Stack spacing={3.75}>
          <Stack direction="row" justifyContent="center" width="100%">
            <Box
              component="img"
              width="50%"
              src={
                selectedNFT.rawMetadata.image.startsWith('ipfs://')
                  ? `https://ipfs.io/ipfs/${selectedNFT.rawMetadata.image.slice(7)}`
                  : selectedNFT.rawMetadata.image
              }
            />
          </Stack>
          {/* TODO gateway ipfs to config */}
        </Stack>
        <Stack spacing={3.75} mt={14} height="100%">
          <Stack direction="row" justifyContent="center">
            <Typography variant="h2" align="center">
              {selectedNFT.title}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Typography align="center">{selectedNFT.description}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </ModalWrapper>
  );
});

NFTModal.displayName = 'NFTModal';
