import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { modalModel } from 'entities/modal';
import { Modals } from 'entities/modal/model';
import { userModel } from 'entities/user';
import { userActions } from 'entities/user/model';
import { BlockData } from 'entities/user/model/types';
import { AddLinksForm, allSocialFieldsData, CreateProfileHeader } from 'features';
import {
  amplitudeInstance,
  checkRequestStatusEquality,
  Icon,
  logger,
  RequestStatus,
  routes,
  SocialField,
  Socials,
  TextfieldWithLabel,
  uiSelector,
  useShallowSelector,
  useWalletConnectorContext,
} from 'shared';
import { Layout } from 'widgets';

import { LinksFormFields, useValidateForm } from './config';

export const SocialLinksPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uiState = useShallowSelector(uiSelector);
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { walletService } = useWalletConnectorContext();
  const {
    links: existedLinks,
    address,
    domain: { domainName },
  } = useShallowSelector(userModel.selectors.getUser);

  const { register, fields, append, remove, handleSubmit } = useValidateForm();

  const isAddLinksLoading = checkRequestStatusEquality(uiState[userActions.addBlock?.type]);
  const isAddLinksSuccess = checkRequestStatusEquality(uiState[userActions.addBlock?.type], RequestStatus.SUCCESS);

  const handleBack = () => {
    navigate(routes.createProfile.path);
  };

  const handleNext = ({ links }: LinksFormFields) => {
    const filledLinksFields = links.filter(({ value }) => !!value);

    if (filledLinksFields.length) {
      const linksBlockData: BlockData[] = filledLinksFields.map(({ name, value, label }) => ({
        blockType: name,
        blockPayload: { contents: value },
        blockDescription: label,
      }));

      dispatch(
        userActions.addBlock({
          blocksArray: linksBlockData,
          web3Provider: walletService.Web3(),
          withGetUserInfoOnSuccess: true,
        }),
      );
      filledLinksFields.map(({ name }) => amplitudeInstance.track('link_add', { link_type: name.toLowerCase() }));

      logger('blockchain data', linksBlockData);
    } else {
      navigate(routes.congrats.path);
    }

    logger('form data', links);
  };

  const handleChooseSocial = (newSocials: SocialField[]) => {
    newSocials.forEach(({ name, label, link, placeholder }) => {
      append({ name, value: '', label, placeholder, linkUrl: link });
    });
    dispatch(modalModel.modalActions.closeModal());
  };

  const handleOpenModal = () => {
    dispatch(
      modalModel.modalActions.openModal({
        type: Modals.LinksModal,
        data: {
          children: <AddLinksForm onSave={handleChooseSocial} />,
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(userActions.getProfileContractAddress());
    return () => {
      dispatch(modalModel.modalActions.closeModal());
    };
  }, [dispatch]);

  useEffect(() => {
    if (existedLinks.length) {
      if (isAddLinksSuccess) {
        navigate(routes.congrats.path);
      } else {
        navigate(routes.profile.getPathname(domainName || address));
      }
    }
  }, [isAddLinksSuccess, existedLinks, navigate, domainName, address]);

  return (
    <Layout
      footerChildren={
        <Stack direction="row" p={{ xs: 1, sm: 2.5 }} pt={{ xs: 0, sm: 2.5 }}>
          <Button variant="text" onClick={handleBack} disabled>
            Back
          </Button>
          <Button
            disabled={isAddLinksLoading}
            color="primary"
            sx={{ ml: 'auto', minWidth: 98 }}
            type="submit"
            form="links-form"
          >
            {isAddLinksLoading ? <CircularProgress size={24} sx={{ color: theme.themeColors.background }} /> : 'Next'}
          </Button>
        </Stack>
      }
    >
      <CreateProfileHeader />
      <Typography variant="h1" mt={{ xs: 5.5, md: 11.25 }} mb={7.5}>
        Let&apos;s get in touch
      </Typography>
      <Stack my={4} component="form" id="links-form" onSubmit={handleSubmit(handleNext)}>
        {fields.map((item, index) => (
          <Box key={item.id}>
            {item.name === Socials.Custom && (
              <TextfieldWithLabel
                label={item.name}
                {...register(`links.${index}.label`)}
                disabled={isAddLinksLoading}
                placeholder="Title"
                isOptional
                sx={{ mb: 2 }}
              />
            )}

            <TextfieldWithLabel
              {...register(`links.${index}.value`)}
              label={item.label}
              disabled={isAddLinksLoading}
              placeholder={item.linkUrl ? '' : item.placeholder}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    variant="standard"
                    sx={{ gap: { xs: 0, md: 1 }, mr: 0 }}
                    disablePointerEvents
                  >
                    <Icon
                      type={allSocialFieldsData.find(({ name }) => name === item.name)?.icon || 'smile'}
                      size={32}
                    />
                    <Typography variant="body2" ml={{ xs: 2, md: 2.75 }}>
                      {item.linkUrl.length > 21 && isDownSm ? `...${item.linkUrl.slice(21)}` : item.linkUrl}
                    </Typography>
                  </InputAdornment>
                ),
                endAdornment: index > 1 && (
                  <IconButton onClick={() => remove(index)}>
                    <Icon type="delete" />
                  </IconButton>
                ),
              }}
              isOptional
              sx={{ mb: 4 }}
            />
          </Box>
        ))}
      </Stack>
      <Button
        variant="outlined"
        fullWidth
        startIcon={<Icon type="plus" />}
        onClick={handleOpenModal}
        className="stroke-color"
      >
        Add another link
      </Button>
    </Layout>
  );
};
