import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, styled } from '@mui/material';
import { profileModel } from 'entities/profile';
import { profileActions } from 'entities/profile/model';
import { userModel } from 'entities/user';
import { userActions } from 'entities/user/model';
import {
  AddressBlock,
  AssetsBlock,
  NFTsBlock,
  ProfileActionButtons,
  PlugBlock,
  ProfileDescription,
  ProfileLinksBlock,
  SocialsBlock,
  UploadProfileImages,
} from 'features';
import { ScoresGallery } from 'features/scores-gallery';
import {
  amplitudeInstance,
  checkRequestStatusEquality,
  routes,
  uiSelector,
  useShallowSelector,
  useWalletConnectorContext,
} from 'shared';
import { Layout, LayoutBackground, SkillsSection } from 'widgets';


const WhiteButton = styled(Button)(({ theme }) => ({
  background: theme.themeColors.backgroundSecondary,
  color: theme.themeColors.colorTypographyPrimary,
}));

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { domain } = useParams();
  const { walletService } = useWalletConnectorContext();
  const uiState = useShallowSelector(uiSelector);
  const { address } = useShallowSelector(userModel.selectors.getUser);
  const {
    profileContractAddress,
    address: profileAddress,
    lens,
  } = useShallowSelector(profileModel.selectors.getProfile);
  const { isCurrentUser } = useShallowSelector(profileModel.selectors.getProfile);

  const isChangeAvatarLoading = checkRequestStatusEquality(uiState[userActions.changeAvatar?.type]);
  const isChangeBannerLoading = checkRequestStatusEquality(uiState[userActions.changeBanner?.type]);
  const isProfileContractAddressLoading = checkRequestStatusEquality(
    uiState[profileActions.getProfileContractAddress?.type],
  );
  const isProfileInfoLoading = checkRequestStatusEquality(uiState[profileActions.getProfileInfo?.type]);

  const changeAvatar = (file: File) => {
    dispatch(userModel.userActions.changeAvatar({ fileImg: file, web3Provider: walletService.Web3() }));
  };

  const changeBanner = (file: File) => {
    dispatch(userModel.userActions.changeBanner({ fileImg: file, web3Provider: walletService.Web3() }));
  };

  useEffect(() => {
    dispatch(profileActions.getProfileInfo({ name: domain || '' }));
    dispatch(userModel.userActions.getScoreMintedTokenId());
  }, [dispatch, domain]);

  if (!profileContractAddress && !isProfileContractAddressLoading && !isProfileInfoLoading)
    navigate(routes.noProfile.path);

  return (
    <Layout bg={LayoutBackground.Lg}>
      {' '}
      {/* footerChildren={<ProfileActionButtons />} */}
      <Stack
        rowGap={{ xs: 2.8, md: 2 }}
        mb={{ xs: 12, sm: 0 }}
        mt={{ xs: 0, md: 6 }}
        sx={{ backdropFilter: 'blur(10px)' }}
      >
        <UploadProfileImages
          variant="secondary"
          withCryptoNative
          isAvatarLoading={isChangeAvatarLoading}
          isBannerLoading={isChangeBannerLoading}
          withEdit={isCurrentUser}
          onUploadAvatar={changeAvatar}
          onUploadBanner={changeBanner}
        />
        <ProfileDescription />

        {profileAddress !== '0x2a81C13F9366395c8FD1EA24912294230d062Db3' && <AddressBlock />}
        <ProfileLinksBlock />
        <SocialsBlock />
        {profileAddress !== '0x2a81C13F9366395c8FD1EA24912294230d062Db3' && <SkillsSection />}
        {profileAddress !== '0x2a81C13F9366395c8FD1EA24912294230d062Db3' && <ScoresGallery />}
        {profileAddress !== '0x2a81C13F9366395c8FD1EA24912294230d062Db3' && <AssetsBlock />}
        {profileAddress !== '0x2a81C13F9366395c8FD1EA24912294230d062Db3' && <NFTsBlock />}

        <Stack mt={{ xs: 2, md: 4 }} width="80%" mx="auto">
          {!isCurrentUser && (
            <WhiteButton
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => {
                amplitudeInstance.track('create_profile_after_viewing');
                navigate(routes.agreement.path);
              }}
            >
              Build my profile
            </WhiteButton>
          )}
        </Stack>
      </Stack>
    </Layout>
  );
};
