import { forwardRef, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Modal, modalModel } from 'entities/modal';
import { profileModel } from 'entities/profile';
import { userModel } from 'entities/user';
import {
  amplitudeInstance,
  BORDER_RADIUS_S,
  BORDER_S,
  BORDER_WIDTH_HOVER,
  Chains,
  routes,
  uiSelector,
  useShallowSelector,
  useWalletConnectorContext,
  WalletProviders,
} from 'shared';
import { WalletService } from 'shared/config';
import { ModalWrapper } from '../ModalWrapper';

import { walletsOptions } from './helper';

const WalletCard = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 56,
  padding: theme.spacing(1),
  background: theme.themeColors.colorBackground,
  border: BORDER_S,
  borderColor: theme.themeColors.colorBorder,
  borderRadius: BORDER_RADIUS_S,
  '&:hover': {
    borderColor: theme.themeColors.colorBorderHover,
    borderWidth: BORDER_WIDTH_HOVER,
    cursor: 'pointer',
  },
}));

const InnerWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  alignItems: 'center',
  height: 40,
  flexDirection: 'row',
  justifyContent: 'center',
  'img, svg': {
    position: 'absolute',
    left: 0,
    top: 0,
  },
}));

export interface ConnectModalPayload {
  type: modalModel.Modals.ConnectModal;
  data: any;
}

export const ConnectModal = forwardRef<HTMLElement, Modal>(() => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [walletProvider, setWalletProvider] = useState('');
  const dispatch = useDispatch();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { connect } = useWalletConnectorContext();

  const handleConnectWallet = useCallback(
    (provider: WalletProviders, chain: Chains) => {
      connect(provider, chain);
      setWalletProvider(provider.toLowerCase());
      dispatch(modalModel.modalActions.closeModal());
    },
    [connect],
  );

  return (
    <ModalWrapper title="Connect Wallet" size="sm">
      <Stack mt={{ xs: 9, md: 2 }}>
        <Stack direction="column" spacing={1} justifyContent="space-between" alignItems="center">
          {walletsOptions.map(({ provider, chain, icon, iconMobile, text }) => (
            <WalletCard key={text} onClick={() => handleConnectWallet(provider, chain)}>
              <InnerWrapper>
                {iconMobile}
                <Typography className="color-primary">{text}</Typography>
              </InnerWrapper>
            </WalletCard>
          ))}
        </Stack>
        <Typography className="center" mt={{ xs: 3.75, md: 7.5 }}>
          {isDownMd
            ? 'These wallets are compatible with the mobile version of the app'
            : 'Sign in by selecting your preferred wallet'}
        </Typography>
      </Stack>
    </ModalWrapper>
  );
});

ConnectModal.displayName = 'ConnectModal';
