import { MouseEvent } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { profileModel } from 'entities/profile';
import { amplitudeInstance, FontWeights, Icon, kFormatter, useShallowSelector } from 'shared';

export const AssetsBlock = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { isCurrentUser } = useShallowSelector(profileModel.selectors.getProfile);
  const { assets } = useShallowSelector(profileModel.selectors.getProfile);

  const handleSwitchClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
  const handleEditClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    amplitudeInstance.track('profile_edit_start');
  };

  return (
    <Accordion className={isDownMd ? 'secondary' : ''}>
      <AccordionSummary className="main">
        Tokens
        {/* {isCurrentUser && (
          <Stack direction="row" spacing={2} ml="auto" mr={2}>
            <IconButton onClick={handleEditClick}>
              <Icon type="edit" />
            </IconButton>
            <Switch onClick={handleSwitchClick} />
          </Stack>
        )} */}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} px={{ xs: 1, md: 1.25 }}>
          {assets.tokens.map(
            ({ img, label, value, rate }) =>
              img && (
                <Grid item container gap={2} xs={12} sm={6} p={2} key={label}>
                  <Box component="img" width={40} height={40} src={img} />
                  <Stack flex={1}>
                    <Typography variant="h5" className="uppercase">
                      {label}
                    </Typography>
                    <Typography sx={{ fontSize: { sm: 13, xs: 10 } }}>{`${rate !== -1 ? +rate : '-'} $`}</Typography>
                  </Stack>
                  <Button
                    className="success"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: 50,
                      width: 80,
                      whiteSpace: 'nowrap',
                      fontWeight: FontWeights.Regular,
                      padding: '10px 0',
                    }}
                    disabled
                  >
                    <Typography sx={{ fontSize: { sm: 11, xs: 9 } }} color="#16592f">{`${(+value).toFixed(
                      2,
                    )} ${label}`}</Typography>{' '}
                    {/* TODO to theme */}
                    <Typography sx={{ fontSize: { sm: 11, xs: 8 } }}>
                      {rate !== -1 ? `$ ${kFormatter(Math.floor(+rate * value * 100) / 100)}` : '-'}
                    </Typography>
                  </Button>
                </Grid>
              ),
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
